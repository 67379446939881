import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Typography
} from '@material-ui/core';
import { useQuery, useQueryClient } from 'react-query';
import { useIsAuthenticated } from '@azure/msal-react';

import { LoadingScreen, UpgradePlanDialog } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';
import { useStyles } from './BillingSubscription.styles';

export const BillingSubscription = ({ className, ...rest }) => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [ openUpgradePlanDialog, setOpenUpgradePlanDialog ] = useState(false);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState(false);

  const { isLoading, data } = useQuery(
    [ 'getOrganisationPlan', { user }],
    () => pricingService.getPricing('gbp', user.organisation.organisation_id), {
      enabled: isAuthenticated && user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const handleUpgradePlanDialogOpen = () => {
    setOpenUpgradePlanDialog(true);
  };

  const handleUpgradePlanDialogClose = () => {
    setOpenUpgradePlanDialog(false);
    setActiveStep(0);
    setCompleted(false);
    queryClient.invalidateQueries('getSelectedPricing');
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted(true);
  };

  const handleSelectedProductTierDataOverview = (selectedProductTierData) => {
    return (
      <Box className={classes.overview}>
        <div>
          <Typography
            color='textPrimary'
            display='inline'
            variant='h5'>
            {selectedProductTierData.tiers[0].formatted}
          </Typography>
          <Typography
            color='textPrimary'
            display='inline'
            variant='subtitle1'>
            /mo
          </Typography>
        </div>
        <Box display='flex' alignItems='center'>
          <Typography
            color='textSecondary'
            variant='overline'>
            {selectedProductTierData.nickname.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleSelectedProductTierDataDetails = (productsData) => {
    const userProduct = productsData.users[0];
    const sourcesFreemiumProduct = productsData.sourcesFreemium[0];
    const selectedSourceProduct = productsData.sources.find(x => x.selected);

    return (
      <Box className={classes.details}>
        <div>
          <Typography
            color='textPrimary'
            variant='body1'>
            <strong>{userProduct.nickname}</strong>
          </Typography>
          {userProduct.tiers.map((tier, index) => (
            <Typography
              key={index}
              color='textPrimary'
              variant='body2'>
              {tier.friendly}
            </Typography>
          ))}
        </div>
        <div>
          {selectedSourceProduct.tiers.map((tier, index) => (
            <Typography
              key={index}
              color='textPrimary'
              variant='body2'>
              {tier.friendly}
            </Typography>
          ))}
        </div>
        <div>
          <Typography
            color='textPrimary'
            variant='body1'>
            <strong>{sourcesFreemiumProduct.nickname}</strong>
          </Typography>
          {sourcesFreemiumProduct.tiers.map((tier, index) => (
            <Typography
              key={index}
              color='textPrimary'
              variant='body2'>
              {tier.friendly}
            </Typography>
          ))}
        </div>
      </Box>
    );
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && data &&
        <Card
          className={clsx(classes.root, className)}
          {...rest}>
          <CardHeader title='Manage your plan' titleTypographyProps={{ variant: 'h6' }} />
          <Divider />
          <CardContent>
            <Paper variant='outlined'>
              {handleSelectedProductTierDataOverview(data.data.products.sources.find(x => x.selected))}
              <Divider />
              {handleSelectedProductTierDataDetails(data.data.products)}
            </Paper>
            <Box
              mt={2}
              display='flex'
              justifyContent='flex-end'>
              <Button
                size='small'
                color='primary'
                onClick={handleUpgradePlanDialogOpen}
                variant='contained' >
                Upgrade plan
              </Button>
            </Box>
          </CardContent>
          <UpgradePlanDialog
            activeStep={activeStep}
            completed={completed}
            handleComplete={handleComplete}
            handleClose={handleUpgradePlanDialogClose}
            onBack={handleBack}
            onNext={handleNext}
            openUpgradePlanDialog={openUpgradePlanDialog} />
        </Card>
      }
    </>
  );
};

BillingSubscription.propTypes = {
  className: PropTypes.string
};