import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: '100%'
  },
  card: {
    borderRadius: 20,
    marginTop: theme.spacing(4)
  },
  avatar: {
    '& .MuiAvatar-img': {
      objectFit: 'contain'
    }
  },
  title: {
    fontWeight: 700,
    textDecoration: 'none',
    margin: 0,
    marginLeft: theme.spacing(2),
    fontSize: '1.0417rem'
  },
  tabs: {
    '& .MuiButtonBase-root': {
      textTransform: 'none'
    }
  },
  dividerRoot: {
    margin: '-1px'
  }
}));