import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  translationBody: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontWeight: 700
  },
  documentViewerContainer: {
    width: '100%',
    height: '100vh',
    borderRadius: 20,
    borderWidth: '1px',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(1)
  },
  languageSelect: {
    maxWidth: '100%',
    minWidth: '100%',
    marginBottom: theme.spacing(3)
  }
}));