import { colors, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600]
  },
}));