import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Trash2 as DeleteIcon } from 'react-feather';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { DeleteSourceDialog, Label } from 'components';
import { handleErrors, sourceStatusEnum } from 'helpers';
import { useUser } from 'hooks';
import { sourceService } from 'services';
import { useStyles } from './SourcesTable.styles';

const getStatusLabel = (sourceStatus) => {
  const { text, color } = sourceStatusEnum[sourceStatus];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

export const SourcesTable = ({
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  sources,
  totalCount
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [ openDeleteSourceDialog, setOpenDeleteSourceDialog ] = useState(false);
  const [ selectedSourceId, setSelectedSourceId ] = useState(null);
  const [ disableButton, setDisableButton ] = useState(false);

  const deleteMutation = useMutation(
    (deleteData) => sourceService.deleteOrganisationSource(deleteData.organisationId, deleteData.sourceId), {
      onSuccess: () => {
        setDisableButton(false);
        invalidateQueries();
        setOpenDeleteSourceDialog(false);
        toast.success('Source deleted successfully');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const handleOpenDeleteSourceDialog = (source_id) => {
    setSelectedSourceId(source_id);
    setOpenDeleteSourceDialog(true);
  };

  const handleCloseDeleteSourceDialog = () => {
    setSelectedSourceId(null);
    setOpenDeleteSourceDialog(false);
  };

  const handleDeleteOrganisationSource = () => {
    setDisableButton(true);

    const dto = {
      organisationId: user.organisation.organisation_id,
      sourceId: selectedSourceId
    };

    deleteMutation.mutate(dto);
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === 'getOrganisationSources' || query.queryKey[0] === 'simulatePricing'
    });
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Website</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sources.map((source) => (
                <TableRow
                  key={source.publisher_id}>
                  <TableCell>
                    <Box
                      display='flex'
                      alignItems='center'>
                      <img
                        className={classes.logo}
                        src={source.logo} />
                    </Box>
                  </TableCell>
                  <TableCell>{source.name}</TableCell>
                  <TableCell>
                    {getStatusLabel('ACTIVE')}
                  </TableCell>
                  <TableCell>{source.website}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenDeleteSourceDialog(source.publisher_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
      {selectedSourceId &&
        <DeleteSourceDialog
          disableButton={disableButton}
          openDeleteSourceDialog={openDeleteSourceDialog}
          selectedSourceId={selectedSourceId}
          onCloseDeleteSourceDialog={handleCloseDeleteSourceDialog}
          handleDeleteOrganisationSource={handleDeleteOrganisationSource} />
      }
    </Card>
  );
};

SourcesTable.propTypes = {
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  sources: PropTypes.array,
  totalCount: PropTypes.number
};