import { b2cPolicies, tenantName } from './policies';

export const config = {
  azureBaseUrl: process.env.REACT_APP_AZURE_API_BASE_URL,
  openRegulationApiBaseUrl: process.env.REACT_APP_OPEN_REGULATION_API_BASE_URL
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_ADB2C_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  }
};

export const loginRequest = {
  scopes: [ 'openid', 'offline_access', `https://${tenantName}.onmicrosoft.com/api/or.read`, `https://${tenantName}.onmicrosoft.com/api/or.write` ]
};