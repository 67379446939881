import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Tooltip
} from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  EditSubscriptionFilterDialog,
  InformationHeader,
  SubscriptionFiltersTable
} from 'components';
import { handleErrors } from 'helpers';
import { subscriptionService } from 'services';

export const SubscriptionExcludedFilters = ({ excludedFilters, languages }) => {
  const queryClient = useQueryClient();
  const routeMatch = useRouteMatch({
    path: '/app/subscriptions/:id/edit',
    strict: true
  });
  const [ excludedFilterPage, setExcludedFilterPage ] = useState(0);
  const [ excludedFilterLimit, setExcludedFilterLimit ] = useState(5);
  const [ openEditSubscriptionFilterDialog, setOpenEditSubscriptionFilterDialog ] = useState(false);
  const [ selectedFilter, setSelectedFilter ] = useState(null);
  const [ disableButton, setDisableButton ] = useState(false);

  const updateExcludeFilterMutation = useMutation(
    (updateData) => subscriptionService.updateSubscriptionFilterExclude(updateData.subscription_id, updateData.data), {
      onSuccess: () => {
        setDisableButton(false);
        handleCloseEditSubscriptionFilterDialog();
        queryClient.invalidateQueries('getSubscription');
        toast.success('Exclude filter updated!');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const deleteFilterMutation = useMutation(
    (deleteData) => subscriptionService.deleteSubscriptionFilter(deleteData.subscription_id, deleteData.filter_criteria_id), {
      onSuccess: () => {
        queryClient.invalidateQueries('getSubscription');
        toast.success('Subscription filter deleted');
      },
      onError: (err) => handleErrors(err)
    });

  const handleExcludedFilterPageChange = (_, newPage) => {
    setExcludedFilterPage(newPage);
  };

  const handleExcludedFilterLimitChange = (eventLimit) => {
    setExcludedFilterLimit(parseInt(eventLimit.target.value));
  };

  const handleUpdateSubscriptionExcludedFilter = (updateFilterData) => {
    const dto = {
      subscription_id: routeMatch.params.id,
      data: updateFilterData
    };

    updateExcludeFilterMutation.mutate(dto);
  };

  const handleDeleteSubscriptionExcludedFilter = (filterId) => {
    const dto = {
      subscription_id: routeMatch.params.id,
      filter_criteria_id: filterId
    };

    deleteFilterMutation.mutate(dto);
  };

  const handleOpenEditSubscriptionFilterDialog = (filterData) => {
    setSelectedFilter(filterData);
    setOpenEditSubscriptionFilterDialog(true);
  };

  const handleCloseEditSubscriptionFilterDialog = () => {
    setSelectedFilter(null);
    setOpenEditSubscriptionFilterDialog(false);
  };

  const handleDisableButtonChange = (disable) => {
    setDisableButton(disable);
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <InformationHeader headerTitle='Exclude' tooltipTitleKey='newsletter_filter_exclude_info' />
        <Tooltip title='Add exclude filter' placement='bottom'>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddCircleOutline />}
            onClick={() => handleOpenEditSubscriptionFilterDialog(null)}>
            Add
          </Button>
        </Tooltip>
      </Box>
      <SubscriptionFiltersTable
        handleDeleteSubscriptionFilter={handleDeleteSubscriptionExcludedFilter}
        handleOpenEditSubscriptionFilterDialog={handleOpenEditSubscriptionFilterDialog}
        handlePageChange={handleExcludedFilterPageChange}
        handleRowsPerPageChange={handleExcludedFilterLimitChange}
        page={excludedFilterPage}
        rowsPerPage={excludedFilterLimit}
        filters={excludedFilters}
        totalCount={excludedFilters.length} />
      <EditSubscriptionFilterDialog
        filterData={selectedFilter}
        languages={selectedFilter && selectedFilter.language && selectedFilter.language.length > 0 ? selectedFilter.language : languages}
        disableButton={disableButton}
        handleDisableButtonChange={handleDisableButtonChange}
        handleUpdateSubscriptionFilter={handleUpdateSubscriptionExcludedFilter}
        openEditSubscriptionFilterDialog={openEditSubscriptionFilterDialog}
        onCloseEditSubscriptionFilterDialog={handleCloseEditSubscriptionFilterDialog} />
    </>
  );
};

SubscriptionExcludedFilters.propTypes = {
  excludedFilters: PropTypes.array.isRequired,
  languages: PropTypes.array
};