import React from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export const ArrowsExpand = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'>
    <path
      stroke='#374151'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M3 8V4m0 0h4M3 4l4 4m8 0V4m0 0h-4m4 0l-4 4m-8 4v4m0 0h4m-4 0l4-4m8 4l-4-4m4 4v-4m0 4h-4' />
  </svg>, 'ArrowsExpand'
);