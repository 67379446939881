import React, {
  createContext,
  //useEffect,
  useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { LANDING_PAGE_TEXT } from 'helpers';

const defaultLandingData = {
  landingText: LANDING_PAGE_TEXT,
  redirect: null
};

export const LandingContext = createContext({
  landingData: defaultLandingData,
  /* eslint-disable-next-line */
  saveLandingData: () => {}
});

export const LandingProvider = ({ children, landingData }) => {
  const [ currentLandingData, setCurrentLandingData ] = useState(landingData || defaultLandingData);

  const handleSaveLandingData = (update = {}) => {
    const mergedUser = _.merge({}, currentLandingData, update);

    setCurrentLandingData(mergedUser);
  };

  return (
    <LandingContext.Provider
      value={{
        landingData: currentLandingData,
        saveLandingData: handleSaveLandingData
      }}>
      {children}
    </LandingContext.Provider>
  );
};

LandingProvider.propTypes = {
  children: PropTypes.node,
  landingData: PropTypes.any
};

export const LandingConsumer = LandingContext.Consumer;