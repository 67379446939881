import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { DialogTitle } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';
import { useStyles } from './EditUserPermissionsDialog.styles';

export const EditUserPermissionsDialog = ({
  openEditUserPermissionsDialog,
  onCloseEditUserPermissionsDialog,
  userData
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const [ disableButton, setDisableButton ] = useState(false);
  const { organisation_id, ...permissions } = userData.permissions.find(x => x.organisation_id === user.organisation.organisation_id);
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      ...permissions
    },
    onSubmit: (values) => {
      setDisableButton(true);

      const dto = {
        user_id: userData.user_id,
        data: {
          ...values,
          organisation_id: organisation_id
        }
      };

      updatePermissionsMutation.mutate(dto);
    }
  });

  const updatePermissionsMutation = useMutation(
    (updateData) => userService.updatePermissions(updateData.user_id, updateData.data), {
      onSuccess: () => {
        setDisableButton(false);
        invalidateQueries();
        onCloseEditUserPermissionsDialog();
        toast.success('User permissions updated');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const invalidateQueries = () => {
    queryClient.invalidateQueries('getUsers');
  };

  return (
    <Dialog
      open={openEditUserPermissionsDialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onCloseEditUserPermissionsDialog}>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        onClose={onCloseEditUserPermissionsDialog}>
        <Typography variant='h5'>Update user role(s)</Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          {Object.keys(permissions).filter(x => x.startsWith('role_')).map((key, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  id={key}
                  name={key}
                  color='primary'
                  checked={formik.values[key]}
                  onChange={formik.handleChange} />
              }
              label={key.replace('role_', '').toUpperCase()} />
          ))}
          <Box mt={2} display='flex' justifyContent='center'>
            <Button
              color='primary'
              type='submit'
              disabled={disableButton}
              variant='contained'>
              Update Permissions
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

EditUserPermissionsDialog.propTypes = {
  openEditUserPermissionsDialog: PropTypes.bool,
  onCloseEditUserPermissionsDialog: PropTypes.func,
  userData: PropTypes.object
};