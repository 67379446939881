import React, {
  lazy,
  Fragment,
  Suspense
} from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import {
  AuthGuard,
  BillingSummaryPage,
  EditSubscriptionPage,
  FreemiumPage,
  GuestGuard,
  HomePage,
  LandingPage,
  LoadingScreen,
  MainLayout,
  PublicationDetailsPage,
  PublicationsPage,
  SourcesPage,
  SubscriptionsPage,
  UsersPage
} from 'components';

export const renderRoutes = (routerRoutes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routerRoutes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : (
                      <>
                        {route.authenticatedTemplate &&
                          <AuthenticatedTemplate>
                            <Component {...props} />
                          </AuthenticatedTemplate>
                        }
                        {route.unauthenticatedTemplate &&
                          <UnauthenticatedTemplate>
                            <Component {...props} />
                          </UnauthenticatedTemplate>
                        }
                        {!route.authenticatedTemplate && !route.unauthenticatedTemplate &&
                          <Component {...props} />
                        }
                      </>
                    )}
                </Layout>
              </Guard>
            )} />
        );
      })}
    </Switch>
  </Suspense>
);

export const routes = [
  {
    exact: true,
    path: '/404',
    authenticatedTemplate: false,
    unauthenticatedTemplate: false,
    component: lazy(() => import('src/components/NotFoundPage/NotFoundPage'))
  },
  {
    exact: true,
    path: '/',
    guard: AuthGuard,
    authenticatedTemplate: false,
    unauthenticatedTemplate: false,
    component: LandingPage
  },
  {
    path: '/freemium',
    layout: MainLayout,
    guard: GuestGuard,
    authenticatedTemplate: false,
    unauthenticatedTemplate: true,
    routes: [
      {
        exact: true,
        path: '/freemium',
        authenticatedTemplate: false,
        unauthenticatedTemplate: true,
        component: FreemiumPage
      },
      {
        exact: true,
        path: '/freemium/:ou_id/:s_id', // organisation_user_id (ou_id), subscription_id (s_id)
        authenticatedTemplate: false,
        unauthenticatedTemplate: true,
        component: FreemiumPage
      },
      {
        /* eslint-disable-next-line */
        component: () => <Redirect to='/404' />
      }
    ]
  },
  {
    path: '/app',
    layout: MainLayout,
    guard: AuthGuard,
    authenticatedTemplate: true,
    unauthenticatedTemplate: false,
    routes: [
      {
        exact: true,
        path: '/app',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: HomePage
      },
      {
        exact: true,
        path: '/app/users',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: UsersPage
      },
      {
        exact: true,
        path: '/app/sources',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: SourcesPage
      },
      {
        exact: true,
        path: '/app/billing',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: BillingSummaryPage
      },
      {
        exact: true,
        path: '/app/subscriptions',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: SubscriptionsPage
      },
      {
        exact: true,
        path: '/app/subscriptions/:id/edit',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: EditSubscriptionPage
      },
      {
        exact: true,
        path: '/app/search',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: PublicationsPage
      },
      {
        exact: true,
        path: '/app/publications/:id',
        authenticatedTemplate: true,
        unauthenticatedTemplate: false,
        component: PublicationDetailsPage
      },
      {
        /* eslint-disable-next-line */
        component: () => <Redirect to='/404' />
      }
    ]
  }
];
