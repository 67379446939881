import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: '100%'
  },
  autocomplete: {
    width: 300,
    backgroundColor: theme.palette.primary.contrastText
  },
  autocompletePopupIndicator: {
    color: theme.palette.text.primary
  }
}));