import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  InformationHeader,
  SubscriptionExcludedFilters,
  SubscriptionFiltersList,
  SubscriptionIncludedFilters,
  SubscriptionScopeFilter
} from 'components';
import { handleErrors } from 'helpers';
import { subscriptionService } from 'services';
import { useStyles } from './SubscriptionFilters.styles';

export const SubscriptionFilters = ({ className, filter, ...rest }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const routeMatch = useRouteMatch({
    path: '/app/subscriptions/:id/edit',
    strict: true
  });

  const updateScopeFilterMutation = useMutation(
    (updateData) => subscriptionService.updateSubscriptionFilterScope(updateData.subscription_id, updateData.data), {
      onSuccess: () => {
        queryClient.invalidateQueries('getSubscription');
        toast.success('Scope filter updated!');
      },
      onError: (err) => handleErrors(err)
    });

  const updateLanguageFilterMutation = useMutation(
    (updateData) => subscriptionService.updateSubscriptionFilterLanguage(updateData.subscription_id, updateData.data), {
      onSuccess: () => {
        queryClient.invalidateQueries('getSubscription');
        toast.success('Language filter updated!');
      },
      onError: (err) => handleErrors(err)
    });

  const updateTypeFilterMutation = useMutation(
    (updateData) => subscriptionService.updateSubscriptionFilterType(updateData.subscription_id, updateData.data), {
      onSuccess: () => {
        queryClient.invalidateQueries('getSubscription');
        toast.success('Type filter updated!');
      },
      onError: (err) => handleErrors(err)
    });

  const handleScopeChange = (scopeValue) => {
    updateScopeFilterMutation.mutate({
      subscription_id: routeMatch.params.id,
      data: {
        only_search_title: scopeValue
      }
    });
  };

  const handleLanguagesChange = (newValue, selected) => {
    if (newValue) {
      updateLanguageFilterMutation.mutate(handleLanguageTypeDto(newValue, selected));
    }
  };

  const handleTypesChange = (newValue, selected) => {
    if (newValue) {
      updateTypeFilterMutation.mutate(handleLanguageTypeDto(newValue, selected));
    }
  };

  const handleLanguageTypeDto = (filterData, selected) => ({
    subscription_id: routeMatch.params.id,
    data: [
      {
        ...filterData,
        selected: selected
      }
    ]
  });

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}>
      <CardHeader title='Filters' titleTypographyProps={{ variant: 'h5' }} />
      <Divider />
      <CardContent>
        <SubscriptionIncludedFilters
          includedFilters={filter.included}
          languages={filter.languages} />
        <Box mt={3}>
          <SubscriptionExcludedFilters
            excludedFilters={filter.excluded}
            languages={filter.languages} />
        </Box>
        <Box mt={3}>
          <SubscriptionScopeFilter
            onlySearchTitle={filter.only_search_title}
            handleScopeChange={handleScopeChange} />
        </Box>
        <Box mt={3}>
          <InformationHeader headerTitle='Languages' tooltipTitleKey='newsletter_filter_languages_info' />
          <SubscriptionFiltersList
            filters={filter.languages}
            handleChange={handleLanguagesChange} />
        </Box>
        <Box mt={3}>
          <InformationHeader headerTitle='Types' tooltipTitleKey='newsletter_filter_type_info' />
          <SubscriptionFiltersList
            filters={filter.types}
            handleChange={handleTypesChange} />
        </Box>
      </CardContent>
    </Card>
  );
};

SubscriptionFilters.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object.isRequired
};