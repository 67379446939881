import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { LoadingScreen, SimulatePricingTable } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';
import { useStyles } from './AddPaymentMethod.styles';

export const AddPaymentMethod = ({
  handleComplete,
  onBack,
  selectedCurrency,
  selectedProductTier
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUser();
  const [ disableButton, setDisableButton ] = useState(true);

  const { isLoading: isLoadingSimulatePricing, data: simulatePricingData } = useQuery(
    [ 'simulatePricing', { user }],
    () => pricingService.simulatePricing(user.organisation.organisation_id, selectedCurrency), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const confirmSetupMutation = useMutation(
    () => stripe.confirmSetup({
      elements: elements,
      redirect: 'if_required'
    }), {
      onSuccess: (result) => {
        if (result.error) {
          setDisableButton(false);
          toast.error(result.error.message);
        } else {
          const dto = {
            organisation_id: user.organisation.organisation_id,
            payment_method_id: result.setupIntent.payment_method
          };

          setDefaultCardMutation.mutate(dto);
        }
      }
    });

  const setDefaultCardMutation = useMutation(
    (defaultCardData) => pricingService.setDefaultCard(defaultCardData), {
      onSuccess: () => {
        const dto = {
          organisation_id: user.organisation.organisation_id,
          price_id: selectedProductTier,
          currency: selectedCurrency
        };

        editBillingSubscription.mutate(dto);
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const editBillingSubscription = useMutation(
    (billingSubscriptionData) => pricingService.createBillingSubscription(billingSubscriptionData), {
      onSuccess: () => {
        setDisableButton(false);
        handleComplete();
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const handleChange = (ev) => {
    if (ev.complete) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleSubmit = async (ev) => {
    // Block native form submission.
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setDisableButton(true);

    confirmSetupMutation.mutate();
  };

  return (
    <>
      {isLoadingSimulatePricing && <LoadingScreen />}
      {!isLoadingSimulatePricing && simulatePricingData &&
        <Box>
          <Typography
            variant='h6'
            color='textPrimary'>
            Add Payment Method
          </Typography>
          <Box mt={2}>
            <Typography
              variant='subtitle1'
              color='textSecondary'>
              In this step you need to enter your credit card information.
            </Typography>
          </Box>
          <Box mt={2}>
            <SimulatePricingTable data={simulatePricingData.data} />
          </Box>
          <Box mt={3}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container direction='column'>
                <Grid item>
                  <PaymentElement onChange={handleChange} />
                </Grid>
              </Grid>
              <Box mt={6} display='flex'>
                <Button
                  onClick={onBack}
                  size='large'>
                  Previous
                </Button>
                <Box flexGrow={1} />
                <Button
                  color='primary'
                  type='submit'
                  variant='contained'
                  size='large'
                  disabled={disableButton}
                  data-analytics='billing_payment_add'>
                  Complete
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      }
    </>
  );
};

AddPaymentMethod.propTypes = {
  handleComplete: PropTypes.func,
  onBack: PropTypes.func,
  selectedCurrency: PropTypes.string,
  selectedProductTier: PropTypes.any,
};