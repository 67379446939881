import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 20,
    marginTop: theme.spacing(4)
  },
  title: {
    fontSize: '1rem',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  list: {
    padding: 0,
    '& .MuiListItem-root': {
      padding: `${theme.spacing(1)}px 0`,
      '& .MuiListItemText-root': {
        margin: 0
      }
    }
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    margin: 0
  },
  itemIcon: {
    minWidth: 0
  }
}));