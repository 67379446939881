import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  HelpDrawer,
  LoadingScreen,
  NavBar,
  TopBar
} from 'components';
import { getPartnerId, handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { localiseService } from 'services';

import { useStyles } from './MainLayout.styles';

export const MainLayout = ({ children }) => {
  const classes = useStyles();
  const { user, saveUser } = useUser();
  const [ isMobileNavOpen, setMobileNavOpen ] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { isLoading } = useQuery(
    [ 'getTheme', { user, query }],
    () => localiseService.getTheme(getPartnerId(user, query.get('pid'))), {
      enabled: user === null || user.theme === null || query.get('pid') !== null,
      retry: false,
      onSuccess: (response) => {
        saveUser({
          ...user,
          ...response.data
        });
      },
      onError: (err) => handleErrors(err)
    });

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading &&
        <div className={classes.root}>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen} />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                {children}
              </div>
            </div>
          </div>
          <HelpDrawer />
        </div>
      }
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};