/* Enter here the user flows and custom policies for your B2C application */
const signUpSignInPolicy = process.env.REACT_APP_AZURE_ADB2C_SIGNIN_SIGNUP_POLICY;
const forgotPasswordPolicy = process.env.REACT_APP_AZURE_ADB2C_FORGOT_PASSWORD_POLICY;
const editProfilePolicy = process.env.REACT_APP_AZURE_ADB2C_EDIT_PROFILE_POLICY;
const signUpPolicy = process.env.REACT_APP_AZURE_ADB2C_SIGNUP_POLICY;

export const tenantName = process.env.REACT_APP_AZURE_ADB2C_TENANT_NAME;

export const b2cPolicies = {
  names: {
    signUpSignIn: signUpSignInPolicy,
    forgotPassword: forgotPasswordPolicy,
    editProfile: editProfilePolicy,
    signUp: signUpPolicy
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signUpSignInPolicy}`,
    },
    forgotPassword: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${forgotPasswordPolicy}`,
    },
    editProfile: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${editProfilePolicy}`
    },
    signUp: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signUpPolicy}`
    }
  },
  authorityDomain: `${tenantName}.b2clogin.com`
};