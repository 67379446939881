import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './Label.styles';

export const Label = ({
  className = '',
  color = 'secondary',
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <span
      className={
        clsx(classes.root, {
          [classes[color]]: color
        }, className)
      }
      {...rest}>
      {children}
    </span>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([ 'primary', 'secondary', 'error', 'warning', 'success' ])
};