import axios from 'axios';

import { config } from 'helpers';

const getSources = (organisationId, searchString = '') => {
  return axios.post(`${config.openRegulationApiBaseUrl}/sources/${organisationId}/search?keyword=${searchString}`)
    .then((response) => {
      return response.data;
    });
};

const getOrganisationSources = (organisationId, offset = 0, limit = 10) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/sources/${organisationId}?offset=${offset}&limit=${limit}`)
    .then((response) => {
      return response.data;
    });
};

const getOrganisationSourceStatus = (organisationId, publisherId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/sources/${organisationId}/${publisherId}/status`)
    .then((response) => {
      return response.data;
    });
};

const requestOrganisationSources = (organisationId, data) => {
  return axios.post(`${config.openRegulationApiBaseUrl}/sources/${organisationId}/request`, data);
};

const addOrganisationSource = (organisationId, publisherId) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/sources/${organisationId}/${publisherId}`);
};

const deleteOrganisationSource = (organisationId, publisherId) => {
  return axios.delete(`${config.openRegulationApiBaseUrl}/sources/${organisationId}/${publisherId}`);
};

export const sourceService = {
  getSources,
  getOrganisationSources,
  getOrganisationSourceStatus,
  requestOrganisationSources,
  addOrganisationSource,
  deleteOrganisationSource
};