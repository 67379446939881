import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1)
  }
}));