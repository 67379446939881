import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 48,
    height: 48
  },
  organisationSelect: {
    maxWidth: '100%',
    minWidth: '100%'
  },
  listSubheader: {
    color: 'black',
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  box: {
    backgroundColor: theme.palette.background.standard,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  displayName: {
    inlineSize: '120px',
    overflowWrap: 'break-word'
  }
}));