import { fade, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  typography: {
    marginTop: theme.spacing(1)
  },
  footerButton: {
    textTransform: 'none'
  },
  avatar: {
    height: 36,
    width: 36,
    backgroundColor: 'transparent'
  },
  successBackgroundColor: {
    backgroundColor: fade(theme.palette.success.main, 0.08),
    color: theme.palette.success.main,
  },
  errorBackgroundColor: {
    backgroundColor: fade(theme.palette.error.main, 0.08),
    color: theme.palette.error.main,
  },
  primaryBackgroundColor: {
    backgroundColor: fade(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
  },
}));