import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography
} from '@material-ui/core';

import { useMobileView } from 'hooks';
import { ExternalLink, Template } from 'resources/icons';
import { useStyles } from './PublicationCard.styles';

export const PublicationCard = ({ publication }) => {
  const classes = useStyles();
  const mobileView = useMobileView();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Avatar
            component={Link}
            className={classes.avatar}
            href={`/app/publications/${publication.publication_id}`}
            src={publication.publisher.logo}
            variant='rounded'/>
          <Link
            className={classes.titleLink}
            href={`/app/publications/${publication.publication_id}`}
            variant='h6'>
            {publication.title}
          </Link>
        </Box>
        <Grid container display='flex' direction='column'>
          <Grid item xs={12}>
            <Grid container display='flex' direction='row' justify='space-between'>
              <Grid item>
                <Grid
                  container
                  display='flex'
                  direction={mobileView ? 'column' : 'row'}
                  alignItems='flex-start'>
                  <Typography
                    className={classes.typography}
                    noWrap
                    variant='overline'>
                    {`${new Date(publication.publication_date).toLocaleDateString()} ${publication.publisher.name} (${publication.type} in ${publication.language})`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  display='flex'
                  direction={mobileView ? 'column' : 'row'}
                  alignItems='flex-start'>
                  <Button
                    className={classes.button}
                    href={`/app/publications/${publication.publication_id}`}
                    endIcon={<Template />}>
                    Details
                  </Button>
                  {publication.publication_url && publication.publication_url !== '' &&
                    <Button
                      className={classes.button}
                      href={publication.publication_url}
                      target='_blank'
                      endIcon={<ExternalLink />}>
                      Publication
                    </Button>
                  }
                  {publication.summary_url && publication.summary_url !== '' &&
                    <Button
                      className={classes.button}
                      href={publication.summary_url}
                      target='_blank'
                      endIcon={<ExternalLink />}>
                      Summary
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {publication && publication.hits && publication.hits.length > 0 &&
            <Grid item xs={12}>
              <Grid container display='flex' direction='column' className={classes.hitsContainer}>
                {publication.hits.map((hit, index) => (
                  <Grid item key={index}>
                    <Typography
                      color='textPrimary'
                      className={classes.hitText}
                      dangerouslySetInnerHTML={{ __html: hit }} />
                    {publication.hits.length > index + 1 &&
                      <Divider />
                    }
                  </Grid>
                ))}
              </Grid>
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

PublicationCard.propTypes = {
  publication: PropTypes.object.isRequired
};