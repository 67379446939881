import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Typography
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { useIsAuthenticated } from '@azure/msal-react';

import { CreditCardDialog, LoadingScreen } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';
import { CreditCard } from 'resources/icons';
import { useStyles } from './CardDetails.styles';

export const CardDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user } = useUser();
  const isAuthenticated = useIsAuthenticated();
  const [ openCreditCardDialog, setOpenCreditCardDialog ] = useState(false);

  const { isLoading, data } = useQuery(
    [ 'getDefaultCard', { user }],
    () => pricingService.getDefaultCard(user.organisation.organisation_id), {
      enabled: isAuthenticated && user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const handleCloseCreditCardDialog = () => {
    setOpenCreditCardDialog(false);
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && data &&
        <Card
          className={clsx(classes.root, className)}
          {...rest}>
          <CardHeader title='Manage your credit card' titleTypographyProps={{ variant: 'h6' }} />
          <Divider />
          <CardContent>
            <Paper variant='outlined'>
              <Box className={classes.overview}>
                <Box
                  display='flex'
                  alignItems='center'>
                  <CreditCard />
                  <Typography
                    className={classes.creditCard}
                    display='inline'
                    variant='h6'
                    color='textPrimary'>
                    {`XXXX-XXXX-XXXX-${data.data.last4}`}
                  </Typography>
                </Box>
                <Typography
                  display='inline'
                  variant='h6'
                  color='textPrimary'>
                  {`${data.data.exp_month}/${data.data.exp_year}`}
                </Typography>
              </Box>
            </Paper>
            <Box
              mt={2}
              display='flex'
              justifyContent='flex-end'>
              <Button
                size='small'
                color='primary'
                variant='contained'
                onClick={() => setOpenCreditCardDialog(true)}>
                Update credit card
              </Button>
            </Box>
          </CardContent>
          <CreditCardDialog
            openCreditCardDialog={openCreditCardDialog}
            onCloseCreditCardDialog={handleCloseCreditCardDialog}/>
        </Card>
      }
    </>
  );
};

CardDetails.propTypes = {
  className: PropTypes.string
};