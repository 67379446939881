import React from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import axios from 'axios';

import {
  browserHistory,
  getSilentRequest,
  msalConfig,
  queryClient,
  renderRoutes,
  routes
} from 'helpers';
import { createTheme } from 'src/theme';
import { GlobalStyles } from 'components';
import { useSettings, useUser } from 'hooks';

import 'react-toastify/dist/ReactToastify.css';
import '../../resources/styles/react-toastify-overrides.css';
import '../../resources/styles/apexcharts-overrides.css';

const jss = create({ plugins: [ ...jssPreset().plugins, rtl() ] });

const msalInstance = new PublicClientApplication(msalConfig);

// axios interceptors
axios.interceptors.request.use(async (config) => {
  const accounts = await msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const account = accounts[0];
      const tokenResponse = await msalInstance.acquireTokenSilent({ ...getSilentRequest(account) });
      if (tokenResponse && tokenResponse.idToken) {
        config.headers.Authorization = 'Bearer ' + tokenResponse.idToken;
      }
    } catch (error) {
      msalInstance.logoutRedirect();
    }
  }

  return config;
}, err => {
  return Promise.reject(err);
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error.response);
});

export const App = () => {
  const { settings } = useSettings();
  const { user } = useUser();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    organisationTheme: user.theme
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider
            dense
            maxSnack={3} >
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <MsalProvider instance={msalInstance}>
                <QueryClientProvider client={queryClient}>
                  <Router history={browserHistory}>
                    <GlobalStyles />
                    {renderRoutes(routes)}
                  </Router>
                </QueryClientProvider>
              </MsalProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
      <ToastContainer
        position='bottom-right'
        autoClose={10000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover />
    </>
  );
};