import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography
} from '@material-ui/core';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { DialogTitle } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';
import { useStyles } from './CreditCardDialog.styles';

export const CreditCardDialog = ({
  openCreditCardDialog,
  onCloseCreditCardDialog
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [ disableButton, setDisableButton ] = useState(true);

  const confirmSetupMutation = useMutation(
    () => stripe.confirmSetup({
      elements: elements,
      redirect: 'if_required'
    }), {
      onSuccess: (result) => {
        if (result.error) {
          setDisableButton(false);
          toast.error(result.error.message);
        } else {
          const dto = {
            organisation_id: user.organisation.organisation_id,
            payment_method_id: result.setupIntent.payment_method
          };

          setDefaultCardMutation.mutate(dto);
        }
      }
    });

  const setDefaultCardMutation = useMutation(
    (defaultCardData) => pricingService.setDefaultCard(defaultCardData), {
      onSuccess: () => {
        queryClient.invalidateQueries('getDefaultCard');
        setDisableButton(false);
        onCloseCreditCardDialog();
        toast.success('Credit card added and set as default card.');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const handleChange = (ev) => {
    if (ev.complete) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleSubmit = async (ev) => {
    // Block native form submission.
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setDisableButton(true);

    confirmSetupMutation.mutate();
  };

  const handleCloseCreditCardDialog = () => {
    setDisableButton(false);
    onCloseCreditCardDialog();
  };

  return (
    <Dialog
      open={openCreditCardDialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={handleCloseCreditCardDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={handleCloseCreditCardDialog}>
        <Typography variant='h5'>Update credit card</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box mt={2}>
              <PaymentElement onChange={handleChange} />
            </Box>
            <Box mt={2} display='flex' justifyContent='center'>
              <Button
                color='primary'
                type='submit'
                variant='contained'
                disabled={disableButton}>
                Update Credit Card
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CreditCardDialog.propTypes = {
  openCreditCardDialog: PropTypes.bool,
  onCloseCreditCardDialog: PropTypes.func,
};