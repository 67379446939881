import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Trash2 as DeleteIcon } from 'react-feather';
import { useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { handleErrors } from 'helpers';
import { subscriptionService } from 'services';
import { useStyles } from './SubscriptionSourcesTable.styles';

export const SubscriptionSourcesTable = ({
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  sources,
  totalCount
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const routeMatch = useRouteMatch({
    path: '/app/subscriptions/:id/edit',
    strict: true
  });

  const deleteSourceMutation = useMutation(
    (deleteData) => subscriptionService.deleteSubscriptionSource(deleteData.subscription_id, deleteData.publisher_id), {
      onSuccess: () => {
        queryClient.invalidateQueries('getSubscriptionSources');
        toast.success('Subscription source deleted');
      },
      onError: (err) => handleErrors(err)
    });

  const handleDeleteSubscriptionSource = (publisher_id) => {
    const dto = {
      subscription_id: routeMatch.params.id,
      publisher_id: publisher_id
    };

    deleteSourceMutation.mutate(dto);
  };

  return (
    <>
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableBody>
              {sources.map((source) => (
                <TableRow
                  key={source.publisher_id}>
                  <TableCell>
                    <Box
                      display='flex'
                      alignItems='center'>
                      <img
                        className={classes.logo}
                        src={source.logo} />
                    </Box>
                  </TableCell>
                  <TableCell>{source.name}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleDeleteSubscriptionSource(source.publisher_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
    </>
  );
};

SubscriptionSourcesTable.propTypes = {
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  sources: PropTypes.array,
  totalCount: PropTypes.number
};