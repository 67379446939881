import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: '100%'
  },
  pagination: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3)
  },
  totalSearchCountText: {
    marginRight: theme.spacing(3),
    opacity: 0.5
  }
}));