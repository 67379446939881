import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { DialogTitle, LoadingScreen } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';
import { useStyles } from './DeleteUserDialog.styles';

export const DeleteUserDialog = ({
  disableButton,
  openDeleteUserDialog,
  selectedUserId,
  handleDeleteUser,
  onCloseDeleteUserDialog
}) => {
  const classes = useStyles();
  const { user } = useUser();

  const { isLoading, data } = useQuery(
    [ 'getUserStatus', { user, selectedUserId }],
    () => userService.getUserStatus(user.organisation.organisation_id, selectedUserId), {
      enabled: user.organisation !== null && selectedUserId !== null,
      onError: (err) => handleErrors(err)
    });

  return (
    <Dialog
      open={openDeleteUserDialog}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onCloseDeleteUserDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseDeleteUserDialog}>
        <Typography variant='h5'>Delete user</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading && <LoadingScreen />}
        {!isLoading && data &&
          <Typography>
            {data.data} Are you sure you want to delete this user?
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={onCloseDeleteUserDialog}>
          No
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={disableButton}
          onClick={handleDeleteUser}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteUserDialog.propTypes = {
  disableButton: PropTypes.bool,
  openDeleteUserDialog: PropTypes.bool,
  selectedUserId: PropTypes.string,
  handleDeleteUser: PropTypes.func,
  onCloseDeleteUserDialog: PropTypes.func
};