import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { useQuery } from 'react-query';

import { DialogTitle } from 'components';
import {
  b2cPolicies,
  handleErrors,
  LANDING_PAGE_TEXT,
  loginRequest
} from 'helpers';
import { localiseService } from 'services';
import { useStyles } from './CreateFullAccountDialog.styles';

export const CreateFullAccountDialog = ({
  openCreateFullAccountDialog,
  onCloseCreateFullAccountDialog
}) => {
  const classes = useStyles();
  const { instance } = useMsal();
  const routeMatch = useRouteMatch({
    path: '/freemium/:ou_id/:s_id',
    strict: true
  });
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { isLoading, data } = useQuery(
    [ 'getHelpText', { routeMatch }],
    () => localiseService.getHelpText('en-GB', routeMatch ? 'freemium_existing' : 'freemium_new', query && query.get('pid') ? query.get('pid') : ''), {
      onError: (err) => handleErrors(err)
    });

  const handleCreateFullAccount = () => {
    const state = {
      landingText: LANDING_PAGE_TEXT,
      redirect: null,
      upgrading: routeMatch !== null,
      ...(routeMatch && {
        organisation_user_id: routeMatch.params.ou_id
      }),
      ...(query && query.get('pid') && {
        partner_id: query.get('pid')
      })
    };

    instance.loginRedirect({
      ...loginRequest,
      redirectStartPage: '/',
      redirectUri: '/',
      authority: b2cPolicies.authorities.signUp.authority,
      state: btoa(JSON.stringify(state))
    });
  };

  return (
    <Dialog
      open={openCreateFullAccountDialog}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onCloseCreateFullAccountDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseCreateFullAccountDialog}>
        <Typography variant='h5'>Upgrade</Typography>
      </DialogTitle>
      <DialogContent>
        {!isLoading && data &&
          <Typography color='textPrimary' dangerouslySetInnerHTML={{ __html: data }} />
        }
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={onCloseCreateFullAccountDialog}
          data-analytics='freemium_upgrade_no'>
          No
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleCreateFullAccount}
          data-analytics='freemium_upgrade_yes'>
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateFullAccountDialog.propTypes = {
  openCreateFullAccountDialog: PropTypes.bool,
  onCloseCreateFullAccountDialog: PropTypes.func
};