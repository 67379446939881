import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    ...(theme.palette.mode === 'light' && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      color: theme.palette.primary.contrastText
    }),
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none'
    }),
    zIndex: theme.zIndex.drawer + 100
  },
  toolbar: {
    minHeight: 64
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText
  }
}));