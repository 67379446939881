import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import { useStyles } from './PublicationTranslationTab.styles';

export const PublicationTranslationTab = ({
  publication,
  filters,
  handleFilters
}) => {
  const classes = useStyles();

  const handleLanguageChange = (languageEvent) => {
    handleFilters({
      ...filters,
      listField: [
        ...filters.listField.map(field => ({
          ...field,
          ...(field.fieldName === languageEvent.target.name && {
            value: field.value.map(val => ({
              ...val,
              selected: false,
              ...(val.key === languageEvent.target.value && {
                selected: true
              })
            }))
          })
        }))
      ]
    });
  };

  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography variant='h5' className={classes.title}>
          {publication.title}
        </Typography>
      </Grid>
      <Grid item className={classes.translationBody}>
        <Grid container direction='column'>
          <Grid item md={4} xs={12}>
            {filters && filters.listField && filters.listField.sort(x => x.sortOrder).map((field) => (
              <FormControl key={field.fieldName} variant='outlined' className={classes.languageSelect}>
                <InputLabel htmlFor={field.fieldName}>{field.fieldLabel}</InputLabel>
                <Select
                  value={field.value.filter(x => x.selected).map(x => x.key).join(',')}
                  onChange={handleLanguageChange}
                  label={field.fieldLabel}
                  inputProps={{
                    name: field.fieldName,
                    id: field.fieldName
                  }}>
                  <MenuItem aria-label='None' value=''></MenuItem>
                  {field.value.map(option => (
                    <MenuItem key={option.key} value={option.key}>{option.value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          </Grid>
          <Grid item xs={12}>
            {publication !== null &&
            filters?.listField?.find(field => field.fieldName === 'language').value.filter(x => x.selected).map(x => x.key).join(',') &&
            (
              <iframe
                className={classes.documentViewerContainer}
                src={`https://api.or-cloud.io/public/api/publications/${publication.publication_id}/translate/${filters?.listField?.find(field => field.fieldName === 'language').value.filter(x => x.selected).map(x => x.key).join(',')}/document/viewer`}>
              </iframe>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PublicationTranslationTab.propTypes = {
  publication: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  handleFilters: PropTypes.func.isRequired
};
