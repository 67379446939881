import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  AutocompleteTextField,
  InformationHeader,
  LoadingScreen,
  SubscriptionSourcesTable,
  SubscriptionTitle
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { sourceService, subscriptionService } from 'services';
import { useStyles } from './SubscriptionSources.styles';

export const SubscriptionSources = ({ className, subscription, ...rest }) => {
  const classes = useStyles();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const routeMatch = useRouteMatch({
    path: '/app/subscriptions/:id/edit',
    strict: true
  });
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const [ searchString, setSearchString ] = useState('');
  const [ offset, setOffset ] = useState(0);
  const [ limit, setLimit ] = useState(10);
  const [ page, setPage ] = useState(0);

  const { isLoading, data } = useQuery(
    [ 'getOrganisationSources', { routeMatch, offset, limit }],
    () => sourceService.getOrganisationSources(user.organisation.organisation_id, 0, 200), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingSubscriptionSources, data: subscriptionSourcesData } = useQuery(
    [ 'getSubscriptionSources', { routeMatch, offset, limit }],
    () => subscriptionService.getSubscriptionSources(routeMatch.params.id, offset, limit), {
      enabled: routeMatch !== null,
      onError: (err) => handleErrors(err)
    });

  const updateMutation = useMutation(
    (updateData) => subscriptionService.updateSubscriptionSource(updateData.subscriptionId, updateData.data), {
      onSuccess: () => {
        queryClient.invalidateQueries('getSubscriptionSources');
        toast.success('Source added to subscription');
      },
      onError: (err) => handleErrors(err)
    });

  const handleAutocompleteChange = (_, newValue) => {
    if (newValue && !subscriptionSourcesData.data.some(x => x.publisher_id === newValue.publisher_id)) {
      updateMutation.mutate({
        subscriptionId: routeMatch.params.id,
        data: [
          {
            publisher_id: newValue.publisher_id
          }
        ]
      });
    }
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    setOffset(parseInt(newPage) * limit);
  };

  const handleLimitChange = (eventLimit) => {
    setLimit(parseInt(eventLimit.target.value));
    setOffset(page * parseInt(eventLimit.target.value));
  };

  return (
    <>
      {isLoadingSubscriptionSources &&
        <Card>
          <CardContent>
            <LoadingScreen />
          </CardContent>
        </Card>
      }
      {!isLoadingSubscriptionSources && subscriptionSourcesData &&
        <Card
          className={clsx(classes.root, className)}
          {...rest}>
          <CardHeader
            className={classes.cardHeader}
            title={<SubscriptionTitle subscriptionId={routeMatch.params.id} data={subscription} />} />
          <Divider />
          <CardContent>
            <InformationHeader headerTitle='Sources' tooltipTitleKey='newsletter_filter_sources_info' />
            <Box mt={1}>
              <Autocomplete
                fullWidth={true}
                open={openDropdown}
                onOpen={() => setOpenDropdown(true)}
                onClose={() => setOpenDropdown(false)}
                getOptionSelected={(option, value) => option.publisher_id === value.publisher_id}
                getOptionLabel={(option) => option.name}
                options={!isLoading && data ? data.data ? data.data : [] : []}
                loading={isLoading}
                onChange={handleAutocompleteChange}
                inputValue={searchString}
                onInputChange={(_, newInputValue) => setSearchString(newInputValue)}
                renderInput={(params) => <AutocompleteTextField params={params} isLoading={isLoading} />} />
            </Box>
            <Box mt={1}>
              <SubscriptionSourcesTable
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                totalCount={subscriptionSourcesData.pagination.total}
                sources={subscriptionSourcesData.data} />
            </Box>
          </CardContent>
        </Card>
      }
    </>
  );
};

SubscriptionSources.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.object.isRequired
};