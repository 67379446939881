import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box } from '@material-ui/core';

import {
  LoadingScreen,
  UsersTable
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';

export const UsersTab = () => {
  const { user } = useUser();
  const [ offset, setOffset ] = useState(0);
  const [ limit, setLimit ] = useState(10);
  const [ page, setPage ] = useState(0);

  const { isLoading, data } = useQuery(
    [ 'getUsers', { user, offset, limit }],
    () => userService.getUsers(user.organisation.organisation_id, offset, limit), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    setOffset(parseInt(newPage) * limit);
  };

  const handleLimitChange = (eventLimit) => {
    setLimit(parseInt(eventLimit.target.value));
    setOffset(page * parseInt(eventLimit.target.value));
  };

  useEffect(() => {
    setPage(0);
    setOffset(0);
    setLimit(10);
  }, [user]);

  return (
    <>
      {isLoading && (
        <Box style={{ height: '55vh' }}>
          <LoadingScreen />
        </Box>
      )}
      {!isLoading && data &&
        <UsersTable
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          totalCount={data.pagination.total}
          users={data.data} />
      }
    </>
  );
};