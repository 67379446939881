import axios from 'axios';

import { config } from 'helpers';

const getSubscriptions = (organisationId, offset = 0, limit = 10) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/subscriptions/user/${organisationId}?offset=${offset}&limit=${limit}`)
    .then((response) => {
      return response.data;
    });
};

const getSubscription = (subscription_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}`)
    .then((response) => {
      return response.data;
    });
};

const getSubscriptionStatus = (subscription_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/status`)
    .then((response) => {
      return response.data;
    });
};

const getSubscriptionSources = (subscription_id, offset = 0, limit = 10) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/sources?offset=${offset}&limit=${limit}`)
    .then((response) => {
      return response.data;
    });
};

const getSubscriptionFilter = (subscription_id, filter_criteria_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/${filter_criteria_id}`)
    .then((response) => {
      return response.data;
    });
};

const getSubscriptionScheduler = (organisationId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/subscriptions/${organisationId}/schedule`)
    .then((response) => {
      return response.data;
    });
};

const createSubscription = (organisationId, subscriptionName) => {
  return axios.post(`${config.openRegulationApiBaseUrl}/subscriptions?organisation_id=${organisationId}&subscription_name=${subscriptionName}`);
};

const updateSubscription = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}`, data);
};

const updateSubscriptionSource = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/sources`, data);
};

const updateSubscriptionFilterExclude = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/exclude`, data);
};

const updateSubscriptionFilterInclude = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/include`, data);
};

const updateSubscriptionFilterScope = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/scope`, data);
};

const updateSubscriptionFilterLanguage = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/language`, data);
};

const updateSubscriptionFilterType = (subscription_id, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/type`, data);
};

const updateSubscriptionScheduler = (organisationId, data) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/subscriptions/${organisationId}/schedule`, data);
};

const deleteSubscription = (subscription_id) => {
  return axios.delete(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}`);
};

const deleteSubscriptionSource = (subscription_id, publisher_id) => {
  return axios.delete(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/sources/${publisher_id}`);
};

const deleteSubscriptionFilter = (subscription_id, filter_criteria_id) => {
  return axios.delete(`${config.openRegulationApiBaseUrl}/subscriptions/${subscription_id}/filters/${filter_criteria_id}`);
};

export const subscriptionService = {
  getSubscriptions,
  getSubscription,
  getSubscriptionStatus,
  getSubscriptionSources,
  getSubscriptionFilter,
  getSubscriptionScheduler,
  createSubscription,
  updateSubscription,
  updateSubscriptionSource,
  updateSubscriptionFilterExclude,
  updateSubscriptionFilterInclude,
  updateSubscriptionFilterLanguage,
  updateSubscriptionFilterScope,
  updateSubscriptionFilterType,
  updateSubscriptionScheduler,
  deleteSubscription,
  deleteSubscriptionSource,
  deleteSubscriptionFilter
};