import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  list: {
    color: theme.palette.text.primary
  },
  errorRoot: {
    backgroundColor: theme.palette.background.standard,
    minHeight: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: '100%',
    maxWidth: 67,
    marginRight: theme.spacing(2)
  },
  textFieldRoot: {
    marginLeft: '12px',
    '& .MuiFormLabel-root.Mui-error': {
      color: 'red'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'red'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }
  }
}));