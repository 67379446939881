import {
  BookOpen as BookOpenIcon,
  DollarSign as DollarSignIcon,
  Edit as EditIcon,
  FileText as FileTextIcon,
  Home as HomeIcon,
  Users as UsersIcon
} from 'react-feather';

export const freemiumSections = (routeMatch) => {
  if (routeMatch) {
    const { ou_id, s_id } = routeMatch.params;

    return [
      {
        subheader: 'User',
        items: [
          {
            title: 'Configure newsletter',
            icon: EditIcon,
            href: `/freemium/${ou_id}/${s_id}`,
          }
        ]
      }
    ];
  } else {
    return [
      {
        subheader: 'User',
        items: [
          {
            title: 'Configure newsletter',
            icon: EditIcon,
            href: '/freemium',
          }
        ]
      }
    ];
  }
};

export const sections = [
  {
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/app',
      }
    ]
  },
  {
    subheader: 'Organisation',
    items: [
      {
        title: 'Sources',
        icon: BookOpenIcon,
        href: '/app/sources',
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/users'
      },
      {
        title: 'Billing',
        icon: DollarSignIcon,
        href: '/app/billing'
      }
    ]
  },
  {
    subheader: 'User',
    items: [
      {
        title: 'Search',
        icon: FileTextIcon,
        href: '/app/search',
      },
      {
        title: 'My newsletter',
        icon: BookOpenIcon,
        href: '/app/subscriptions',
      }
    ]
  }
];