import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { DialogTitle } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { subscriptionService } from 'services';
import { useStyles } from './CreateSubscriptionDialog.styles';

export const CreateSubscriptionDialog = ({
  openCreateSubscriptionDialog,
  onCloseCreateSubscriptionDialog
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: (values) => {
      setDisableButton(true);

      const dto = {
        organisationId: user.organisation.organisation_id,
        subscriptionName: values.name
      };

      createMutation.mutate(dto);
    }
  });

  const [ disableButton, setDisableButton ] = useState(false);

  const createMutation = useMutation(
    (createData) => subscriptionService.createSubscription(createData.organisationId, createData.subscriptionName), {
      onSuccess: () => {
        setDisableButton(false);
        formik.resetForm();
        queryClient.invalidateQueries('getSubscriptions');
        onCloseCreateSubscriptionDialog();
        toast.success('Filter created!');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  return (
    <Dialog
      open={openCreateSubscriptionDialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onCloseCreateSubscriptionDialog}>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        onClose={onCloseCreateSubscriptionDialog}>
        <Typography variant='h5'>Create filter</Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            variant='outlined'
            id='name'
            name='name'
            label='Name'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name} />
          <Box mt={2} display='flex' justifyContent='center'>
            <Button
              color='primary'
              type='submit'
              disabled={disableButton}
              variant='contained'>
              Create
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

CreateSubscriptionDialog.propTypes = {
  openCreateSubscriptionDialog: PropTypes.bool,
  onCloseCreateSubscriptionDialog: PropTypes.func
};