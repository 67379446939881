import React from 'react';
import PropTypes from 'prop-types';
import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';

import { LANDING_PAGE_TEXT, loginRequest, REDIRECT_URL } from 'helpers';

export const AuthGuard = ({ children }) => {
  const state = {
    landingText: LANDING_PAGE_TEXT,
    redirect: REDIRECT_URL
  };

  useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
    state: btoa(JSON.stringify(state))
  });

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};