import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { AutocompleteTextField } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { subscriptionService } from 'services';
import { useStyles } from './SubscriptionsSettingsTab.styles';

export const SubscriptionsSettingsTab = ({
  active,
  hoursOfDay,
  weekDays,
  timeZones,
  tab,
  handleTabsChange
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const queryClient = useQueryClient();

  const [ searchString, setSearchString ] = useState('');
  const [ selectedWeekDays, setSelectedWeekDays ] = useState([...weekDays]);
  const [ selectedHoursOfDay, setSelectedHoursOfDay ] = useState([...hoursOfDay]);
  const [ selectedTimeZone, setSelectedTimeZone ] = useState(timeZones.find(x => x.selected) ? timeZones.find(x => x.selected) : null);
  const [ settingsActive, setSettingsActive ] = useState(active);
  const [ disableButton, setDisableButton ] = useState(false);

  const editMutation = useMutation(
    (editData) => subscriptionService.updateSubscriptionScheduler(editData.organisationId, editData.data), {
      onSuccess: () => {
        setDisableButton(false);
        queryClient.invalidateQueries('getSubscriptionScheduler');
        toast.success('Subscription scheduler updated.');
        handleTabsChange(tab);
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const handleSubmit = () => {
    setDisableButton(true);

    const dto = {
      organisationId: user.organisation.organisation_id,
      data: {
        active: settingsActive,
        weekdays: selectedWeekDays,
        hours_of_day: selectedHoursOfDay,
        time_zone: timeZones.map(timeZone => timeZone.name === selectedTimeZone.name ? { ...timeZone, selected: true } : { ...timeZone, selected: false })
      }
    };

    editMutation.mutate(dto);
  };

  const handleSelectedWeekDaysChange = (weekDayEvent) => {
    setSelectedWeekDays((oldSelectedWeekDays) => [...oldSelectedWeekDays.map((oldSelectedWeekDay) => oldSelectedWeekDay.name === weekDayEvent.target.name ? { ...oldSelectedWeekDay, selected: !oldSelectedWeekDay.selected } : oldSelectedWeekDay)]);
  };

  const handleSelectedHoursOfDay = (hourOfDayEvent) => {
    setSelectedHoursOfDay((oldSelectedHoursOfDay) => [...oldSelectedHoursOfDay.map((oldSelectedHourOfDay) => oldSelectedHourOfDay.hour.toString() === hourOfDayEvent.target.name ? { ...oldSelectedHourOfDay, selected: !oldSelectedHourOfDay.selected } : oldSelectedHourOfDay)]);
  };

  const handleSettingsActiveChange = () => {
    setSettingsActive(!settingsActive);
  };

  return (
    <Card>
      <CardHeader title='Manage settings' titleTypographyProps={{ variant: 'h6' }} />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={6}
          wrap='wrap'>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className={classes.gridItem} >
            <Typography
              color='textPrimary'
              gutterBottom
              variant='h6' >
              Day(s) during the week
            </Typography>
            {selectedWeekDays.map((weekDay, index) => (
              <FormControlLabel
                key={index}
                control={(
                  <Checkbox
                    name={weekDay.name}
                    checked={weekDay.selected}
                    onChange={handleSelectedWeekDaysChange}
                    color='primary' />
                )}
                label={weekDay.name} />
            ))}
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            xs={12} >
            <Typography
              color='textPrimary'
              gutterBottom
              variant='h6'>
              Time(s) during the day
            </Typography>
            {selectedHoursOfDay.map((hourOfDay, index) => (
              <FormControlLabel
                key={index}
                control={(
                  <Checkbox
                    name={hourOfDay.hour.toString()}
                    checked={hourOfDay.selected}
                    onChange={handleSelectedHoursOfDay}
                    color='primary' />
                )}
                label={`${hourOfDay.hour.toString()}:00`} />
            ))}
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xs={12} >
            <Typography
              color='textPrimary'
              gutterBottom
              variant='h6'>
              Time zone
            </Typography>
            <Autocomplete
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={timeZones}
              value={selectedTimeZone}
              onChange={(_, newValue) => setSelectedTimeZone(newValue)}
              inputValue={searchString}
              onInputChange={(_, newInputValue) => setSearchString(newInputValue)}
              renderInput={(params) => <AutocompleteTextField params={params} isLoading={false} />} />
            <Box mt={2}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name='active'
                    checked={settingsActive}
                    onChange={handleSettingsActiveChange}
                    color='primary' />
                )}
                label='Active' />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box className={classes.cardFooter}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={disableButton}>
          Save
        </Button>
      </Box>
    </Card>
  );
};

SubscriptionsSettingsTab.propTypes = {
  active: PropTypes.bool,
  hoursOfDay: PropTypes.array,
  weekDays: PropTypes.array,
  timeZones: PropTypes.array,
  tab: PropTypes.string,
  handleTabsChange: PropTypes.func
};