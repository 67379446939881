import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './PublicationOverviewTab.styles';

export const PublicationOverviewTab = ({ publication }) => {
  const classes = useStyles();

  return (
    <Grid container direction='row'>
      <Grid item>
        <Typography variant='h5' className={classes.title}>
          {publication.title}
        </Typography>
      </Grid>
      <Grid item className={classes.overviewBody}>
        <Typography variant='body1' className={classes.abstractText}>
          {publication.abstract}
        </Typography>
      </Grid>
    </Grid>
  );
};

PublicationOverviewTab.propTypes = {
  publication: PropTypes.object.isRequired
};