import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Step,
  Stepper,
  StepLabel,
  Typography
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { useIsAuthenticated } from '@azure/msal-react';

import {
  ChooseProductTier,
  ConfirmationStep,
  CustomStepConnector,
  CustomStepIcon,
  DialogTitle,
  UpgradePlanSummary
} from 'components';
import { handleErrors, upgradeSteps } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';
import { useStyles } from './UpgradePlanDialog.styles';

export const UpgradePlanDialog = ({
  activeStep,
  completed,
  handleComplete,
  handleClose,
  onBack,
  onNext,
  openUpgradePlanDialog
}) => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const { user } = useUser();
  const [ selectedCurrency, setSelectedCurrency ] = useState('');
  const [ selectedProductTier, setSelectedProductTier ] = useState(null);

  const { isLoading, data } = useQuery(['getPricingCurrencies'], () => pricingService.getPricingCurrencies(), {
    onSuccess: (dataCurrencies) => {
      setSelectedCurrency(dataCurrencies.data[0]);
    },
    onError: (err) => handleErrors(err)
  });

  const { isLoading: isLoadingProductsPricing, data: productsPricing } = useQuery(
    [ 'getSelectedPricing', { selectedCurrency, user }],
    () => pricingService.getPricing(selectedCurrency, user.organisation.organisation_id), {
      enabled: isAuthenticated && selectedCurrency !== '' && user.organisation !== null,
      onSuccess: (dataPricing) => {
        const selectedSource = dataPricing.data.products.sources.find(x => x.selected);
        setSelectedProductTier(selectedSource ? selectedSource.id : dataPricing.data.products.sources[0].id);
      },
      onError: (err) => handleErrors(err)
    });

  const handleSelectedCurrencyChange = (radioGroupEvent) => {
    setSelectedCurrency(radioGroupEvent.target.value);
  };

  const handleSelectedProductTierChange = (productTierValue) => {
    setSelectedProductTier(productTierValue);
  };

  return (
    <Dialog
      open={openUpgradePlanDialog}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={handleClose}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={handleClose}>
        <Typography variant='h5'>Upgrade plan</Typography>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth='lg'>
          {!completed && (
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                    connector={<CustomStepConnector />}
                    orientation='vertical'>
                    {upgradeSteps.map((step) => (
                      <Step key={step.label}>
                        <StepLabel
                          StepIconComponent={CustomStepIcon}
                          StepIconProps={{
                            icon: step.icon
                          }}>
                          {step.label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box height='100%' p={3}>
                    {activeStep === 0 && (
                      <Box>
                        <Typography
                          variant='h6'
                          color='textPrimary'>
                          Information Tab
                        </Typography>
                        <Box mt={1}>
                          <Typography
                            variant='subtitle1'
                            color='textSecondary'>
                            You have decided to upgrade your plan so lets take you through this wizard so you can upgrade your plan.
                          </Typography>
                        </Box>
                        <Box mt={7} display='flex'>
                          <Box flexGrow={1} />
                          <Button
                            color='primary'
                            type='submit'
                            variant='contained'
                            size='large'
                            onClick={onNext}>
                            Next
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {activeStep === 1 && (
                      <ChooseProductTier
                        selectedCurrency={selectedCurrency}
                        selectedProductTier={selectedProductTier}
                        onSelectedCurrencyChange={handleSelectedCurrencyChange}
                        onSelectedProductTierChange={handleSelectedProductTierChange}
                        isLoadingCurrencies={isLoading}
                        isLoadingProductsPricing={isLoadingProductsPricing}
                        currencies={data}
                        productsPricing={productsPricing}
                        onBack={onBack}
                        onNext={onNext}
                        upgrade={true} />
                    )}
                    {activeStep === 2 && (
                      <UpgradePlanSummary
                        onBack={onBack}
                        handleComplete={handleComplete}
                        selectedCurrency={selectedCurrency}
                        selectedProductTier={selectedProductTier} />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          )}
          {completed && (
            <ConfirmationStep onClose={handleClose} upgrade={true} />
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

UpgradePlanDialog.propTypes = {
  activeStep: PropTypes.number,
  completed: PropTypes.bool,
  handleComplete: PropTypes.func,
  handleClose: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  openUpgradePlanDialog: PropTypes.bool
};