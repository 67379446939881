import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1)
  },
  keywordRow: {
    display: 'flex',
    alignItems: 'center'
  }
}));