import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  BillingSubscription,
  CardDetails,
  CreateBillingDialog,
  Page,
  StripeElements
} from 'components';
import { useUser } from 'hooks';
import { organisationService } from 'services';
import { tabs } from './BillingSummaryPage.config';
import { useStyles } from './BillingSummaryPage.styles';

export const BillingSummaryPage = () => {
  const classes = useStyles();
  const { user, saveUser } = useUser();
  const [ currentTab, setCurrentTab ] = useState(tabs[0].value);
  const [ openCreateBillingDialog, setOpenCreateBillingDialog ] = useState(false);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState(false);

  const handleTabsChange = (_, value) => {
    setCurrentTab(value);
  };

  const handleCreateBillingDialogOpen = () => {
    setOpenCreateBillingDialog(true);
  };

  const handleCreateBillingDialogClose = () => {
    setOpenCreateBillingDialog(false);
    setActiveStep(0);
    if (completed) {
      handleGetOrganisation();
    }

    setCompleted(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted(true);
  };

  const handleGetOrganisation = () => {
    organisationService.getOrganisations().then((response) => {
      saveUser({
        ...user,
        organisation: response && response.data && response.data.find(x => x.organisation_id === user.organisation.organisation_id)
      });
    });
  };

  return (
    <StripeElements>
      <Page
        className={clsx(classes.root, user.organisation.billing_is_present ? '' : classes.containerRoot)}
        appName={user.theme && user.theme.appName}
        icon={user.theme && user.theme.appIcon}
        title='Billing Summary'>
        <Container
          className={user.organisation.billing_is_present ? '' : classes.containerRoot}
          maxWidth={false}>
          {user.organisation.billing_is_present
            ? (
              <>
                <Typography
                  variant='h5'
                  color='textPrimary'>
                  Billing Summary
                </Typography>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                  sx={{ mt: 1 }}>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to='/app'
                    variant='subtitle2'>
                    Home
                  </Link>
                  <Typography
                    color='textSecondary'
                    variant='subtitle2'>
                    Billing
                  </Typography>
                </Breadcrumbs>
                <Box mt={3}>
                  <Tabs
                    onChange={handleTabsChange}
                    scrollButtons='auto'
                    value={currentTab}
                    variant='scrollable'
                    indicatorColor='primary'
                    textColor='primary'>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value} />
                    ))}
                  </Tabs>
                </Box>
                <Divider className={classes.dividerRoot} />
                <Box mt={3}>
                  {currentTab === 'subscription' && <BillingSubscription />}
                  {currentTab === 'card-details' && <CardDetails />}
                </Box>
              </>
            ) : (
              <Box height='100%' display='flex' flexDirection='column'>
                <Box>
                  <Typography
                    variant='h5'
                    color='textPrimary'>
                    Billing Summary
                  </Typography>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                    sx={{ mt: 1 }}>
                    <Link
                      color='textPrimary'
                      component={RouterLink}
                      to='/app'
                      variant='subtitle2'>
                      Home
                    </Link>
                    <Typography
                      color='textSecondary'
                      variant='subtitle2'>
                      Billing
                    </Typography>
                  </Breadcrumbs>
                </Box>
                <Box
                  mt={3}
                  flexGrow={1}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <Button
                    color='primary'
                    type='submit'
                    variant='contained'
                    onClick={handleCreateBillingDialogOpen}>
                    Click here to provide payment details to activate subscription
                  </Button>
                </Box>
                <CreateBillingDialog
                  activeStep={activeStep}
                  completed={completed}
                  handleComplete={handleComplete}
                  handleClose={handleCreateBillingDialogClose}
                  onBack={handleBack}
                  onNext={handleNext}
                  openCreateBillingDialog={openCreateBillingDialog} />
              </Box>
            )}
        </Container>
      </Page>
    </StripeElements>
  );
};