import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './PublicationsBanner.styles';

export const PublicationsBanner = ({ message }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems='center' className={classes.root}>
      <Grid
        item
        xs={12}
        sm={12}>
        <Typography
          color='inherit'
          variant='body1'>
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

PublicationsBanner.propTypes = {
  message: PropTypes.string.isRequired
};