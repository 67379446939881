import axios from 'axios';

import { config } from 'helpers';

const getFreemiumData = (subscription_id, organisation_user_id) => {
  let queryParams = '';
  if (subscription_id && organisation_user_id) {
    queryParams = `?subscription_id=${subscription_id}&organisation_user_id=${organisation_user_id}`;
  }

  return axios.get(`${config.openRegulationApiBaseUrl}/freemium${queryParams}`)
    .then((response) => {
      return response.data;
    });
};

const editFreemiumSubscription = (data) => {
  return axios.post(`${config.openRegulationApiBaseUrl}/freemium`, data);
};

const validateFreemiumEmail = (email) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/freemium/email/validate?email=${email}`)
    .then((response) => {
      return response.data;
    });
};

const upgradeFreemiumAccount = (organisation_user_id, partner_id) => {
  let queryParams = '';
  if (organisation_user_id) {
    queryParams = `?organisation_user_id=${organisation_user_id}`;
  } else if (partner_id) {
    queryParams = `?partner_id=${partner_id}`;
  }

  return axios.post(`${config.openRegulationApiBaseUrl}/freemium/fullaccount${queryParams}`);
};

export const freemiumService = {
  editFreemiumSubscription,
  getFreemiumData,
  validateFreemiumEmail,
  upgradeFreemiumAccount
};