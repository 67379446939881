import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import { Page } from 'components';
import { notFoundImage } from 'resources';
import { useStyles } from './NotFoundPage.styles';

const NotFoundPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page
      className={classes.root}
      title='404: Not found'>
      <Container maxWidth='lg'>
        <Typography
          align='center'
          variant={mobileDevice ? 'h6' : 'h4'}
          color='textPrimary'>
          404: The page you are looking for isn’t here
        </Typography>
        <Typography
          align='center'
          variant='subtitle2'
          color='textSecondary'>
          You either tried some shady route or you
          came here by mistake. Whichever it is, try using the navigation.
        </Typography>
        <Box
          mt={6}
          display='flex'
          justifyContent='center'>
          <img
            alt='Under development'
            className={classes.image}
            src={notFoundImage} />
        </Box>
        <Box
          mt={6}
          display='flex'
          justifyContent='center'>
          <Button
            color='primary'
            component={RouterLink}
            to='/'
            variant='outlined'>
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundPage;