import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Breadcrumbs,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from 'react-query';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  CreateSubscriptionDialog,
  LoadingScreen,
  Page,
  SubscriptionsSettingsSummaryTab,
  SubscriptionsSettingsTab,
  SubscriptionsTable
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { subscriptionService } from 'services';
import { tabs } from './SubscriptionsPage.config';
import { useStyles } from './SubscriptionsPage.styles';

export const SubscriptionsPage = () => {
  const classes = useStyles();
  const { user } = useUser();
  const [ offset, setOffset ] = useState(0);
  const [ limit, setLimit ] = useState(10);
  const [ page, setPage ] = useState(0);
  const [ openCreateSubscriptionDialog, setOpenCreateSubscriptionDialog ] = useState(false);
  const [ currentTab, setCurrentTab ] = useState(tabs[0].value);

  const { isLoading, data } = useQuery(
    [ 'getSubscriptions', { user, offset, limit }],
    () => subscriptionService.getSubscriptions(user.organisation.organisation_id, offset, limit), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingScheduler, data: schedulerData } = useQuery(
    [ 'getSubscriptionScheduler', { user }],
    () => subscriptionService.getSubscriptionScheduler(user.organisation.organisation_id), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const handleTabsChange = (_, value) => {
    setCurrentTab(value);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    setOffset(parseInt(newPage) * limit);
  };

  const handleLimitChange = (eventLimit) => {
    setLimit(parseInt(eventLimit.target.value));
    setOffset(page * parseInt(eventLimit.target.value));
  };

  const handleOpenCreateSubscriptionDialog = () => {
    setOpenCreateSubscriptionDialog(true);
  };

  const handleCloseCreateSubscriptionDialog = () => {
    setOpenCreateSubscriptionDialog(false);
  };

  useEffect(() => {
    setPage(0);
    setOffset(0);
    setLimit(10);
  }, [user]);

  return (
    <>
      {(isLoading || isLoadingScheduler) && <LoadingScreen />}
      {!isLoading && !isLoadingScheduler && schedulerData && data &&
        <Page
          className={classes.root}
          appName={user.theme && user.theme.appName}
          icon={user.theme && user.theme.appIcon}
          title='Subscriptions'>
          <Container maxWidth={false}>
            <Grid
              container
              justify='space-between'
              spacing={3}>
              <Grid item>
                <Typography
                  variant='h5'
                  color='textPrimary'>
                  My filters
                </Typography>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                  sx={{ mt: 1 }}>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to='/app'
                    variant='subtitle2'>
                    Home
                  </Link>
                  <Typography
                    color='textSecondary'
                    variant='subtitle2'>
                    My newsletter
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleOpenCreateSubscriptionDialog}>
                  Create filter
                </Button>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons='auto'
                value={currentTab}
                variant='scrollable'
                indicatorColor='primary'
                textColor='primary'>
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value} />
                ))}
              </Tabs>
            </Box>
            <Divider />
            <Box mt={3}>
              {currentTab === 'summary' &&
              <SubscriptionsSettingsSummaryTab summary={schedulerData.data.summary} />}
              {currentTab === 'settings' &&
              <SubscriptionsSettingsTab
                active={schedulerData.data.active}
                hoursOfDay={schedulerData.data.hours_of_day}
                weekDays={schedulerData.data.weekdays}
                timeZones={schedulerData.data.time_zone}
                tab={tabs[0].value}
                handleTabsChange={setCurrentTab} />}
            </Box>
            <Box mt={3}>
              <SubscriptionsTable
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                totalCount={data.pagination.total}
                subscriptions={data.data} />
            </Box>
          </Container>
          <CreateSubscriptionDialog
            openCreateSubscriptionDialog={openCreateSubscriptionDialog}
            onCloseCreateSubscriptionDialog={handleCloseCreateSubscriptionDialog} />
        </Page>
      }
    </>
  );
};