import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: '100%'
  }
}));