import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { DialogTitle } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { sourceService } from 'services';
import { useStyles } from './RequestSourcesDialog.styles';

export const RequestSourcesDialog = ({
  openRequestSourcesDialog,
  onCloseRequestSourcesDialog
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const formik = useFormik({
    initialValues: {
      message: ''
    },
    onSubmit: (values) => {
      setDisableButton(true);

      const dto = {
        organisationId: user.organisation.organisation_id,
        data: {
          message: values.message
        }
      };

      requestMutation.mutate(dto);
    }
  });

  const [ disableButton, setDisableButton ] = useState(false);

  const requestMutation = useMutation(
    (requestData) => sourceService.requestOrganisationSources(requestData.organisationId, requestData.data), {
      onSuccess: (response) => {
        setDisableButton(false);
        onCloseRequestSourcesDialog();
        toast.success(response.data.data);
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  return (
    <Dialog
      open={openRequestSourcesDialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onCloseRequestSourcesDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseRequestSourcesDialog}>
        <Typography variant='h4'>Request sources</Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id='message'
            name='message'
            label='Message'
            variant='outlined'
            multiline
            rows={3}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message} />
          <Box mt={2} display='flex' justifyContent='center'>
            <Button
              color='primary'
              className={classes.button}
              variant='contained'
              onClick={onCloseRequestSourcesDialog}>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              disabled={disableButton}
              variant='contained'>
              Send
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

RequestSourcesDialog.propTypes = {
  openRequestSourcesDialog: PropTypes.bool,
  onCloseRequestSourcesDialog: PropTypes.func
};