import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';

import { useStyles } from './ProductTierPreview.styles';

export const ProductTierPreview = ({ previewSource, previewSourceName }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography
        gutterBottom
        variant='h6'
        color='textPrimary'>
        {previewSourceName ? previewSourceName : previewSource.nickname}
      </Typography>
      {previewSource.tiers.map((tier, index) => (
        <Typography
          key={index}
          variant='body1'
          color='textPrimary'>
          {tier.friendly}
        </Typography>
      ))}
    </Paper>
  );
};

ProductTierPreview.propTypes = {
  previewSource: PropTypes.object,
  previewSourceName: PropTypes.string
};