import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { EventType, InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useMutation } from 'react-query';

import { LoadingScreen } from 'components';
import {
  b2cPolicies,
  getPartnerId,
  LANDING_PAGE_TEXT,
  REDIRECT_URL
} from 'helpers';
import { useLandingData, useUser } from 'hooks';
import {
  dashboardService,
  localiseService,
  organisationService
} from 'services';

import { useStyles } from './LandingPage.styles';

export const LandingPage = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { landingData, saveLandingData } = useLandingData();
  const { user, saveUser } = useUser();
  const [ shouldRedirect, setShouldRedirect ] = useState(true);

  const handleRedirect = () => {
    if (landingData.redirect) {
      return <Redirect to={landingData.redirect} />;
    }
  };

  const handleGetOrganisations = (newLandingData) => {
    organisationService.getOrganisations().then((response) => {
      if (response && response.data && response.data[0]) {
        localiseService.getTheme(getPartnerId(response.data[0], null)).then((themeResponse) => {
          saveUser({
            ...user,
            organisation: response && response.data && response.data[0],
            ...themeResponse.data
          });

          saveLandingData({ ...newLandingData });
        });
      }
    });
  };

  const handleSaveLandingData = (payload) => {
    if (payload.state) {
      const decodedB2CState = atob(payload.state);
      let newLandingData = JSON.parse(decodedB2CState);
      if (payload.idTokenClaims && payload.idTokenClaims.newUser) {
        newLandingData = {
          landingText: LANDING_PAGE_TEXT,
          redirect: null
        };

        saveLandingData({ ...newLandingData });
      } else {
        handleGetOrganisations(newLandingData);
      }
    }
  };

  const handleAccountCreation = (payload) => {
    let stateObject = null;
    if (payload.state) {
      const decodedB2CState = atob(payload.state);
      stateObject = JSON.parse(decodedB2CState);
    }

    landingPageMutation.mutate({
      ...(stateObject && stateObject.organisation_user_id && {
        organisation_user_id: stateObject.organisation_user_id
      }),
      ...(stateObject && stateObject.partner_id && {
        partner_id: stateObject.partner_id
      })
    });
  };

  const handleEventCallback = (message) => {
    if (message.eventType === EventType.LOGIN_FAILURE && message.interactionType === InteractionType.Redirect) {
      const b2cState = {
        landingText: LANDING_PAGE_TEXT,
        redirect: REDIRECT_URL
      };

      if (message.error.errorMessage && message.error.errorMessage.indexOf('AADB2C90118') > -1) {
        instance.loginRedirect({ ...b2cPolicies.authorities.forgotPassword, state: btoa(JSON.stringify(b2cState)) });
      } else if (message.error.errorMessage && message.error.errorMessage.indexOf('AADB2C90091') > -1) {
        instance.loginRedirect({ ...b2cPolicies.authorities.signUpSignIn, state: btoa(JSON.stringify(b2cState)) });
      }
    } else if (message.eventType === EventType.LOGIN_SUCCESS && message.interactionType === InteractionType.Redirect) {
      setShouldRedirect(false);
      handleSaveLandingData(message.payload);
      handleAccountCreation(message.payload);
    }
  };

  const landingPageMutation = useMutation((updateData) => dashboardService.updateLandingPage(updateData.organisation_user_id, updateData.partner_id), {
    onSuccess: () => {
      handleGetOrganisations({
        landingText: LANDING_PAGE_TEXT,
        redirect: REDIRECT_URL
      });
    }
  });

  useEffect(() => {
    const callbackId = instance.addEventCallback(handleEventCallback);

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return (
    <Grid
      className={classes.root}
      container
      direction='column'
      justify='center'
      alignItems='center'>
      <Grid item>
        <Typography
          color='textPrimary'
          variant='h5'>
          {landingData.landingText}
        </Typography>
      </Grid>
      <Grid item>
        <LoadingScreen />
      </Grid>
      {landingData.redirect && handleRedirect()}
      {isAuthenticated && shouldRedirect && <Redirect to='/app' />}
    </Grid>
  );
};