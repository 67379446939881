import React from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export const DotsVertical = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor' >
    <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
  </svg>, 'DotsVertical'
);