import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';

import { handleErrors, stripePromise } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';

export const StripeElements = (props) => {
  const { children } = props;
  const { user } = useUser();

  const { isLoading, data } = useQuery(
    [ 'setupIntentPricing', { user }],
    () => pricingService.setupIntentPricing(user.organisation.organisation_id), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  return (
    <>
      {!isLoading && data &&
        <Elements stripe={stripePromise} options={{
          clientSecret: data.data.client_secret
        }}>
          {children}
        </Elements>
      }
    </>
  );
};

StripeElements.propTypes = {
  children: PropTypes.node.isRequired
};