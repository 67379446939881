import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Trash2 as DeleteIcon } from 'react-feather';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { DeletePendingUserDialog } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';

import { useStyles } from './PendingUsersTable.styles';

export const PendingUsersTable = ({
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  totalCount,
  users
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [ selectedUser, setSelectedUser ] = useState(null);
  const [ openDeletePendingUserDialog, setOpenDeletePendingUserDialog ] = useState(false);
  const [ disableButton, setDisableButton ] = useState(false);

  const deleteMutation = useMutation(
    (deleteData) => userService.deletePendingUser(deleteData.organisationId, deleteData.userInvitationId), {
      onSuccess: () => {
        setDisableButton(false);
        invalidateQueries();
        setOpenDeletePendingUserDialog(false);
        toast.success('Pending user deleted successfully');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const handleOpenDeletePendingUserDialog = (userData) => {
    setSelectedUser(userData);
    setOpenDeletePendingUserDialog(true);
  };

  const handleCloseDeletePendingUserDialog = () => {
    setSelectedUser(null);
    setOpenDeletePendingUserDialog(false);
  };

  const handleDeletePendingUser = () => {
    setDisableButton(true);

    const dto = {
      organisationId: user.organisation.organisation_id,
      userInvitationId: selectedUser.user_invitation_id
    };

    deleteMutation.mutate(dto);
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries('getPendingUsers');
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((userData) => (
                <TableRow
                  key={userData.user_invitation_id}>
                  <TableCell>
                    <Box
                      display='flex'
                      alignItems='center'>
                      <Avatar
                        className={classes.avatar}
                        src={null} >
                      </Avatar>
                      <div>
                        <Typography
                          variant='body1'>
                          {userData.email}
                        </Typography>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>{userData.comment}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenDeletePendingUserDialog(userData)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
      {selectedUser &&
        <>
          <DeletePendingUserDialog
            openDeletePendingUserDialog={openDeletePendingUserDialog}
            handleDeletePendingUser={handleDeletePendingUser}
            onCloseDeletePendingUserDialog={handleCloseDeletePendingUserDialog}
            selectedUserEmail={selectedUser.email}
            disableButton={disableButton} />
        </>
      }
    </Card>
  );
};

PendingUsersTable.propTypes = {
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  users: PropTypes.array
};