import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';

import { InformationHeader } from 'components';

export const SubscriptionScopeFilter = ({ onlySearchTitle, handleScopeChange }) => {
  const [ searchTitle, setSearchTitle ] = useState(onlySearchTitle);

  const onSearchTitleChange = (radioGroupEvent) => {
    const scopeValue = radioGroupEvent.target.value === 'true';
    setSearchTitle(scopeValue);

    handleScopeChange(scopeValue);
  };

  return (
    <Box>
      <InformationHeader headerTitle='Scope' tooltipTitleKey='newsletter_filter_include_scope' />
      <FormControl component='fieldset'>
        <RadioGroup value={searchTitle} onChange={onSearchTitleChange}>
          <FormControlLabel value={false} control={<Radio color='primary' />} label='Search full publication text' />
          <FormControlLabel value={true} control={<Radio color='primary' />} label='Only search title' />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

SubscriptionScopeFilter.propTypes = {
  onlySearchTitle: PropTypes.bool,
  handleScopeChange: PropTypes.func
};