import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Card, Divider, Input, SvgIcon } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

import { AppliedPublicationsFilters, MultiSelect } from 'components';
import { Search as SearchIcon } from 'resources/icons';

import { useStyles } from './PublicationsFilters.styles';

export const PublicationsFilters = ({
  filters,
  handleFilters,
  freeText,
  handleFreeTextChange
}) => {
  const classes = useStyles();

  const handleSearchTextChange = (searchTextEvent) => {
    handleFreeTextChange(searchTextEvent.target.value);
    /* handleFilters({
      ...filters,
      textField: [
        ...filters.textField.map(field => ({
          ...field,
          value: {
            ...field.value,
            ...(searchTextEvent.target.name === field.fieldName && {
              value: searchTextEvent.target.value
            })
          }
        }))
      ]
    }); */
  };

  const handleListFieldsChange = (fieldName, newValues) => {
    handleFilters({
      ...filters,
      listField: [
        ...filters.listField.map(field => ({
          ...field,
          ...(field.fieldName === fieldName && {
            value: [...newValues]
          })
        }))
      ]
    });
  };

  const mapDateFieldFilterData = (value, subFieldName, dateField) => {
    handleFilters({
      ...filters,
      dateRange: [
        ...filters.dateRange.map(dateRangeField => ({
          ...dateRangeField,
          ...((dateRangeField.subFieldStartName === subFieldName || dateRangeField.subFieldEndName === subFieldName) && {
            [dateField]: value
          })
        }))
      ]
    });
  };

  const handleDateFieldChange = (date, value, subFieldName, dateField) => {
    if (date === null) {
      mapDateFieldFilterData(null, subFieldName, dateField);
    } else if (date && date._isValid) {
      mapDateFieldFilterData(moment(new Date(value)).format('yyyy-MM-DDTHH:mm:ssZ'), subFieldName, dateField);
    }
  };

  return (
    <Card className={classes.cardRoot}>
      {filters && filters.textField && filters.textField.map((field) => (
        <Box
          key={field.fieldName}
          className={classes.filtersBox}
          display='flex'
          alignItems='center'
          flexDirection='row'>
          <SvgIcon>
            <SearchIcon />
          </SvgIcon>
          <Box className={classes.searchBox}>
            <Input
              disableUnderline
              fullWidth
              name={field.fieldName}
              placeholder='Enter keywords here...'
              value={freeText}
              onChange={handleSearchTextChange} />
          </Box>
        </Box>
      ))}
      <Divider />
      {filters && <AppliedPublicationsFilters filters={filters} handleFilters={handleFilters} />}
      <Divider />
      <Box
        className={classes.filtersBox}
        alignItems='center'
        justifyContent='space-between'
        display='flex'
        flexDirection='row'
        flexWrap='wrap'>
        <Box
          alignItems='center'
          display='flex'
          flexDirection='row'
          flexWrap='wrap'>
          {filters && filters.listField && filters.listField.sort(x => x.sortOrder).map((field, index) => (
            <Box key={field.fieldName} className={index > 0 ? classes.filterItem : ''}>
              <MultiSelect
                id={field.fieldName}
                label={field.fieldLabel}
                multipleSelect={field.multipleSelect}
                options={field.value || []}
                onChange={handleListFieldsChange} />
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
      <Box
        className={classes.filtersBox}
        alignItems='center'
        display='flex'
        flexDirection='row'
        flexWrap='wrap'>
        {filters && filters.dateRange && filters.dateRange.map((field, index) => (
          <Box
            key={field.sortOrder}
            className={index > 0 ? classes.filterItem : classes.datefilterItem}>
            <KeyboardDatePicker
              className={classes.mobileDatefilterItem}
              variant='inline'
              format='MM/DD/yyyy'
              margin='none'
              inputVariant='outlined'
              rightArrowButtonProps={{
                color: 'primary'
              }}
              leftArrowButtonProps={{
                color: 'primary'
              }}
              autoOk
              id={field.subFieldStartName}
              name={field.subFieldStartName}
              label={`${field.fieldLabel} ${field.subFieldStartLabel}`}
              value={field.start ? new Date(field.start) : field.start}
              onChange={(date, value) => handleDateFieldChange(date, value, field.subFieldStartName, 'start')} />
            <KeyboardDatePicker
              className={clsx(classes.filterItem, classes.mobileDatefilterItem)}
              variant='inline'
              format='MM/DD/yyyy'
              margin='none'
              inputVariant='outlined'
              rightArrowButtonProps={{
                color: 'primary'
              }}
              leftArrowButtonProps={{
                color: 'primary'
              }}
              autoOk
              id={field.subFieldEndName}
              name={field.subFieldEndName}
              {...(field && field.start && {
                minDate: new Date(field.start)
              })}
              label={`${field.fieldLabel} ${field.subFieldEndLabel}`}
              value={field.end ? new Date(field.end) : field.end}
              onChange={(date, value) => handleDateFieldChange(date, value, field.subFieldEndName, 'end')} />
          </Box>
        ))}
      </Box>
    </Card>
  );
};

PublicationsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleFilters: PropTypes.func.isRequired,
  freeText: PropTypes.string.isRequired,
  handleFreeTextChange: PropTypes.func.isRequired
};