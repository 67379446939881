import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  overviewBody: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontWeight: 700
  },
  abstractText: {
    whiteSpace: 'pre-wrap'
  }
}));