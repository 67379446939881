import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    marginTop: theme.spacing(4)
  },
  avatar: {
    '& .MuiAvatar-img': {
      objectFit: 'contain'
    }
  },
  titleLink: {
    color: 'black',
    fontWeight: 700,
    textDecoration: 'none',
    margin: 0,
    marginLeft: theme.spacing(2),
    fontSize: '1.0417rem'
  },
  typography: {
    textTransform: 'none',
  },
  typographyMargin: {
    marginLeft: theme.spacing(2),
  },
  button: {
    textTransform: 'none',
    padding: '2px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    }
  },
  hitsContainer: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 20,
    marginTop: theme.spacing(2)
  },
  hitText: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& em': {
      backgroundColor: '#fcae41'
    }
  }
}));