import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  filters: {
    padding: theme.spacing(2)
  },
  noFilters: {
    padding: theme.spacing(2.5),
    marginLeft: theme.spacing(1)
  },
  chipBox: {
    '& span': {
      fontWeight: 600
    }
  },
  chipRoot: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    }
  },
  chipNext: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  }
}));