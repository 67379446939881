import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const Page = forwardRef(({
  appName = '',
  children,
  icon = '',
  title = '',
  ...rest
}, ref) => {

  return (
    <div
      ref={ref}
      {...rest}>
      <Helmet>
        <meta
          name='description'
          content={`${appName} ${(appName ? '-' : '')} ${title}`} />
        <title>{`${appName} ${(appName ? '-' : '')} ${title}`}</title>
        <link rel='icon' href={icon} sizes='16x16' />
        <link rel='apple-touch-icon' href={icon} sizes='16x16' />
      </Helmet>
      {children}
    </div>
  );
});

Page.displayName = 'Page';
Page.propTypes = {
  appName: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string
};
