import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Edit as EditIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { EditNameDialog } from 'components';
import { useStyles } from './SubscriptionTitle.styles';

export const SubscriptionTitle = ({ subscriptionId, data }) => {
  const classes = useStyles();
  const [ openEditNameDialog, setOpenEditNameDialog ] = useState(false);
  const { name: title, active } = data;

  const handleOpenEditNameDialog = () => {
    setOpenEditNameDialog(true);
  };

  const handleCloseEditNameDialog = () => {
    setOpenEditNameDialog(false);
  };

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Box>
        <Typography
          variant='h5'
          color='textPrimary'>
          {title ? `Edit ${title}` : 'Edit Subscription'}
        </Typography>
        <Breadcrumbs
          aria-label='breadcrumb'
          separator={<NavigateNextIcon fontSize='small' />}
          sx={{ mt: 1 }}>
          <Link
            color='textPrimary'
            component={RouterLink}
            to='/app'
            variant='subtitle2'>
            Home
          </Link>
          <Link
            color='textPrimary'
            component={RouterLink}
            to='/app/subscriptions'
            variant='subtitle2'>
            My newsletter
          </Link>
          <Typography
            color='textSecondary'
            variant='subtitle2'>
            {title ? `Edit ${title}` : 'Edit Subscription'}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Tooltip title='Edit Name' placement='right'>
        <IconButton
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={handleOpenEditNameDialog}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <EditNameDialog
        active={active}
        defaultName={title}
        subscriptionId={subscriptionId}
        openEditNameDialog={openEditNameDialog}
        onCloseEditNameDialog={handleCloseEditNameDialog} />
    </Box>
  );
};

SubscriptionTitle.propTypes = {
  subscriptionId: PropTypes.string,
  data: PropTypes.object
};