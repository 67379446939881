import moment from 'moment';
import { toast } from 'react-toastify';

import { b2cPolicies, browserHistory, loginRequest } from 'helpers';

export const getSilentRequest = (account) => {
  let silentRequest = {
    ...loginRequest,
    account: account
  };

  switch (account.idTokenClaims['acr'].toLowerCase()) {
    case b2cPolicies.names.signUpSignIn.toLocaleLowerCase():
      silentRequest = {
        ...silentRequest,
        authority: b2cPolicies.authorities.signUpSignIn.authority
      };

      break;
    case b2cPolicies.names.forgotPassword.toLowerCase():
      silentRequest = {
        ...silentRequest,
        authority: b2cPolicies.authorities.forgotPassword.authority
      };

      break;
    case b2cPolicies.names.editProfile.toLowerCase():
      silentRequest = {
        ...silentRequest,
        authority: b2cPolicies.authorities.editProfile.authority
      };

      break;
    case b2cPolicies.names.signUp.toLowerCase():
      silentRequest = {
        ...silentRequest,
        authority: b2cPolicies.authorities.signUp.authority
      };

      break;
  }

  return silentRequest;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [ el, index ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const handleErrors = (errorResponse) => {
  const errorText = errorResponse && errorResponse.data && errorResponse.data.errors && Array.isArray(errorResponse.data.errors) ? errorResponse.data.errors.map(error => error.friendly).join(' ') : 'Something went wrong. Please try again.';

  toast.error(errorText);
};

export const getPartnerId = (user, partnerId) => {
  if (partnerId) {
    return partnerId;
  } else if (user && user.organisation && user.organisation.partner_id) {
    return user.organisation.partner_id;
  } else {
    return '';
  }
};

export const handleOpenLink = (url) => {
  if (url.startsWith('/app')) {
    browserHistory.replace(url);
  } else {
    window.open(url, '_blank');
  }
};

export const setDefaultFilters = (filters, queryString) => ({
  ...filters,
  textField: [
    ...filters.textField.map(field => ({
      ...field,
      value: {
        ...field.value,
        ...(field.fieldName === 'freetext' && queryString.get('freetext') && {
          value: queryString.get('freetext')
        })
      }
    }))
  ],
  listField: [
    ...filters.listField.map(field => ({
      ...field,
      ...(field.fieldName === 'only_search_title' && queryString.get('only_search_title') && {
        value: [
          ...field.value.map(val => ({
            ...val,
            ...(queryString.get('only_search_title').split(',').includes(val.key) && {
              selected: true
            }),
          }))
        ]
      }),
      ...(field.fieldName === 'freetext_language' && queryString.get('freetext_language') && {
        value: [
          ...field.value.map(val => ({
            ...val,
            ...(queryString.get('freetext_language').split(',').includes(val.key) && {
              selected: true
            })
          }))
        ]
      }),
      ...(field.fieldName === 'publishers' && queryString.get('publishers') && {
        value: [
          ...field.value.map(val => ({
            ...val,
            ...(queryString.get('publishers').split(',').includes(val.key) && {
              selected: true
            })
          }))
        ]
      }),
      ...(field.fieldName === 'types' && queryString.get('types') && {
        value: [
          ...field.value.map(val => ({
            ...val,
            ...(queryString.get('types').split(',').includes(val.key) && {
              selected: true
            })
          }))
        ]
      }),
      ...(field.fieldName === 'languages' && queryString.get('languages') && {
        value: [
          ...field.value.map(val => ({
            ...val,
            ...(queryString.get('languages').split(',').includes(val.key) && {
              selected: true
            })
          }))
        ]
      }),
      ...(field.fieldName === 'order_by' && queryString.get('order_by') && {
        value: [
          ...field.value.map(val => ({
            ...val,
            ...(queryString.get('order_by').split(',').includes(val.key) && {
              selected: true
            })
          }))
        ]
      }),
    }))
  ],
  dateRange: [
    ...filters.dateRange.map(dateRangeField => ({
      ...dateRangeField,
      ...(dateRangeField.subFieldStartName === 'created_date_start' && queryString.get('created_date_start') && {
        start: queryString.get('created_date_start')
      }),
      ...(dateRangeField.subFieldStartName === 'created_date_start' && !queryString.get('created_date_start') && {
        start: null
      }),
      ...(dateRangeField.subFieldEndName === 'created_date_end' && queryString.get('created_date_end') && {
        end: queryString.get('created_date_end')
      }),
      ...(dateRangeField.subFieldEndName === 'created_date_end' && !queryString.get('created_date_end') && {
        end: null
      }),
      ...(dateRangeField.subFieldStartName === 'publication_date_start' && queryString.get('publication_date_start') && {
        start: queryString.get('publication_date_start')
      }),
      ...(dateRangeField.subFieldStartName === 'publication_date_start' && !queryString.get('publication_date_start') && {
        start: null
      }),
      ...(dateRangeField.subFieldEndName === 'publication_date_end' && queryString.get('publication_date_end') && {
        end: queryString.get('publication_date_end')
      }),
      ...(dateRangeField.subFieldEndName === 'publication_date_end' && !queryString.get('publication_date_end') && {
        end: null
      })
    }))
  ]
});

export const getFiltersObject = (filters) => {
  if (filters) {
    return [
      filters?.textField.find(field => field.fieldName === 'freetext').value.value,
      filters?.listField.find(field => field.fieldName === 'only_search_title').value.filter(x => x.selected).map(x => x.key).join(','),
      filters?.listField.find(field => field.fieldName === 'freetext_language').value.filter(x => x.selected).map(x => x.key).join(','),
      filters?.listField.find(field => field.fieldName === 'publishers').value.filter(x => x.selected).map(x => x.key).join(','),
      filters?.listField.find(field => field.fieldName === 'types').value.filter(x => x.selected).map(x => x.key).join(','),
      filters?.listField.find(field => field.fieldName === 'languages').value.filter(x => x.selected).map(x => x.key).join(','),
      filters?.listField.find(field => field.fieldName === 'order_by').value.filter(x => x.selected).map(x => x.key).join(','),
      filters?.dateRange.find(field => field.subFieldStartName === 'created_date_start').start ? moment(new Date(filters?.dateRange.find(field => field.subFieldStartName === 'created_date_start').start)).format('yyyy-MM-DDTHH:mm:ss') : '',
      filters?.dateRange.find(field => field.subFieldEndName === 'created_date_end').end ? moment(new Date(filters?.dateRange.find(field => field.subFieldEndName === 'created_date_end').end)).format('yyyy-MM-DDTHH:mm:ss') : '',
      filters?.dateRange.find(field => field.subFieldStartName === 'publication_date_start').start ? moment(new Date(filters?.dateRange.find(field => field.subFieldStartName === 'publication_date_start').start)).format('yyyy-MM-DDTHH:mm:ss') : '',
      filters?.dateRange.find(field => field.subFieldEndName === 'publication_date_end').start ? moment(new Date(filters?.dateRange.find(field => field.subFieldEndName === 'publication_date_end').end)).format('yyyy-MM-DDTHH:mm:ss') : ''
    ];
  }

  return [];
};

export const getQueryString = (
  organisation_id,
  offset,
  freetext,
  only_search_title,
  freetext_language,
  publishers,
  types,
  languages,
  order_by,
  created_date_start,
  created_date_end,
  publication_date_start,
  publication_date_end,
) => {
  let queryString = `?offset=${offset}`;

  if (only_search_title) {
    queryString += `&only_search_title=${only_search_title}`;
  }

  if (organisation_id) {
    queryString += `&organisation_id=${organisation_id}`;
  }

  if (freetext) {
    queryString += `&freetext=${freetext}`;
  }

  if (freetext_language) {
    queryString += `&freetext_language=${freetext_language}`;
  }

  if (publishers) {
    queryString += `&publishers=${publishers}`;
  }

  if (types) {
    queryString += `&types=${types}`;
  }

  if (languages) {
    queryString += `&languages=${languages}`;
  }

  if (order_by) {
    queryString += `&order_by=${order_by}`;
  }

  if (created_date_start) {
    queryString += `&created_date_start=${created_date_start}`;
  }

  if (created_date_end) {
    queryString += `&created_date_end=${created_date_end}`;
  }

  if (publication_date_start) {
    queryString += `&publication_date_start=${publication_date_start}`;
  }

  if (publication_date_end) {
    queryString += `&publication_date_end=${publication_date_end}`;
  }

  return queryString;
};