import {
  Info as InfoIcon,
  Layers as LayersIcon,
  CreditCard as CreditCardIcon
} from 'react-feather';
import {
  AcademicCap,
  Adjustments,
  Archive,
  ArrowLeft,
  ArrowRight,
  ArrowsExpand,
  Ban,
  Bell,
  Briefcase,
  Calendar,
  Camera,
  Cash,
  ChartPie,
  ChartSquareBar,
  ChatAlt,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard,
  ClipboardList,
  Clock,
  Cog,
  CreditCard,
  CurrencyDollar,
  DeviceTablet,
  DocumentText,
  Dot,
  DotsHorizontal,
  DotsVertical,
  Download,
  Duplicate,
  Exclamation,
  ExclamationCircle,
  ExternalLink,
  Eye,
  EyeOff,
  FingerPrint,
  FolderOpen,
  Home,
  Inbox,
  InformationCircle,
  Lock,
  Mail,
  Menu,
  Minus,
  Moon,
  PencilAlt,
  Phone,
  Picture,
  Plus,
  Refresh,
  Save,
  Search,
  Share,
  ShoppingBag,
  ShoppingCart,
  SortAscending,
  SortDescending,
  Star,
  Sun,
  Template,
  Trash,
  Upload,
  User,
  UserAdd,
  Users,
  X
} from 'resources/icons';

export const sourceStatusEnum = {
  ACTIVE: {
    text: 'ACTIVE',
    color: 'success'
  },
  NOT_SAVED: {
    text: 'NOT SAVED',
    color: 'warning'
  }
};

export const subscriptionStatusEnum = {
  ACTIVE: {
    text: 'ACTIVE',
    color: 'success'
  },
  INACTIVE: {
    text: 'INACTIVE',
    color: 'warning'
  }
};

export const userRoleEnum = {
  YES: {
    text: 'YES',
    color: 'success'
  },
  NO: {
    text: 'NO',
    color: 'error'
  }
};

export const helpTextEnum = {
  '/app': 'dashboard',
  '/app/sources': 'sources',
  '/app/users': 'users',
  '/app/billing': 'billing',
  '/app/subscriptions': 'newsletter',
  '/app/subscriptions/:id/edit': 'newsletter_filter',
  '/app/search': 'search_list',
  '/app/publications/:id': 'publication_details',
  '/freemium': 'freemium_new',
  '/freemium/:ou_id/:s_id': 'freemium_existing'
};

export const steps = [
  {
    label: 'Information Tab',
    icon: InfoIcon
  },
  {
    label: 'Choose Product Tier',
    icon: LayersIcon
  },
  {
    label: 'Add Payment Method',
    icon: CreditCardIcon
  }
];

export const upgradeSteps = [
  {
    label: 'Information Tab',
    icon: InfoIcon
  },
  {
    label: 'Choose Product Tier',
    icon: LayersIcon
  },
  {
    label: 'Summary',
    icon: DocumentText
  }
];

export const iconsEnum = {
  AcademicCap,
  Adjustments,
  Archive,
  ArrowLeft,
  ArrowRight,
  ArrowsExpand,
  Ban,
  Bell,
  Briefcase,
  Calendar,
  Camera,
  Cash,
  ChartPie,
  ChartSquareBar,
  ChatAlt,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard,
  ClipboardList,
  Clock,
  Cog,
  CreditCard,
  CurrencyDollar,
  DeviceTablet,
  DocumentText,
  Dot,
  DotsHorizontal,
  DotsVertical,
  Download,
  Duplicate,
  Exclamation,
  ExclamationCircle,
  ExternalLink,
  Eye,
  EyeOff,
  FingerPrint,
  FolderOpen,
  Home,
  Inbox,
  InformationCircle,
  Lock,
  Mail,
  Menu,
  Minus,
  Moon,
  PencilAlt,
  Phone,
  Picture,
  Plus,
  Refresh,
  Save,
  Search,
  Share,
  ShoppingBag,
  ShoppingCart,
  SortAscending,
  SortDescending,
  Star,
  Sun,
  Template,
  Trash,
  Upload,
  User,
  UserAdd,
  Users,
  X
};