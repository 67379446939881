import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';

import { DialogTitle } from 'components';

import { useStyles } from './DeletePendingUserDialog.styles';

export const DeletePendingUserDialog = ({
  disableButton,
  openDeletePendingUserDialog,
  selectedUserEmail,
  handleDeletePendingUser,
  onCloseDeletePendingUserDialog
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openDeletePendingUserDialog}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onCloseDeletePendingUserDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseDeletePendingUserDialog}>
        <Typography variant='h5'>Delete pending user</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {`You are about to delete this user invitation: ${selectedUserEmail}.`}
        </Typography>
        <Typography>Would you like to continue?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={onCloseDeletePendingUserDialog}>
          No
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={disableButton}
          onClick={handleDeletePendingUser}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeletePendingUserDialog.propTypes = {
  disableButton: PropTypes.bool,
  openDeletePendingUserDialog: PropTypes.bool,
  selectedUserEmail: PropTypes.string,
  handleDeletePendingUser: PropTypes.func,
  onCloseDeletePendingUserDialog: PropTypes.func
};