import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  buttonRoot: {
    borderRadius: 8,
    textTransform: 'none'
  },
  menuItem: {
    flexGrow: 1,
    marginRight: 0
  }
}));