import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardRoot: {
    borderRadius: 20,
    marginTop: theme.spacing(4)
  },
  filtersBox: {
    padding: theme.spacing(2)
  },
  searchBox: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  filterItem: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
    }
  },
  datefilterItem: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main
    },
    '& .MuiInputBase-root': {
      paddingRight: 0,
    }
  },
  mobileDatefilterItem: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  }
}));