import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  circularProgress: {
    color: theme.palette.primary.contrastText
  }
}));