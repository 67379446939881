import axios from 'axios';

import { config } from 'helpers';

const getUsers = (organisationId, offset = 0, limit = 10) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/users/${organisationId}?offset=${offset}&limit=${limit}`)
    .then((response) => {
      return response.data;
    });
};

const getPendingUsers = (organisationId, offset = 0, limit = 10) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/users/${organisationId}/invite?offset=${offset}&limit=${limit}`)
    .then((response) => {
      return response.data;
    });
};

const getCurrentUser = (organisationId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/users/${organisationId}/current`)
    .then((response) => {
      return response.data;
    });
};

const getUserStatus = (organisationId, userId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/users/${organisationId}/${userId}/status`)
    .then((response) => {
      return response.data;
    });
};

const inviteUser = (organisationId, data) => {
  return axios.post(`${config.openRegulationApiBaseUrl}/users/invite?organisation_id=${organisationId}`, data);
};

const updatePermissions = (userId, updateData) => {
  return axios.patch(`${config.openRegulationApiBaseUrl}/users/${userId}/permissions`, updateData);
};

const deleteUser = (organisationId, userId) => {
  return axios.delete(`${config.openRegulationApiBaseUrl}/users/${organisationId}/${userId}`);
};

const deletePendingUser = (organisationId, user_invitation_id) => {
  return axios.delete(`${config.openRegulationApiBaseUrl}/users/${organisationId}/invite/${user_invitation_id}`);
};

export const userService = {
  getUsers,
  getPendingUsers,
  getCurrentUser,
  getUserStatus,
  inviteUser,
  updatePermissions,
  deleteUser,
  deletePendingUser
};