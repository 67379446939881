import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Typography
} from '@material-ui/core';

export const AutocompleteNoOptionsText = ({
  handleOpenRequestSourcesDialog
}) => {

  return (
    <Typography>
      Source not found –&nbsp;
      <Link
        component='button'
        variant='body1'
        onMouseDown={handleOpenRequestSourcesDialog}>
        request here
      </Link>
    </Typography>
  );
};

AutocompleteNoOptionsText.propTypes = {
  handleOpenRequestSourcesDialog: PropTypes.func
};