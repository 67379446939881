import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Tooltip,
  Typography
} from '@material-ui/core';

import { CustomChart } from 'components';
import { InformationCircle } from 'resources/icons';
import { useStyles } from './LineChartCard.styles';

export const LineChartCard = ({ lineChartData }) => {
  const classes = useStyles();
  const [ selectedSeries, setSelectedSeries ] = useState([...lineChartData.data.series.map(x => x.name)]);

  const handleChange = (changeEvent, seriesName) => {
    if (changeEvent.target.checked) {
      setSelectedSeries([ ...selectedSeries, seriesName ]);
    } else {
      setSelectedSeries(selectedSeries.filter((item) => item !== seriesName));
    }
  };

  const visibleSeries = lineChartData.data.series.filter((item) => selectedSeries.includes(item.name));

  return (
    <Card>
      <CardHeader
        disableTypography
        title={(
          <Box className={classes.titleBox}>
            <Typography
              color='textPrimary'
              variant='h6'>
              {lineChartData.header.heading}
            </Typography>
            <Tooltip title={lineChartData.header.help ? lineChartData.header.help : ''}>
              <InformationCircle fontSize='small' />
            </Tooltip>
          </Box>
        )} />
      <CardContent>
        <Box
          px={2}
          display='flex'
          alignItems='center'
          flexWrap='wrap'>
          {lineChartData.data.series.map((item, index) => (
            <Box
              key={index}
              mr={2}
              alignItems='center'
              display='flex'>
              <Checkbox
                checked={selectedSeries.some((visibleItem) => visibleItem === item.name)}
                color='primary'
                onChange={(changeEvent) => handleChange(changeEvent, item.name)} />
              <Box
                style={{ backgroundColor: item.color }}
                className={classes.box}
                ml={1}
                mr={2} />
              <Typography
                color='textPrimary'
                variant='subtitle2'>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <CustomChart visibleSeries={visibleSeries} dataPoints={lineChartData.data.x_axis} />
      </CardContent>
    </Card>
  );
};

LineChartCard.propTypes = {
  lineChartData: PropTypes.object
};