import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  drawer: {
    width: '320px',
    height: '100%',
    padding: theme.spacing(2)
  },
  container: {
    height: '100%'
  }
}));