import React, {
  createContext,
  useEffect,
  useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const defaultUser = {
  organisation: null,
  theme: null,
  logo: null,
  name: null
};

export const restoreUser = () => {
  let user = null;

  try {
    const storedData = window.localStorage.getItem('user');

    if (storedData) {
      user = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return user;
};

export const storeUser = (user) => {
  window.localStorage.setItem('user', JSON.stringify(user));
};

export const UserContext = createContext({
  user: defaultUser,
  /* eslint-disable-next-line */
  saveUser: () => {}
});

export const UserProvider = ({ children, user }) => {
  const [ currentUser, setCurrentUser ] = useState(user || defaultUser);

  const handleSaveUser = (update = {}) => {
    const mergedUser = _.merge({}, currentUser, update);

    setCurrentUser(mergedUser);
    storeUser(mergedUser);
  };

  useEffect(() => {
    const restoredUser = restoreUser();

    if (restoredUser) {
      setCurrentUser(restoredUser);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        saveUser: handleSaveUser
      }} >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.any
};

export const UserConsumer = UserContext.Consumer;