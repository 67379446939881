import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    width: '100%',
    maxWidth: 67,
    marginRight: theme.spacing(1)
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));