import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography
} from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { LoadingScreen, SimulatePricingTable } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { pricingService } from 'services';

export const UpgradePlanSummary = ({
  handleComplete,
  onBack,
  selectedCurrency,
  selectedProductTier
}) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [ disableButton, setDisableButton ] = useState(false);

  const { isLoading: isLoadingSimulatePricing, data: simulatePricingData } = useQuery(
    [ 'simulatePricing', { user }],
    () => pricingService.simulatePricing(user.organisation.organisation_id, selectedCurrency), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const editBillingSubscription = useMutation(
    (billingSubscriptionData) => pricingService.createBillingSubscription(billingSubscriptionData), {
      onSuccess: () => {
        setDisableButton(false);
        handleComplete();
        invalidateQueries();
        toast.success('Plan upgraded successfully');
      },
      onError: (err) => {
        setDisableButton(true);
        handleErrors(err);
      }
    });

  const handleSubmit = () => {
    setDisableButton(true);

    const dto = {
      organisation_id: user.organisation.organisation_id,
      price_id: selectedProductTier,
      currency: selectedCurrency
    };

    editBillingSubscription.mutate(dto);
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === 'getOrganisations' || query.queryKey[0] === 'getOrganisationPlan'
    });
  };

  return (
    <>
      {isLoadingSimulatePricing && <LoadingScreen />}
      {!isLoadingSimulatePricing && simulatePricingData &&
        <Box>
          <Typography
            variant='h6'
            color='textPrimary'>
            Upgrade Plan Summary
          </Typography>
          <Box mt={2}>
            <Typography
              variant='subtitle1'
              color='textSecondary'>
              In this step you can see upgrade plan summary.
            </Typography>
          </Box>
          <Box mt={2}>
            <SimulatePricingTable data={simulatePricingData.data} />
          </Box>
          <Box mt={6} display='flex'>
            <Button
              onClick={onBack}
              size='large'>
              Previous
            </Button>
            <Box flexGrow={1} />
            <Button
              color='primary'
              variant='contained'
              size='large'
              onClick={handleSubmit}
              disabled={disableButton}>
              Complete
            </Button>
          </Box>
        </Box>
      }
    </>
  );
};

UpgradePlanSummary.propTypes = {
  handleComplete: PropTypes.func,
  onBack: PropTypes.func,
  selectedCurrency: PropTypes.string,
  selectedProductTier: PropTypes.any
};