import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';

export const SimulatePricingTable = ({ data }) => {

  return (
    <TableContainer>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align='right'>Count</TableCell>
            <TableCell>Total cost/month</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.products.map((product, index) => (
            <TableRow key={index}>
              <TableCell>{product.name}</TableCell>
              <TableCell align='right'>{product.count}</TableCell>
              <TableCell>{`${data.currency.toUpperCase()} ${product.cost}`}</TableCell>
              <TableCell>
                <Tooltip title={product.help} placement='top'>
                  <Typography>(i)</Typography>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SimulatePricingTable.propTypes = {
  data: PropTypes.object,
};