import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  ArrowRight as ArrowRightIcon
} from 'react-feather';

import { KPIChart } from 'components';
import { handleOpenLink, iconsEnum } from 'helpers';
import { InformationCircle } from 'resources/icons';
import { useStyles } from './KPICard.styles';

export const KPICard = ({ kpiData }) => {
  const classes = useStyles();

  const handleIcon = (icon) => {
    const Icon = iconsEnum[icon];
    switch (icon) {
      case 'ChevronUp':
        return (
          <Avatar className={`${classes.avatar} ${classes.successBackgroundColor}`}>
            <Icon />
          </Avatar>
        );
      case 'ChevronDown':
        return (
          <Avatar className={`${classes.avatar} ${classes.errorBackgroundColor}`}>
            <Icon />
          </Avatar>
        );
      default:
        return (
          <Avatar className={`${classes.avatar} ${classes.primaryBackgroundColor}`}>
            <Icon />
          </Avatar>
        );
    }
  };

  return (
    <Card>
      <Box
        p={3}
        display='flex'
        alignItems='center'
        justifyContent='space-between'>
        <Box>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box mr={1}>
              <Typography
                color='textPrimary'
                variant='subtitle2'>
                {kpiData.header.heading}
              </Typography>
            </Box>
            <Tooltip title={kpiData.header.help}>
              <InformationCircle fontSize='small' />
            </Tooltip>
          </Box>
          <Typography
            color='textPrimary'
            className={classes.typography}
            variant='h5'>
            {kpiData.header.subtitle}
          </Typography>
        </Box>
        <KPIChart kpiChartData={kpiData.data} />
      </Box>
      {kpiData.footer &&
        <>
          <Divider />
          {kpiData.footer.link &&
            <Box px={3} py={2}>
              <Button
                color='primary'
                className={classes.footerButton}
                endIcon={<ArrowRightIcon />}
                onClick={() => handleOpenLink(kpiData.footer.link.url)}
                variant='text'>
                {kpiData.footer.link.text}
              </Button>
            </Box>
          }
          {kpiData.footer.text &&
            <Box
              px={3}
              py={2}
              display='flex'
              alignItems='center'>
              {handleIcon(kpiData.footer.text.icon)}
              <Box ml={1}>
                <Typography
                  color='textSecondary'
                  variant='caption'>
                  {kpiData.footer.text.text}
                </Typography>
              </Box>
            </Box>
          }
        </>
      }
    </Card>
  );
};

KPICard.propTypes = {
  kpiData: PropTypes.object
};