import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Container,
  Grid
} from '@material-ui/core';
import { useQuery } from 'react-query';

import {
  LoadingScreen,
  Page,
  SubscriptionFilters,
  SubscriptionSources
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { subscriptionService } from 'services';
import { useStyles } from './EditSubscriptionPage.styles';

export const EditSubscriptionPage = () => {
  const classes = useStyles();
  const { user } = useUser();
  const routeMatch = useRouteMatch({
    path: '/app/subscriptions/:id/edit',
    strict: true
  });

  const { isLoading: isLoadingSubscription, data: subscriptionData } = useQuery(
    [ 'getSubscription', { routeMatch }],
    () => subscriptionService.getSubscription(routeMatch.params.id), {
      enabled: routeMatch !== null,
      onError: (err) => handleErrors(err)
    });

  return (
    <>
      {isLoadingSubscription && <LoadingScreen />}
      {!isLoadingSubscription && subscriptionData &&
        <Page
          className={classes.root}
          appName={user.theme && user.theme.appName}
          icon={user.theme && user.theme.appIcon}
          title='Edit Subscription'>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}>
              <Grid item md={5} xl={4} xs={12}>
                <SubscriptionSources subscription={subscriptionData.data} />
              </Grid>
              <Grid item md={7} xl={8} xs={12}>
                <SubscriptionFilters filter={subscriptionData.data.filter} />
              </Grid>
            </Grid>
          </Container>
        </Page>
      }
    </>
  );
};