import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { fade, useTheme } from '@material-ui/core/styles';

export const KPIChart = ({ kpiChartData }) => {
  const theme = useTheme();

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: [fade(theme.palette.primary.main, 0.6)],
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false
      },
      stroke: {
        width: 3
      },
      theme: {
        mode: theme.palette.mode
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: false
      }
    },
    series: [
      {
        data: [...kpiChartData]
      }
    ]
  };

  return (
    <Chart
      type='line'
      width={120}
      {...chart} />
  );
};

KPIChart.propTypes = {
  kpiChartData: PropTypes.array
};