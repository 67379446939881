import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, SvgIcon } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useStyles } from './MultiSelect.styles';

export const MultiSelect = ({ id, label, onChange, options, multipleSelect = false }) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [ openMenu, setOpenMenu ] = useState(false);

  const handleMenuOpen = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const handleValueChange = useCallback((selectEvent) => {
    let newValues = [...options];

    newValues = newValues.map(newValue => ({
      ...newValue,
      ...(!multipleSelect && {
        selected: false
      }),
      ...(newValue.key === selectEvent.target.value && {
        selected: !newValue.selected
      })
    }));

    onChange?.(id, newValues);
  }, [ id, multipleSelect, options, onChange ]);

  return (
    <>
      <Button
        className={classes.buttonRoot}
        color='inherit'
        endIcon={(
          <SvgIcon>
            <KeyboardArrowDownIcon />
          </SvgIcon>
        )}
        onClick={handleMenuOpen}
        ref={anchorRef}>
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleMenuClose}
        open={openMenu}
        id={id}
        PaperProps={{ style: { minWidth: 250 } }}>
        {options.map((option) => (
          <MenuItem key={option.key}>
            <FormControlLabel
              control={(
                <Checkbox
                  color='primary'
                  checked={option.selected}
                  onChange={handleValueChange}
                  value={option.key} />
              )}
              label={option.value}
              className={classes.menuItem} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  multipleSelect: PropTypes.bool,
};
