import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((/*theme*/) => ({
  root: {},
  avatar: {
    height: 36,
    width: 36,
    backgroundColor: 'transparent'
  },
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));