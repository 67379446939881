import axios from 'axios';

import { config } from 'helpers';

const getPricing = (currency = 'gbp', organisationId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/pricing/${currency}/${organisationId}`)
    .then((response) => {
      return response.data;
    });
};

const getPricingCurrencies = () => {
  return axios.get(`${config.openRegulationApiBaseUrl}/pricing/currencies`).then((response) => {
    return response.data;
  });
};

const getDefaultCard = (organisationId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/pricing/defaultcard?organisation_id=${organisationId}`)
    .then((response) => {
      return response.data;
    });
};

const setDefaultCard = (data) => {
  return axios.post(`${config.openRegulationApiBaseUrl}/pricing/defaultcard`, data);
};

const simulatePricing = (organisationId, currency = '') => {
  return axios.get(`${config.openRegulationApiBaseUrl}/pricing/simulate/${organisationId}/${currency}`)
    .then((response) => {
      return response.data;
    });
};

const createBillingSubscription = (data) => {
  return axios.post(`${config.openRegulationApiBaseUrl}/pricing/subscriptions`, data);
};

const setupIntentPricing = (organisationId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/pricing/setupintent?organisation_id=${organisationId}`)
    .then((response) => {
      return response.data;
    });
};

export const pricingService = {
  getPricing,
  getPricingCurrencies,
  getDefaultCard,
  simulatePricing,
  createBillingSubscription,
  setDefaultCard,
  setupIntentPricing
};