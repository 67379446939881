import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  }
}));