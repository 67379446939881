import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';

import {
  getComparator,
  handleOpenLink,
  iconsEnum,
  stableSort
} from 'helpers';
import { ExternalLink, InformationCircle } from 'resources/icons';
import { useStyles } from './TableCard.styles';

export const TableCard = ({ tableData }) => {
  const classes = useStyles();
  const [ order, setOrder ] = useState('asc');
  const [ orderBy, setOrderBy ] = useState(tableData.data.columns.find(col => col.sort).id);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleIcon = (icon) => {
    const Icon = iconsEnum[icon];

    return <Icon color='primary' />;
  };

  return (
    <Card>
      <CardHeader
        disableTypography
        title={(
          <Box className={classes.titleBox}>
            <Typography
              color='textPrimary'
              variant='h6'>
              {tableData.header.heading}
            </Typography>
            <Tooltip title={tableData.header.help}>
              <InformationCircle fontSize='small' />
            </Tooltip>
          </Box>
        )} />
      <Table>
        <TableHead>
          <TableRow>
            {tableData.data.columns.map((column) => (
              <React.Fragment key={column.id}>
                {column.sort &&
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}>
                      {column.name}
                      {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                }
                {!column.sort &&
                  <TableCell key={column.id}>{column.name}</TableCell>
                }
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(tableData.data.rows, getComparator(order, orderBy)).map((row, i) => (
            <TableRow key={i}>
              {Object.keys(row).map((rowKey, j) => {
                const column = tableData.data.columns.find(col => col.id === rowKey);
                switch (column.type) {
                  case 'icon':
                    return (
                      <TableCell key={j}>
                        <Avatar className={`${classes.avatar}`}>
                          {handleIcon(row[rowKey])}
                        </Avatar>
                      </TableCell>
                    );
                  case 'url':
                    return (
                      <TableCell key={j}>
                        <Tooltip title={row[rowKey]} placement='bottom'>
                          <IconButton
                            variant='contained'
                            color='primary'
                            onClick={() => handleOpenLink(row[rowKey])}>
                            <ExternalLink />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    );
                  default:
                    return <TableCell key={j}>{row[rowKey]}</TableCell>;
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

TableCard.propTypes = {
  tableData: PropTypes.object
};