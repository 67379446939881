import React from 'react';
import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

export const Picture = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor' >
    <path
      fillRule='evenodd'
      d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z'
      clipRule='evenodd' />
  </svg>, 'Picture'
);