import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Typography
} from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
  EditSubscriptionFilterForm,
  DialogTitle,
  LoadingScreen
} from 'components';
import { handleErrors } from 'helpers';
import { subscriptionService } from 'services';
import { useStyles } from './EditSubscriptionFilterDialog.styles';

export const EditSubscriptionFilterDialog = ({
  filterData,
  languages,
  disableButton,
  handleDisableButtonChange,
  handleUpdateSubscriptionFilter,
  openEditSubscriptionFilterDialog,
  onCloseEditSubscriptionFilterDialog
}) => {
  const classes = useStyles();
  const routeMatch = useRouteMatch({
    path: '/app/subscriptions/:id/edit',
    strict: true
  });
  const [ selectedLanguage, setSelectedLanguage ] = useState(null);

  const { isLoading, data } = useQuery(
    [ 'getSubscriptionFilter', { routeMatch, filterData }],
    () => subscriptionService.getSubscriptionFilter(routeMatch.params.id, filterData.id), {
      enabled: routeMatch !== null && filterData !== null,
      onError: (err) => handleErrors(err)
    });

  const handleSubmit = (values) => {
    handleDisableButtonChange(true);

    const dto = [
      {
        ...(filterData && { id: filterData.id }),
        keywords: values.keywords,
        distance: values.keywords && values.keywords.length > 1 ? parseInt(values.distance) : 0,
        language: selectedLanguage ? [
          { ...selectedLanguage, selected: true }
        ] : []
      }
    ];

    handleUpdateSubscriptionFilter(dto);
  };

  const handleSelectedLanguage = (language) => {
    if (language) {
      setSelectedLanguage(language);
    } else {
      setSelectedLanguage(null);
    }
  };

  const enteredEditSubscriptionFilterDialog = () => {
    setSelectedLanguage(routeMatch && filterData && filterData.language && filterData.language.find(x => x.selected) ? filterData.language.find(x => x.selected) : null);
  };

  return (
    <Dialog
      open={openEditSubscriptionFilterDialog}
      fullWidth={true}
      maxWidth={'sm'}
      onEntered={enteredEditSubscriptionFilterDialog}
      onClose={onCloseEditSubscriptionFilterDialog}>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        onClose={onCloseEditSubscriptionFilterDialog}>
        <Typography variant='h5'>{filterData ? 'Edit filter': 'Create filter'}</Typography>
      </DialogTitle>
      {filterData &&
        <DialogContent>
          {isLoading && <LoadingScreen />}
          {!isLoading && data &&
            <EditSubscriptionFilterForm
              filterData={data.data}
              disableButton={disableButton}
              selectedLanguage={selectedLanguage}
              handleSelectedLanguage={handleSelectedLanguage}
              handleSubmit={handleSubmit} />
          }
        </DialogContent>
      }
      {!filterData &&
        <DialogContent>
          <EditSubscriptionFilterForm
            filterData={{
              language: languages
            }}
            disableButton={disableButton}
            selectedLanguage={selectedLanguage}
            handleSelectedLanguage={handleSelectedLanguage}
            handleSubmit={handleSubmit} />
        </DialogContent>
      }
    </Dialog>
  );
};

EditSubscriptionFilterDialog.propTypes = {
  filterData: PropTypes.object,
  languages: PropTypes.array,
  disableButton: PropTypes.bool,
  handleDisableButtonChange: PropTypes.func,
  handleUpdateSubscriptionFilter: PropTypes.func,
  openEditSubscriptionFilterDialog: PropTypes.bool,
  onCloseEditSubscriptionFilterDialog: PropTypes.func
};