import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Trash2 as DeleteIcon } from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { AutocompleteTextField } from 'components';
import { useStyles } from './SubscriptionFiltersList.styles';

export const SubscriptionFiltersList = ({ filters, handleChange }) => {
  const classes = useStyles();
  const [ searchString, setSearchString ] = useState('');
  const [ limit, setLimit ] = useState(5);
  const [ page, setPage ] = useState(0);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (eventLimit) => {
    setLimit(parseInt(eventLimit.target.value));
  };

  return (
    <Box mt={1}>
      <Autocomplete
        getOptionSelected={(option, value) => option.abbreviation === value.abbreviation || option.id === value.id}
        getOptionLabel={(option) => option.language || option.name}
        options={filters.filter(x => !x.selected)}
        onChange={(_, newValue) => handleChange(newValue, true)}
        inputValue={searchString}
        onInputChange={(_, newInputValue) => setSearchString(newInputValue)}
        renderInput={(params) => <AutocompleteTextField params={params} isLoading={false} />} />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableBody>
              {filters.filter(x => x.selected).slice(page * limit, page * limit + limit).map((filter, index) => (
                <TableRow
                  key={index}>
                  <TableCell>{filter.language || filter.name}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleChange(filter, false)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={filters.filter(x => x.selected).length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
    </Box>
  );
};

SubscriptionFiltersList.propTypes = {
  filters: PropTypes.array,
  handleChange: PropTypes.func
};