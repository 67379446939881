import axios from 'axios';

import { config } from 'helpers';

const getPublications = (organisation_id, offset = 0, freetext = '', only_search_title = false, freetext_language = '', publishers = '', types = '', languages = '', order_by = '', created_date_start = '', created_date_end = '', publication_date_start = '', publication_date_end = '', limit = 10) => {
  let queryString = `?organisation_id=${organisation_id}&freetext=${freetext}&freetext_language=${freetext_language}&publishers=${publishers}&types=${types}&languages=${languages}&order_by=${order_by}&offset=${offset}&limit=${limit}`;
  if (only_search_title !== '') {
    queryString += `&only_search_title=${only_search_title}`;
  }

  if (created_date_start !== '') {
    queryString += `&created_date_start=${created_date_start}`;
  }

  if (created_date_end !== '') {
    queryString += `&created_date_end=${created_date_end}`;
  }

  if (publication_date_start !== '') {
    queryString += `&publication_date_start=${publication_date_start}`;
  }

  if (publication_date_end !== '') {
    queryString += `&publication_date_end=${publication_date_end}`;
  }

  return axios.get(`${config.openRegulationApiBaseUrl}/publications/search${queryString}`)
    .then((response) => {
      return response.data;
    });
};

const getPublication = (publication_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/publications/${publication_id}`)
    .then((response) => {
      return response.data;
    });
};

const getPublicationFilters = (organisation_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/publications/search/filter?organisation_id=${organisation_id}`)
    .then((response) => {
      return response.data;
    });
};

const getPublicationLanguages = (organisation_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/publications/translate/filter?organisation_id=${organisation_id}`)
    .then((response) => {
      return response.data;
    });
};

export const publicationService = {
  getPublications,
  getPublication,
  getPublicationFilters,
  getPublicationLanguages
};