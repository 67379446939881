import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Trash2 as DeleteIcon
} from 'react-feather';

import { useStyles } from './SubscriptionFiltersTable.styles';

export const SubscriptionFiltersTable = ({
  handleDeleteSubscriptionFilter,
  handleOpenEditSubscriptionFilterDialog,
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  filters,
  totalCount
}) => {
  const classes = useStyles();

  return (
    <>
      <PerfectScrollbar>
        <Box mt={1}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Keywords</TableCell>
                <TableCell>Distance</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Performance</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filters.map((filter, index) => (
                <TableRow
                  key={index}>
                  <TableCell>{filter.keywords_summary}</TableCell>
                  <TableCell>{filter.distance}</TableCell>
                  <TableCell>{filter.language && filter.language.length > 0 && filter.language.find(x => x.selected) ? filter.language.find(x => x.selected).language : ''}</TableCell>
                  <TableCell>{filter.performance ? filter.performance.performance : ''}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Edit' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenEditSubscriptionFilterDialog(filter)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleDeleteSubscriptionFilter(filter.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
    </>
  );
};

SubscriptionFiltersTable.propTypes = {
  handleDeleteSubscriptionFilter: PropTypes.func,
  handleOpenEditSubscriptionFilterDialog: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  filters: PropTypes.array,
  totalCount: PropTypes.number
};