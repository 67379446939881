import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  InviteUserDialog,
  Page,
  PendingUsersTab,
  UsersTab
} from 'components';
import { browserHistory } from 'helpers';
import { useUser } from 'hooks';

import { tabs } from './UsersPage.config';
import { useStyles } from './UsersPage.styles';

export const UsersPage = () => {
  const classes = useStyles();
  const { user } = useUser();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [ currentTab, setCurrentTab ] = useState(query && query.get('tab') ? query.get('tab') : tabs[0].value);
  const [ openInviteUserDialog, setOpenInviteUserDialog ] = useState(false);

  const handleTabsChange = (_, value) => {
    setCurrentTab(value);
  };

  const handleOpenInviteUserDialog = () => {
    setOpenInviteUserDialog(true);
  };

  const handleCloseInviteUserDialog = () => {
    setOpenInviteUserDialog(false);
  };

  useEffect(() => {
    browserHistory.push({
      pathname: '/app/users',
      search: `?tab=${currentTab}`
    });
  }, [currentTab]);

  return (
    <>
      <Page
        className={classes.root}
        appName={user.theme && user.theme.appName}
        icon={user.theme && user.theme.appIcon}
        title='Users'>
        <Container maxWidth={false}>
          <Grid
            container
            justify='space-between'
            spacing={3}>
            <Grid item>
              <Typography
                variant='h5'
                color='textPrimary'>
                List of Users
              </Typography>
              <Breadcrumbs
                aria-label='breadcrumb'
                separator={<NavigateNextIcon fontSize='small' />}
                sx={{ mt: 1 }}>
                <Link
                  color='textPrimary'
                  component={RouterLink}
                  to='/app'
                  variant='subtitle2'>
                  Home
                </Link>
                <Typography
                  color='textSecondary'
                  variant='subtitle2'>
                  Users
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleOpenInviteUserDialog}>
                  Invite Users
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons='auto'
              value={currentTab}
              variant='scrollable'
              indicatorColor='primary'
              textColor='primary'>
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Divider className={classes.dividerRoot} />
          <Box mt={3}>
            {currentTab === 'users' && <UsersTab />}
            {currentTab === 'pending-users' && <PendingUsersTab />}
          </Box>
        </Container>
        <InviteUserDialog
          openInviteUserDialog={openInviteUserDialog}
          onCloseInviteUserDialog={handleCloseInviteUserDialog} />
      </Page>
    </>
  );
};