import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Radio,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from './ProductTier.styles';

export const ProductTier = ({
  source,
  index,
  selectedProductTier,
  onSelectedProductTierChange,
  expanded,
  onAccordionChange
}) => {
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded === `panel${index}`} onChange={onAccordionChange(`panel${index}`)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}>
        <Radio
          color='primary'
          checked={selectedProductTier === source.id}
          onClick={() => onSelectedProductTierChange(source.id)} />
        <Box ml={2}>
          <Typography
            gutterBottom
            variant='h6'
            color='textPrimary'>
            {source.nickname}
          </Typography>
          <Typography
            variant='body1'
            color='textPrimary'>
            {`Starts at ${source.tiers[0].formatted}`}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}>
        <Typography
          className={classes.typography}
          color='textPrimary'>
          {source.description}
        </Typography>
        <Box mt={1}>
          {source.tiers.map((tier, innerIndex) => (
            <Box key={innerIndex}>
              <Typography
                className={classes.typography}
                color='textPrimary'>
                {tier.friendly}
              </Typography>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

ProductTier.propTypes = {
  index: PropTypes.number,
  source: PropTypes.any,
  selectedProductTier: PropTypes.any,
  onSelectedProductTierChange: PropTypes.func,
  expanded: PropTypes.any,
  onAccordionChange: PropTypes.func
};