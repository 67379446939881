/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  FormControl,
  Hidden,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { useQuery } from 'react-query';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated
} from '@azure/msal-react';

import { LoadingScreen, Logo, NavItem } from 'components';
import { browserHistory, getPartnerId, handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { localiseService, organisationService } from 'services';

import { freemiumSections, sections } from './NavBar.config';
import { useStyles } from './NavBar.styles';

const renderNavItems = ({
  items,
  pathname,
  depth = 0
}) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth
}) => {
  const key = `${item.title}-${depth}`;

  if (item.items) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title} />
    );
  }

  return acc;
};

export const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const routerLocation = useLocation();
  const { user, saveUser } = useUser();
  const isAuthenticated = useIsAuthenticated();
  const routeMatch = useRouteMatch({
    path: '/freemium/:ou_id/:s_id',
    strict: true
  });

  const query = new URLSearchParams(routerLocation.search);

  const { isLoading, data } = useQuery(['getOrganisations'], () => organisationService.getOrganisations(), {
    enabled: isAuthenticated,
    onSuccess: (response) => {
      if (user.organisation === null) {
        saveUser({
          ...user,
          organisation: response && response.data && response.data[0],
          ...(query && query.get('organisation_id') && {
            organisation: response && response.data && response.data.find(organisation => organisation.organisation_id === query.get('organisation_id'))
          })
        });
      }
    },
    onError: (err) => handleErrors(err)
  });

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerLocation.pathname]);

  const handleOrganisationChange = (organisationEvent) => {
    const organisation = data.data.find(x => x.organisation_id === organisationEvent.target.value);
    const newUserData = {
      ...user,
      organisation
    };

    if (organisation.partner_id === user.organisation.partner_id) {
      saveUser({ ...newUserData });
    } else {
      localiseService.getTheme(getPartnerId(newUserData, null)).then((themeResponse) => {
        saveUser({
          ...newUserData,
          ...themeResponse.data
        });
      });
    }

    browserHistory.replace('/');
  };

  const handleSections = () => {
    return isAuthenticated ? sections : freemiumSections(routeMatch);
  };

  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column' >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display='flex'
            justifyContent='center'>
            <RouterLink to='/'>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box className={classes.box} display='flex' alignItems='center'>
            <Box mr={1}>
              <Avatar
                src={null}
                className={classes.avatar} />
            </Box>
            <Box sx={{ ml: 2 }} className={classes.displayName}>
              <Typography
                color='textPrimary'
                variant='subtitle2'>
                {user && user.name ? user.name : 'Anonymous'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <AuthenticatedTemplate>
            {isLoading && <LoadingScreen />}
            {!isLoading && data && user.organisation !== null &&
              <FormControl variant='outlined' className={classes.organisationSelect}>
                <InputLabel>Organisation</InputLabel>
                <Select
                  value={user.organisation.organisation_id}
                  onChange={handleOrganisationChange}
                  label='Organisation'>
                  {data.data.map((organisation) => (
                    <MenuItem
                      key={organisation.organisation_id}
                      value={organisation.organisation_id}>
                      {organisation.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </AuthenticatedTemplate>
          <Box mt={2}>
            {handleSections().map((section, index) => (
              <List
                key={index}
                subheader={(
                  <ListSubheader
                    className={classes.listSubheader}
                    disableGutters
                    disableSticky>
                    {section.subheader ? section.subheader : ''}
                  </ListSubheader>
                )}>
                {renderNavItems({
                  items: section.items,
                  pathname: routerLocation.pathname
                })}
              </List>
            ))}
          </Box>
          <UnauthenticatedTemplate>
            {!routeMatch && (
              <Box mt={2}>
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemText primary='1 - Enter your email' />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary='2 - Select which feeds you would like to subscribe to (this is just a sample of what is available)' />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary='3 - Click OK' />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary='4 - Check your email for today&apos;s updates' />
                  </ListItem>
                </List>
              </Box>
            )}
          </UnauthenticatedTemplate>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'>
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'>
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};
