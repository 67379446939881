import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  AutocompleteNoOptionsText,
  AutocompleteTextField,
  CreateBillingDialog,
  LoadingScreen,
  Page,
  RequestSourcesDialog,
  SimulatePricingTable,
  SourcesTable,
  StripeElements
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import {
  organisationService,
  pricingService,
  sourceService
} from 'services';
import { useStyles } from './SourcesPage.styles';

export const SourcesPage = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { user, saveUser } = useUser();
  const [ offset, setOffset ] = useState(0);
  const [ limit, setLimit ] = useState(10);
  const [ page, setPage ] = useState(0);
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const [ searchString, setSearchString ] = useState('');
  const [ openCreateBillingDialog, setOpenCreateBillingDialog ] = useState(false);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completed, setCompleted ] = useState(false);
  const [ openRequestSourcesDialog, setOpenRequestSourcesDialog ] = useState(false);

  const { isLoading, data } = useQuery(
    [ 'getSources', { user, searchString }],
    () => sourceService.getSources(user.organisation.organisation_id, searchString), {
      enabled: user.organisation !== null && searchString.length >= 3,
      onSuccess: () => {
        if (!openDropdown) {
          setOpenDropdown(true);
        }
      },
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingOrganisationSources, data: organisationSourcesData } = useQuery(
    [ 'getOrganisationSources', { user, offset, limit }],
    () => sourceService.getOrganisationSources(user.organisation.organisation_id, offset, limit), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingSimulatePricing, data: simulatePricingData } = useQuery(
    [ 'simulatePricing', { user }],
    () => pricingService.simulatePricing(user.organisation.organisation_id), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const addMutation = useMutation(
    (addData) => sourceService.addOrganisationSource(addData.organisationId, addData.publisherId), {
      onSuccess: () => {
        invalidateQueries();
        toast.success('Source added to organisation');
      },
      onError: (err) => handleErrors(err)
    });

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === 'getOrganisationSources' || query.queryKey[0] === 'simulatePricing'
    });
  };

  const handleAutocompleteChange = (_, newValue) => {
    if (newValue && !organisationSourcesData.data.some(x => x.publisher_id === newValue.publisher_id)) {
      addMutation.mutate({
        organisationId: user.organisation.organisation_id,
        publisherId: newValue.publisher_id
      });
    }
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
    setOffset(parseInt(newPage) * limit);
  };

  const handleLimitChange = (eventLimit) => {
    setLimit(parseInt(eventLimit.target.value));
    setOffset(page * parseInt(eventLimit.target.value));
  };

  const handleCreateBillingDialogOpen = () => {
    setOpenCreateBillingDialog(true);
  };

  const handleCreateBillingDialogClose = () => {
    setOpenCreateBillingDialog(false);
    setActiveStep(0);
    if (completed) {
      handleGetOrganisation();
    }

    setCompleted(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    setCompleted(true);
  };

  const handleOpenRequestSourcesDialog = () => {
    setOpenRequestSourcesDialog(true);
  };

  const handleCloseRequestSourcesDialog = () => {
    setOpenRequestSourcesDialog(false);
  };

  const handleGetOrganisation = () => {
    organisationService.getOrganisations().then((response) => {
      saveUser({
        ...user,
        organisation: response && response.data && response.data.find(x => x.organisation_id === user.organisation.organisation_id)
      });
    });
  };

  useEffect(() => {
    setPage(0);
    setOffset(0);
    setLimit(10);
  }, [user]);

  return (
    <>
      {(isLoadingOrganisationSources || isLoadingSimulatePricing) && <LoadingScreen />}
      {!isLoadingOrganisationSources && !isLoadingSimulatePricing && organisationSourcesData && simulatePricingData &&
        <StripeElements>
          <Page
            className={classes.root}
            appName={user.theme && user.theme.appName}
            icon={user.theme && user.theme.appIcon}
            title='Sources'>
            <Container maxWidth={false}>
              <Grid
                container
                justify='space-between'
                spacing={3}>
                <Grid item>
                  <Typography
                    variant='h5'>
                    List of Sources
                  </Typography>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                    sx={{ mt: 1 }}>
                    <Link
                      color='textPrimary'
                      component={RouterLink}
                      to='/app'
                      variant='subtitle2'>
                      Home
                    </Link>
                    <Typography
                      color='textSecondary'
                      variant='subtitle2'>
                      Sources
                    </Typography>
                  </Breadcrumbs>
                  <Box mt={2}>
                    <Autocomplete
                      className={classes.autocomplete}
                      classes={{
                        popupIndicator: classes.autocompletePopupIndicator
                      }}
                      open={openDropdown}
                      onClose={() => setOpenDropdown(false)}
                      getOptionSelected={(option, value) => option.publisher_id === value.publisher_id}
                      getOptionLabel={(option) => option.name}
                      options={!isLoading && data ? data.data.items ? data.data.items : [] : []}
                      loading={isLoading}
                      noOptionsText={
                        <AutocompleteNoOptionsText
                          handleOpenRequestSourcesDialog={handleOpenRequestSourcesDialog} />
                      }
                      onChange={handleAutocompleteChange}
                      inputValue={searchString}
                      onInputChange={(_, newInputValue) => setSearchString(newInputValue)}
                      renderInput={(params) => <AutocompleteTextField params={params} isLoading={isLoading} />} />
                    <RequestSourcesDialog
                      openRequestSourcesDialog={openRequestSourcesDialog}
                      onCloseRequestSourcesDialog={handleCloseRequestSourcesDialog} />
                  </Box>
                  {!simulatePricingData.data.billing_is_present &&
                    <Box mt={2}>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={handleCreateBillingDialogOpen}>
                        Click here to provide payment details to activate subscription
                      </Button>
                      <CreateBillingDialog
                        activeStep={activeStep}
                        completed={completed}
                        handleComplete={handleComplete}
                        handleClose={handleCreateBillingDialogClose}
                        onBack={handleBack}
                        onNext={handleNext}
                        openCreateBillingDialog={openCreateBillingDialog} />
                    </Box>
                  }
                </Grid>
                <Grid item>
                  <SimulatePricingTable data={simulatePricingData.data} />
                </Grid>
              </Grid>
              <Box mt={3}>
                <SourcesTable
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  totalCount={organisationSourcesData.pagination.total}
                  sources={organisationSourcesData.data} />
              </Box>
            </Container>
          </Page>
        </StripeElements>
      }
    </>
  );
};