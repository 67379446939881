import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  field: {
    padding: theme.spacing(2),
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    borderRadius: theme.spacing(2),
    flex: 1
  },
  dialogTitle: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1)
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  cvcField: {
    marginLeft: theme.spacing(2)
  },
  errorMessage: {
    color: '#f44336',
    marginLeft: theme.spacing(1)
  },
  errorField: {
    borderColor: theme.palette.error.main,
  },
  cvcErrorMessage: {
    marginLeft: theme.spacing(3)
  },
  gridContainer: {
    marginTop: theme.spacing(1)
  }
}));