import { colors, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    backgroundColor: colors.green[600]
  },
  card: {
    marginBottom: theme.spacing(2)
  }
}));