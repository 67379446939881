import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Typography } from '@material-ui/core';

import { useStyles } from './AppliedPublicationsFilters.styles';

export const AppliedPublicationsFilters = ({ filters, handleFilters }) => {
  const classes = useStyles();

  const appliedPublicationsFilters = filters.listField.filter(x => x.value.some(y => y.selected)).map(field => ({
    appliedFilters: [...field.value.filter(x => x.selected).map(appliedFilter => ({
      label: field.fieldLabel,
      field: field.fieldName,
      value: appliedFilter.key,
      displayValue: appliedFilter.value
    }))]
  }));

  const showAppliedPublicationsFilters = appliedPublicationsFilters.length > 0;

  const handleRemoveAppliedPublicationsFilter = (fieldName, appliedFilterKey) => {
    handleFilters({
      ...filters,
      listField: [
        ...filters.listField.map(field => ({
          ...field,
          ...(field.fieldName === fieldName && {
            value: [
              ...field.value.map(value => ({
                ...value,
                ...(value.key === appliedFilterKey && {
                  selected: false
                })
              })),
            ]
          })
        }))
      ]
    });
  };

  return (
    <>
      {showAppliedPublicationsFilters
        ? (
          <Box
            display='flex'
            alignItems='center'
            flexDirection='row'
            flexWrap='wrap'
            className={classes.filters}>
            {appliedPublicationsFilters.map((x, index) => x.appliedFilters.map(chip => (
              <Chip
                key={chip.value}
                onDelete={() => handleRemoveAppliedPublicationsFilter(chip.field, chip.value)}
                className={index > 0 ? classes.chipNext : classes.chipRoot}
                label={(
                  <Box
                    display='flex'
                    alignItems='center'
                    className={classes.chipBox}>
                    <span>
                      {chip.label}
                    </span>
                      :
                    {' '}
                    {chip.displayValue || chip.value}
                  </Box>
                )}
                variant='outlined'/>
            )))}
          </Box>
        ) : (
          <Box className={classes.noFilters}>
            <Typography variant='subtitle2'>
              No filters applied
            </Typography>
          </Box>
        )}
    </>
  );
};

AppliedPublicationsFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  handleFilters: PropTypes.func.isRequired
};