import axios from 'axios';

import { config } from 'helpers';

const getDashboard = (organisation_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/dashboard/${organisation_id}`)
    .then((response) => {
      return response.data;
    });
};

const updateLandingPage = (organisation_user_id, partner_id) => {
  let queryParams = '';
  if (organisation_user_id && partner_id) {
    queryParams = `?organisation_user_id=${organisation_user_id}&partner_id=${partner_id}`;
  } else if (organisation_user_id) {
    queryParams = `?organisation_user_id=${organisation_user_id}`;
  } else if (partner_id) {
    queryParams = `?partner_id=${partner_id}`;
  }

  return axios.post(`${config.openRegulationApiBaseUrl}/dashboard/landingpage${queryParams}`);
};

export const dashboardService = {
  getDashboard,
  updateLandingPage
};