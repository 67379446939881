import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { DialogTitle, LoadingScreen } from 'components';
import { handleErrors } from 'helpers';
import { subscriptionService } from 'services';
import { useStyles } from './DeleteSubscriptionDialog.styles';

export const DeleteSubscriptionDialog = ({
  openDeleteSubscriptionDialog,
  onCloseDeleteSubscriptionDialog,
  subscriptionId
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [ disableButton, setDisableButton ] = useState(false);

  const { isLoading, data } = useQuery(
    [ 'getSubscriptionStatus', { subscriptionId }],
    () => subscriptionService.getSubscriptionStatus(subscriptionId), {
      enabled: subscriptionId !== null,
      onError: (err) => handleErrors(err)
    });

  const deleteMutation = useMutation(() => subscriptionService.deleteSubscription(subscriptionId), {
    onSuccess: () => {
      setDisableButton(false);
      queryClient.invalidateQueries('getSubscriptions');
      onCloseDeleteSubscriptionDialog();
      toast.success('Subscription deleted!');
    },
    onError: (err) => {
      setDisableButton(false);
      handleErrors(err);
    }
  });

  const handleDeleteSubscription = () => {
    setDisableButton(true);
    deleteMutation.mutate();
  };

  return (
    <Dialog
      open={openDeleteSubscriptionDialog}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onCloseDeleteSubscriptionDialog}>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
        onClose={onCloseDeleteSubscriptionDialog}>
        <Typography variant='h5'>Delete subscription from organisation</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading && <LoadingScreen />}
        {!isLoading && data &&
          <Typography>
            {data.data} Are you sure you want to delete this subscription?
          </Typography>
        }
      </DialogContent>
      {!isLoading && data &&
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={onCloseDeleteSubscriptionDialog}>
            No
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={disableButton}
            onClick={handleDeleteSubscription}>
            Yes
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
};

DeleteSubscriptionDialog.propTypes = {
  openDeleteSubscriptionDialog: PropTypes.bool,
  onCloseDeleteSubscriptionDialog: PropTypes.func,
  subscriptionId: PropTypes.string
};