import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  ArrowRight as ArrowRightIcon,
} from 'react-feather';

import { handleOpenLink, iconsEnum } from 'helpers';
import { InformationCircle } from 'resources/icons';
import { useStyles } from './TextBoxCard.styles';

export const TextBoxCard = ({ textBoxData }) => {
  const classes = useStyles();

  const handleIcon = (icon) => {
    const Icon = iconsEnum[icon];

    return (
      <Avatar className={`${classes.avatar}`}>
        <Icon color='primary' />
      </Avatar>
    );
  };

  return (
    <Card>
      <CardHeader
        disableTypography
        subheader={(
          <Typography
            color='textPrimary'
            variant='h6'>
            {textBoxData.header.subtitle}
          </Typography>
        )}
        title={(
          <Box className={classes.titleBox}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              {handleIcon(textBoxData.header.icon)}
              <Typography
                color='textPrimary'
                className={classes.titleTypography}
                variant='h6'>
                {textBoxData.header.heading}
              </Typography>
            </Box>
            <Tooltip title={textBoxData.header.help}>
              <InformationCircle fontSize='small' />
            </Tooltip>
          </Box>
        )}
        className={classes.cardHeader} />
      <CardContent className={classes.cardContent}>
        <Typography
          component={'div'}
          color='textSecondary'
          variant='body2'
          dangerouslySetInnerHTML={{ __html: textBoxData.data }} />
      </CardContent>
      {textBoxData.footer &&
        <CardActions className={classes.cardActions}>
          <Button
            className={classes.footerButton}
            color='primary'
            endIcon={<ArrowRightIcon />}
            onClick={() => handleOpenLink(textBoxData.footer.link.url)}
            variant='text'>
            {textBoxData.footer.link.text}
          </Button>
        </CardActions>
      }
    </Card>
  );
};

TextBoxCard.propTypes = {
  textBoxData: PropTypes.object
};