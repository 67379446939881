import React, {
  useRef,
  useState
} from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Hidden,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useQuery } from 'react-query';

import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';
import { useStyles } from './Account.styles';

export const Account = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [ isOpen, setOpen ] = useState(false);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { user, saveUser } = useUser();

  const { isLoading, data } = useQuery(
    [ 'getCurrentUser', { user }],
    () => userService.getCurrentUser(user.organisation.organisation_id), {
      enabled: isAuthenticated && user.organisation !== null,
      onSuccess: (userData) => {
        window.heap.identify(userData.data.user_id);
        window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
          'user_id': userData.data.user_id
        });
        saveUser({
          ...user,
          name: userData.data.display_name
        });
      },
      onError: (err) => handleErrors(err)
    });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    instance.logout();
    localStorage.removeItem('user');
    handleClose();
  };

  return (
    <>
      {isLoading && <CircularProgress className={classes.circularProgress} />}
      {!isLoading && data &&
        <>
          <Box
            display='flex'
            alignItems='center'
            component={ButtonBase}
            onClick={handleOpen}
            ref={ref}>
            <Avatar
              alt='User'
              className={classes.avatar}
              src={null} />
            <Hidden smDown>
              <Typography
                variant='h6'
                color='inherit'>
                {data.data.display_name}
              </Typography>
            </Hidden>
          </Box>
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}>
            <MenuItem onClick={handleLogout}>
              Logout
            </MenuItem>
          </Menu>
        </>
      }
      {isAuthenticated && user.organisation === null &&
        <Button
          color='primary'
          variant='contained'
          onClick={handleLogout}>
          Logout
        </Button>
      }
    </>
  );
};
