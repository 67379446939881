import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { DialogTitle, LoadingScreen } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { sourceService } from 'services';
import { useStyles } from './DeleteSourceDialog.styles';

export const DeleteSourceDialog = ({
  openDeleteSourceDialog,
  disableButton,
  selectedSourceId,
  handleDeleteOrganisationSource,
  onCloseDeleteSourceDialog
}) => {
  const classes = useStyles();
  const { user } = useUser();

  const { isLoading, data } = useQuery(
    [ 'getOrganisationSourceStatus', { user, selectedSourceId }],
    () => sourceService.getOrganisationSourceStatus(user.organisation.organisation_id, selectedSourceId), {
      enabled: user.organisation !== null && selectedSourceId !== null,
      onError: (err) => handleErrors(err)
    });

  return (
    <Dialog
      open={openDeleteSourceDialog}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onCloseDeleteSourceDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseDeleteSourceDialog}>
        <Typography variant='h5'>Delete source from organisation</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading && <LoadingScreen />}
        {!isLoading && data &&
          <Typography>
            {data.data} Are you sure you want to delete this source?
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={onCloseDeleteSourceDialog}>
          No
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={disableButton}
          onClick={handleDeleteOrganisationSource}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteSourceDialog.propTypes = {
  openDeleteSourceDialog: PropTypes.bool,
  selectedSourceId: PropTypes.string,
  disableButton: PropTypes.bool,
  handleDeleteOrganisationSource: PropTypes.func,
  onCloseDeleteSourceDialog: PropTypes.func,
};