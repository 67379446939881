import React, { useState } from 'react';
import {
  Link as RouterLink,
  useRouteMatch
} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';

import {
  Account,
  CreateFullAccountDialog,
  Logo
} from 'components';
import { useStyles } from './TopBar.styles';

export const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const routeMatch = useRouteMatch({
    path: '/freemium/:ou_id/:s_id',
    strict: true
  });
  const [ openCreateFullAccountDialog, setOpenCreateFullAccountDialog ] = useState(false);

  const handleOpenCreateFullAccountDialog = () => {
    setOpenCreateFullAccountDialog(true);
  };

  const handleCloseCreateFullAccountDialog = () => {
    setOpenCreateFullAccountDialog(false);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest} >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color='inherit'
            onClick={onMobileNavOpen} >
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to='/'>
            <Logo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <AuthenticatedTemplate>
            <Account />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              className={classes.button}
              variant='contained'
              onClick={handleOpenCreateFullAccountDialog}>
              {routeMatch ? 'Upgrade Your Account' : 'Upgrade'}
            </Button>
            <CreateFullAccountDialog
              openCreateFullAccountDialog={openCreateFullAccountDialog}
              onCloseCreateFullAccountDialog={handleCloseCreateFullAccountDialog} />
          </UnauthenticatedTemplate>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  /* eslint-disable-next-line */
  onMobileNavOpen: () => {}
};
