import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { DialogTitle } from 'components';
import { handleErrors } from 'helpers';
import { subscriptionService } from 'services';
import { useStyles } from './EditNameDialog.styles';

export const EditNameDialog = ({
  subscriptionId,
  defaultName,
  active,
  openEditNameDialog,
  onCloseEditNameDialog
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      name: defaultName ? defaultName : '',
      active: active
    },
    onSubmit: (values) => {
      setDisableButton(true);

      const dto = {
        subscriptionId: subscriptionId,
        data: {
          name: values.name,
          active: values.active
        }
      };

      editMutation.mutate(dto);
    }
  });

  const [ disableButton, setDisableButton ] = useState(false);

  const editMutation = useMutation(
    (editData) => subscriptionService.updateSubscription(editData.subscriptionId, editData.data), {
      onSuccess: () => {
        setDisableButton(false);
        queryClient.invalidateQueries('getSubscription');
        onCloseEditNameDialog();
        toast.success('Subscription updated.');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  return (
    <Dialog
      open={openEditNameDialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onCloseEditNameDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseEditNameDialog}>
        <Typography variant='h5'>Update name</Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id='name'
            name='name'
            label='Name'
            variant='outlined'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name} />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.active}
                onChange={formik.handleChange}
                color='primary' />
            }
            id='active'
            name='active'
            label='Active' />
          <Box mt={2} display='flex' justifyContent='center'>
            <Button
              color='primary'
              className={classes.button}
              variant='contained'
              onClick={onCloseEditNameDialog}>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              disabled={disableButton}
              variant='contained'>
              Update
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

EditNameDialog.propTypes = {
  subscriptionId: PropTypes.string,
  defaultName: PropTypes.string,
  openEditNameDialog: PropTypes.bool,
  onCloseEditNameDialog: PropTypes.func,
  active: PropTypes.bool
};