import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  accordionDetails: {
    flexDirection: 'column'
  },
  typography: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));