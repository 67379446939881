import axios from 'axios';

import { config } from 'helpers';

const getCultures = () => {
  return axios.get(`${config.openRegulationApiBaseUrl}/localise/cultures`)
    .then((response) => {
      return response.data;
    });
};

const getHelpText = (culture, key, partner_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/localise/${culture}/${key}/${partner_id}`)
    .then((response) => {
      return response.data;
    });
};

const getLink = (key, partner_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/localise/link/${key}/${partner_id}`)
    .then((response) => {
      return response.data;
    });
};

const getTheme = (partner_id) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/localise/css/${partner_id}`)
    .then((response) => {
      return response.data;
    });
};

export const localiseService = {
  getCultures,
  getHelpText,
  getLink,
  getTheme
};