import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';

import { useStyles } from './CustomStepIcon.styles';

export const CustomStepIcon = ({ active, completed, icon }) => {
  const classes = useStyles();
  const Icon = icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })} >
      <Icon size='20' />
    </Avatar>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.object.isRequired
};