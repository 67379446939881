import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Formik, Form, FieldArray } from 'formik';
import {
  Plus as PlusIcon,
  Trash2 as DeleteIcon
} from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  AutocompleteTextField,
  InformationHeader
} from 'components';
import { useStyles } from './EditSubscriptionFilterForm.styles';

export const EditSubscriptionFilterForm = ({
  filterData,
  disableButton,
  selectedLanguage,
  handleSelectedLanguage,
  handleSubmit
}) => {
  const classes = useStyles();
  const [ searchString, setSearchString ] = useState('');

  return (
    <Formik
      initialValues={{
        languages: filterData && filterData.language ? [...filterData.language] : [],
        keywords: filterData && filterData.keywords ? [...filterData.keywords] : [''],
        distance: filterData && filterData.distance ? filterData.distance : 0
      }}
      onSubmit={handleSubmit}>
      {({ handleChange, values }) => (
        <Form autoComplete='off'>
          <Box mb={3}>
            <InformationHeader headerTitle='Languages' tooltipTitleKey='newsletter_filter_edit_language' />
            <Box mt={1}>
              <Autocomplete
                getOptionSelected={(option, value) => option.abbreviation === value.abbreviation}
                getOptionLabel={(option) => option.language}
                options={values.languages}
                value={selectedLanguage}
                onChange={(_, newValue) => handleSelectedLanguage(newValue)}
                inputValue={searchString}
                onInputChange={(_, newInputValue) => setSearchString(newInputValue)}
                renderInput={(params) => <AutocompleteTextField params={params} isLoading={false} />} />
            </Box>
          </Box>
          <InformationHeader headerTitle='Keywords' tooltipTitleKey='newsletter_filter_edit_keywords' />
          <FieldArray name='keywords'>
            {({ remove, push }) => (
              <>
                {values.keywords.map((_, index) => (
                  <Box mt={1} key={index} className={classes.keywordRow}>
                    <TextField
                      id={`keywords.${index}`}
                      name={`keywords.${index}`}
                      placeholder='Enter keyword'
                      onChange={handleChange}
                      value={values.keywords[index]}
                      variant='outlined' />
                    {values.keywords.length > 1 &&
                      <Tooltip title='Delete' placement='bottom'>
                        <IconButton
                          variant='contained'
                          color='primary'
                          onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    {values.keywords.length === index + 1 &&
                      <Tooltip title='Add' placement='bottom'>
                        <IconButton
                          variant='contained'
                          color='primary'
                          onClick={() => push('')}>
                          <PlusIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Box>
                ))}
              </>
            )}
          </FieldArray>
          {values.keywords.length > 1 &&
            <Box mt={3}>
              <InformationHeader headerTitle='Distance' tooltipTitleKey='newsletter_filter_edit_distance' />
              <Box mt={1}>
                <TextField
                  id='distance'
                  name='distance'
                  variant='outlined'
                  onChange={handleChange}
                  value={values.distance}
                  placeholder='Enter distance' />
              </Box>
            </Box>
          }
          <Box mt={3} display='flex' justifyContent='flex-end'>
            <Button
              color='primary'
              type='submit'
              disabled={disableButton}
              variant='contained'>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

EditSubscriptionFilterForm.propTypes = {
  filterData: PropTypes.object,
  disableButton: PropTypes.bool,
  selectedLanguage: PropTypes.object,
  handleSelectedLanguage: PropTypes.func,
  handleSubmit: PropTypes.func
};