import React from 'react';
import {
  Container,
  Grid
} from '@material-ui/core';
import { useQuery } from 'react-query';

import {
  KPICard,
  LineChartCard,
  LoadingScreen,
  Page,
  TableCard,
  TextBoxCard
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { dashboardService } from 'services';
import { useStyles } from './HomePage.styles';

export const HomePage = () => {
  const classes = useStyles();
  const { user } = useUser();

  const { isLoading, data } = useQuery(
    [ 'getDashboard', { user }],
    () => dashboardService.getDashboard(user.organisation.organisation_id), {
      enabled: user.organisation !== null,
      onError: (err) => handleErrors(err)
    });

  const handleRowItem = (rowItem) => {
    if (rowItem.type) {
      switch (rowItem.type) {
        case 'kpi':
          return <KPICard kpiData={rowItem} />;
        case 'textbox':
          return <TextBoxCard textBoxData={rowItem} />;
        case 'table':
          return <TableCard tableData={rowItem} />;
        case 'linechart':
          return <LineChartCard lineChartData={rowItem} />;
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && data &&
        <Page
          className={classes.root}
          appName={user.theme && user.theme.appName}
          icon={user.theme && user.theme.appIcon}
          title='Home' >
          <Container maxWidth={false}>
            <Grid
              container
              direction='column'
              spacing={2}>
              {data.data.rows.map((row, i) => (
                <Grid key={i} item container spacing={2}>
                  {row.map((rowItem, j) => (
                    <Grid
                      key={j}
                      item
                      lg={rowItem.layout.lg}
                      md={rowItem.layout.md}
                      sm={rowItem.layout.sm}
                      xs={rowItem.layout.xs}>
                      {handleRowItem(rowItem)}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Container>
        </Page>
      }
    </>
  );
};