import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((/*theme*/) => ({
  root: {},
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  box: {
    borderRadius: '50%',
    height: 8,
    width: 8
  }
}));