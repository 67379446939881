import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  loadingCard: {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  }
}));