import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useQuery } from 'react-query';

import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { InformationCircle } from 'resources/icons';
import { localiseService } from 'services';

export const InformationHeader = ({ headerTitle, tooltipTitleKey }) => {
  const { user } = useUser();

  const { isLoading: isLoadingHelpText, data: helpTextData } = useQuery(
    [ 'getHelpText', { user, tooltipTitleKey }],
    () => localiseService.getHelpText('en-GB', tooltipTitleKey, user.organisation === null ? '' : user.organisation.partner_id), {
      onError: (err) => handleErrors(err)
    });

  return (
    <Box display='flex' alignItems='center'>
      <Typography
        variant='h6'
        color='textPrimary'>
        {headerTitle}
      </Typography>
      {!isLoadingHelpText && helpTextData &&
        <Box display='flex' ml={0.5}>
          <Tooltip title={helpTextData}>
            <InformationCircle fontSize='small' />
          </Tooltip>
        </Box>
      }
    </Box>
  );
};

InformationHeader.propTypes = {
  headerTitle: PropTypes.string,
  tooltipTitleKey: PropTypes.string.isRequired
};