import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';

export const CustomChart = ({ visibleSeries, dataPoints }) => {
  const theme = useTheme();

  const chart = {
    options: {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: false
      },
      colors: visibleSeries.map((item) => item.color),
      dataLabels: {
        enabled: false
      },
      grid: {
        borderColor: theme.palette.divider,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        show: false
      },
      markers: {
        hover: {
          size: undefined,
          sizeOffset: 2
        },
        radius: 2,
        shape: 'circle',
        size: 4,
        strokeWidth: 0
      },
      stroke: {
        curve: 'smooth',
        lineCap: 'butt',
        width: 3
      },
      theme: {
        mode: theme.palette.mode
      },
      tooltip: {
        theme: theme.palette.mode
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        categories: dataPoints,
        labels: {
          style: {
            colors: theme.palette.text.secondary
          }
        }
      },
      yaxis: [
        {
          axisBorder: {
            color: theme.palette.divider,
            show: true
          },
          axisTicks: {
            color: theme.palette.divider,
            show: true
          },
          labels: {
            style: {
              colors: theme.palette.text.secondary
            }
          }
        }
      ]
    },
    series: visibleSeries
  };

  return (
    <Chart
      type='line'
      {...chart} />
  );
};

CustomChart.propTypes = {
  visibleSeries: PropTypes.array,
  dataPoints: PropTypes.array
};