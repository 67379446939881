import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Trash2 as DeleteIcon
} from 'react-feather';

import { DeleteSubscriptionDialog, Label } from 'components';
import { subscriptionStatusEnum } from 'helpers';
import { useStyles } from './SubscriptionsTable.styles';

const getStatusLabel = (subscriptionStatus) => {
  const { text, color } = subscriptionStatusEnum[subscriptionStatus];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

export const SubscriptionsTable = ({
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  subscriptions,
  totalCount
}) => {
  const classes = useStyles();
  const [ openDeleteSubscriptionDialog, setOpenDeleteSubscriptionDialog ] = useState(false);
  const [ selectedSubscriptionId, setSelectedSubscriptionId ] = useState(null);

  const handleOpenDeleteSubscriptionDialog = (subscriptionId) => {
    setSelectedSubscriptionId(subscriptionId);
    setOpenDeleteSubscriptionDialog(true);
  };

  const handleCloseDeleteSubscriptionDialog = () => {
    setSelectedSubscriptionId(null);
    setOpenDeleteSubscriptionDialog(false);
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Filter</TableCell>
                <TableCell>Active</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions && subscriptions.map((subscription, index) => (
                <TableRow key={index}>
                  <TableCell>{subscription.name}</TableCell>
                  <TableCell>{getStatusLabel(subscription.active ? 'ACTIVE' : 'INACTIVE')}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Edit' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        component={RouterLink}
                        to={`/app/subscriptions/${subscription.subscription_id}/edit`}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenDeleteSubscriptionDialog(subscription.subscription_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
      {selectedSubscriptionId &&
        <DeleteSubscriptionDialog
          openDeleteSubscriptionDialog={openDeleteSubscriptionDialog}
          onCloseDeleteSubscriptionDialog={handleCloseDeleteSubscriptionDialog}
          subscriptionId={selectedSubscriptionId} />
      }
    </Card>
  );
};

SubscriptionsTable.propTypes = {
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  subscriptions: PropTypes.array,
  totalCount: PropTypes.number
};