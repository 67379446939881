import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

import { useStyles } from './SubscriptionsSettingsSummaryTab.styles';

export const SubscriptionsSettingsSummaryTab = ({ summary }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.summary}
          color='textSecondary'
          variant='subtitle2'>
          {summary}
        </Typography>
      </CardContent>
    </Card>
  );
};

SubscriptionsSettingsSummaryTab.propTypes = {
  summary: PropTypes.string
};