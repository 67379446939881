import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  LoadingScreen,
  Page,
  PublicationAboutSection,
  PublicationOverviewTab,
  PublicationTranslationTab
} from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { publicationService } from 'services';

import { tabs } from './PublicationDetailsPage.config';
import { useStyles } from './PublicationDetailsPage.styles';

export const PublicationDetailsPage = () => {
  const classes = useStyles();
  const { user } = useUser();
  const routeMatch = useRouteMatch({
    path: '/app/publications/:id',
    strict: true
  });
  const [ currentTab, setCurrentTab ] = useState(tabs[0].value);
  const [ filters, setFilters ] = useState(null);

  const { isLoading, data } = useQuery(
    [ 'getPublication', { routeMatch, user }],
    () => publicationService.getPublication(routeMatch.params.id), {
      enabled: user.organisation !== null && routeMatch !== null,
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingLanguages, data: languagesData } = useQuery(
    [ 'getPublicationLanguages', { user }],
    () => publicationService.getPublicationLanguages(user.organisation.organisation_id), {
      enabled: user.organisation !== null,
      onSuccess: (response) => setFilters(response.data.criteria),
      onError: (err) => handleErrors(err)
    });

  const handleTabsChange = (_, value) => {
    setCurrentTab(value);
  };

  const handleFilters = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <>
      {(isLoading || isLoadingLanguages) && <LoadingScreen />}
      {!isLoading && !isLoadingLanguages && data && languagesData &&
        <Page
          className={classes.root}
          appName={user.theme && user.theme.appName}
          icon={user.theme && user.theme.appIcon}
          title='Publication Details'>
          <Container maxWidth={false}>
            <Grid
              container
              justify='space-between'
              spacing={3}>
              <Grid item>
                <Typography
                  variant='h5'>
                  Publication Details
                </Typography>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                  sx={{ mt: 1 }}>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to='/app'
                    variant='subtitle2'>
                    Home
                  </Link>
                  <Link
                    color='textPrimary'
                    component={RouterLink}
                    to='/app/search'
                    variant='subtitle2'>
                    Search
                  </Link>
                  <Typography
                    color='textSecondary'
                    variant='subtitle2'>
                    {data.data.title}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}>
              <Grid
                item
                xs={12}
                lg={8}>
                <Card className={classes.card}>
                  <CardHeader
                    disableTypography
                    title={(
                      <Box
                        display='flex'
                        alignItems='center'
                        flexDirection='row'
                        spacing={2}>
                        <Avatar
                          className={classes.avatar}
                          src={data.data.publisher.logo}
                          variant='rounded' />
                        <Typography variant='h6' className={classes.title}>
                          {data.data.publisher.name}
                        </Typography>
                      </Box>
                    )}/>
                  <Divider />
                  <Tabs
                    className={classes.tabs}
                    onChange={handleTabsChange}
                    scrollButtons='auto'
                    value={currentTab}
                    variant='scrollable'
                    indicatorColor='primary'
                    textColor='primary'>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value} />
                    ))}
                  </Tabs>
                  <Divider className={classes.dividerRoot} />
                  <CardContent>
                    {currentTab === 'overview' && <PublicationOverviewTab publication={data.data} />}
                    {currentTab === 'translation' && (
                      <PublicationTranslationTab
                        publication={data.data}
                        filters={filters}
                        handleFilters={handleFilters} />
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}>
                <PublicationAboutSection publication={data.data} />
              </Grid>
            </Grid>
          </Container>
        </Page>
      }
    </>
  );
};