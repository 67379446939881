import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { LoadingScreen, Page } from 'components';
import { browserHistory, handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { InformationCircle } from 'resources/icons';
import { freemiumService, localiseService } from 'services';

import { useStyles } from './FreemiumPage.styles';

export const FreemiumPage = () => {
  const classes = useStyles();
  const routeMatch = useRouteMatch({
    path: '/freemium/:ou_id/:s_id',
    strict: true
  });
  const { user, saveUser } = useUser();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [ selectedFreemiumSources, setSelectedFreemiumSources ] = useState([]);
  const [ selectedFreemiumFilters, setSelectedFreemiumFilters ] = useState([]);
  const [ freemiumEmail, setFreemiumEmail ] = useState(query && query.get('e') ? query.get('e') : '');
  const [ enableValidation, setEnableValidation ] = useState(false);
  const [ disableButton, setDisableButton ] = useState(false);

  const { isLoading, data, error: freemiumSourcesError } = useQuery(
    [ 'getFreemiumData', { routeMatch }],
    () => freemiumService.getFreemiumData(routeMatch === null ? null : routeMatch.params.s_id, routeMatch === null ? null : routeMatch.params.ou_id), {
      retry: false,
      onSuccess: (freemiumSourcesData) => {
        saveUser({
          ...user,
          name: freemiumSourcesData.data.display_name
        });

        if (freemiumSourcesData && freemiumSourcesData.data && freemiumSourcesData.data.email) {
          setFreemiumEmail(freemiumSourcesData.data.email);
        }

        setSelectedFreemiumSources((oldSelectedSources) => [
          ...oldSelectedSources,
          ...freemiumSourcesData.data.sources.map(source => ({
            ...source,
            ...(query && query.get('s') && query.get('s').split(',').includes(source.publisher_id) && {
              selected: true
            })
          }))
        ]);

        setSelectedFreemiumFilters((oldSelectedFilters) => [
          ...oldSelectedFilters,
          ...freemiumSourcesData.data.filter_subscription.map(filter => ({
            ...filter,
            ...(query && query.get('f') && !query.get('f').split(',').includes(filter.filter_subscription_id) && {
              selected: false
            }),
            ...(query && query.get('f') && query.get('f').split(',').includes(filter.filter_subscription_id) && {
              selected: true
            })
          }))
        ]);
      }
    });

  const { error: validateEmailError } = useQuery(
    [ 'validateFreemiumEmail', { freemiumEmail }],
    () => freemiumService.validateFreemiumEmail(freemiumEmail), {
      enabled: enableValidation,
      retry: false
    });

  const { isLoading: isLoadingLink, data: linkData } = useQuery(
    ['getLink'],
    () => localiseService.getLink('terms_and_conditions', query && query.get('pid') ? query.get('pid') : ''), {
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingHelpText, data: helpTextData } = useQuery(
    ['getHelpText'],
    () => localiseService.getHelpText('en-GB', 'accept_terms_and_conditions', query && query.get('pid') ? query.get('pid') : ''), {
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingFilterHelpText, data: filterHelpTextData } = useQuery(
    ['getFilterHelpText'],
    () => localiseService.getHelpText('en-GB', 'freemium_filter_subscription', query && query.get('pid') ? query.get('pid') : ''), {
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingSourcesHelpText, data: sourcesHelpTextData } = useQuery(
    ['getSourcesHelpText'],
    () => localiseService.getHelpText('en-GB', 'freemium_filter_sources', query && query.get('pid') ? query.get('pid') : ''), {
      onError: (err) => handleErrors(err)
    });

  const editMutation = useMutation((editData) => freemiumService.editFreemiumSubscription(editData), {
    onSuccess: async (freemiumResponse) => {
      setDisableButton(false);
      toast.success(routeMatch ? await handleHelpText('freemium_subscription_updated') : await handleHelpText('freemium_subscription_created'));

      const freemiumData = freemiumResponse.data.data;
      if (routeMatch === null) {
        if (query && query.get('pid')) {
          browserHistory.replace(`/freemium/${freemiumData.organisation_user_id}/${freemiumData.subscription_id}?pid=${query.get('pid')}`);
        } else {
          browserHistory.replace(`/freemium/${freemiumData.organisation_user_id}/${freemiumData.subscription_id}`);
        }
      }
    },
    onError: (err) => {
      setDisableButton(false);
      handleErrors(err);
    }
  });

  const handleFreemiumUser = () => {
    setDisableButton(true);

    let parameters = null;
    if (query) {
      query.forEach((value, key) => {
        parameters = {
          ...parameters,
          [key]: value
        };
      });
    }

    const dto = {
      email: freemiumEmail,
      ...(routeMatch && {
        organisation_user_id: routeMatch.params.ou_id,
        subscription_id: routeMatch.params.s_id
      }),
      ...(query && query.get('pid') && {
        partner_id: query.get('pid')
      }),
      selection: selectedFreemiumSources.map((freemiumSource) => ({
        publisher_id: freemiumSource.publisher_id,
        selected: freemiumSource.selected
      })),
      filter_subscription: [...selectedFreemiumFilters],
      ...(query && parameters && {
        parameters
      })
    };

    editMutation.mutate(dto);
  };

  const handleSelectedFreemiumSources = (publisherId) => {
    setSelectedFreemiumSources((oldSelectedSources) => [...oldSelectedSources.map((oldSelectedSource) => {
      if (oldSelectedSource.publisher_id === publisherId) {
        return {
          ...oldSelectedSource,
          selected: !oldSelectedSource.selected
        };
      } else {
        return oldSelectedSource;
      }
    })]);
  };

  const handleHelpText = async (tooltipTitleKey) => {
    return await localiseService.getHelpText('en-GB', tooltipTitleKey, '');
  };

  const handleFreemiumEmailChange = (textFieldEvent) => {
    setFreemiumEmail(textFieldEvent.target.value);
  };

  const handleSelectedFreemiumFiltersChange = (_, value) => {
    setSelectedFreemiumFilters((oldSelectedFilters) => [...oldSelectedFilters.map((oldSelectedFilter) => ({
      ...oldSelectedFilter,
      selected: false,
      ...(oldSelectedFilter.filter_subscription_id === value && {
        selected: true
      })
    }))]);
  };

  return (
    <>
      {isLoading && isLoadingFilterHelpText && isLoadingSourcesHelpText && <LoadingScreen />}
      {!isLoading && data && !isLoadingFilterHelpText && filterHelpTextData && !isLoadingSourcesHelpText && sourcesHelpTextData &&
        <Page
          className={classes.root}
          appName={user.theme && user.theme.appName}
          icon={user.theme && user.theme.appIcon}
          title='Freemium'>
          <Container maxWidth={false}>
            <Grid container>
              <Grid item>
                <Typography
                  variant='h4'
                  color='textPrimary'>
                  My regulatory newsletter
                </Typography>
              </Grid>
            </Grid>
            <Box mt={3}>
              {!routeMatch &&
                <TextField
                  label='Email'
                  className={classes.textFieldRoot}
                  value={freemiumEmail}
                  error={validateEmailError !== null}
                  helperText={validateEmailError ? validateEmailError.data.errors[0].errors[0].friendly : null}
                  onChange={handleFreemiumEmailChange}
                  onBlur={() => {
                    setEnableValidation(true);
                  }}
                  color='primary'
                  size='medium'
                  variant='outlined' />
              }
              <Box mt={3}>
                <Typography
                  variant='h6'
                  color='textPrimary'>
                  {filterHelpTextData}
                </Typography>
                <Box paddingLeft='12px'>
                  <FormControl component='fieldset'>
                    <RadioGroup defaultValue='end' onChange={handleSelectedFreemiumFiltersChange}>
                      {selectedFreemiumFilters.map((selectedFreemiumFilter) => (
                        <FormControlLabel
                          key={selectedFreemiumFilter.filter_subscription_id}
                          value={selectedFreemiumFilter.filter_subscription_id}
                          checked={selectedFreemiumFilter.selected}
                          control={<Radio color='primary' />}
                          label={
                            <Box display='flex' alignItems='center'>
                              <Typography
                                variant='body1'
                                color='textPrimary'>
                                {selectedFreemiumFilter.name}
                              </Typography>
                              <Box display='flex' ml={0.5}>
                                <Tooltip title={selectedFreemiumFilter.help}>
                                  <InformationCircle fontSize='small' />
                                </Tooltip>
                              </Box>
                            </Box>
                          }
                          labelPlacement='end' />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box mt={3}>
                <Typography
                  variant='h6'
                  color='textPrimary'>
                  {sourcesHelpTextData}
                </Typography>
                <List className={classes.list}>
                  {selectedFreemiumSources.map((selectedFreemiumSource, index) => (
                    <ListItem key={index} disableGutters>
                      <ListItemIcon>
                        <Checkbox
                          color='primary'
                          edge='end'
                          onChange={() => handleSelectedFreemiumSources(selectedFreemiumSource.publisher_id)}
                          checked={selectedFreemiumSource.selected} />
                      </ListItemIcon>
                      <ListItemAvatar>
                        <img
                          className={classes.logo}
                          src={selectedFreemiumSource.logo} />
                      </ListItemAvatar>
                      <ListItemText primary={selectedFreemiumSource.name} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box mt={3}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleFreemiumUser}
                  disabled={disableButton}
                  data-analytics={routeMatch ? 'freemium_update' : 'freemium_create'}>
                  {routeMatch ? 'Update your newsletter' : 'Create your newsletter'}
                </Button>
              </Box>
              {!isLoadingLink && !isLoadingHelpText && linkData && helpTextData && (
                <Box mt={3}>
                  <Typography color='textPrimary' dangerouslySetInnerHTML={{ __html: `${helpTextData} ${linkData}` }} />
                </Box>
              )}
            </Box>
          </Container>
        </Page>
      }
      {freemiumSourcesError &&
        <Page
          className={classes.errorRoot}
          title='Freemium'>
          <Container className={classes.container} maxWidth={true}>
            <Typography
              variant='h5'
              color='textPrimary'
              dangerouslySetInnerHTML={{ __html: handleErrors(freemiumSourcesError) }} />
          </Container>
        </Page>
      }
    </>
  );
};