import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 8,
    marginTop: theme.spacing(4)
  }
}));