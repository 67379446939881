import React from 'react';

import { useUser } from 'hooks';

export const Logo = (props) => {
  const { user } = useUser();

  return user.logo ? (
    <img
      height='54px'
      width='54px'
      alt='Logo'
      src={user.logo}
      {...props} />
  ) : null;
};