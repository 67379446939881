import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import { DialogTitle } from 'components';
import { handleErrors } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';
import { useStyles } from './InviteUserDialog.styles';

export const InviteUserDialog = ({
  openInviteUserDialog,
  onCloseInviteUserDialog
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      emails: ''
    },
    onSubmit: (values) => {
      setDisableButton(true);

      const dto = {
        organisationId: user.organisation.organisation_id,
        data: values.emails.split('\n').map((email) => ({
          email: email
        }))
      };

      inviteMutation.mutate(dto);
    }
  });

  const [ disableButton, setDisableButton ] = useState(false);

  const inviteMutation = useMutation(
    (inviteData) => userService.inviteUser(inviteData.organisationId, inviteData.data), {
      onSuccess: () => {
        setDisableButton(false);
        formik.resetForm();
        invalidateQueries();
        onCloseInviteUserDialog();
        toast.success('Users invited successfully');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      predicate: query => query.queryKey[0] === 'getUsers' || query.queryKey[0] === 'getPendingUsers'
    });
    //queryClient.invalidateQueries('getUsers');
  };

  return (
    <Dialog
      open={openInviteUserDialog}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={onCloseInviteUserDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle} onClose={onCloseInviteUserDialog}>
        <Typography variant='h5'>Invite users</Typography>
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id='emails'
            name='emails'
            placeholder='Enter one email per line'
            variant='outlined'
            multiline
            rows={3}
            value={formik.values.emails}
            onChange={formik.handleChange}
            error={formik.touched.emails && Boolean(formik.errors.emails)}
            helperText={formik.touched.emails && formik.errors.emails} />
          <Box mt={2} display='flex' justifyContent='center'>
            <Button
              color='primary'
              className={classes.button}
              variant='contained'
              onClick={onCloseInviteUserDialog}>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              disabled={disableButton}
              variant='contained'>
              Invite
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

InviteUserDialog.propTypes = {
  openInviteUserDialog: PropTypes.bool,
  onCloseInviteUserDialog: PropTypes.func
};