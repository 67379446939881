import React, { useState } from 'react';
import {
  Box,
  Drawer,
  Fab,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { LoadingScreen } from 'components';
import { handleErrors, helpTextEnum } from 'helpers';
import { useUser } from 'hooks';
import { localiseService } from 'services';
import { useStyles } from './HelpDrawer.styles';

export const HelpDrawer = () => {
  const classes = useStyles();
  const { user } = useUser();
  const [ openHelpDrawer, setOpenHelpDrawer ] = useState(false);
  const routeLocation = useLocation();
  const match = matchPath(routeLocation.pathname, {
    path: Object.keys(helpTextEnum),
    exact: true
  });

  const { isLoading, data } = useQuery(
    [ 'getLink', { user }],
    () => localiseService.getLink('helpdesk_new_request', user.organisation === null ? '' : user.organisation.partner_id), {
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingHelpText, data: helpTextData } = useQuery(
    [ 'getHelpText', { user, match }],
    () => localiseService.getHelpText('en-GB', helpTextEnum[match.path], user.organisation === null ? '' : user.organisation.partner_id), {
      onError: (err) => handleErrors(err)
    });

  const handleOpenHelpDrawer = () => {
    setOpenHelpDrawer(true);
  };

  const handleCloseHelpDrawer = () => {
    setOpenHelpDrawer(false);
  };

  return (
    <>
      <Tooltip title='Help'>
        <Fab
          color='primary'
          size='small'
          onClick={handleOpenHelpDrawer}
          className={classes.fab}>
          <HelpOutline />
        </Fab>
      </Tooltip>
      <Drawer
        anchor='right'
        onClose={handleCloseHelpDrawer}
        open={openHelpDrawer}>
        <Box className={classes.drawer}>
          {isLoading && <LoadingScreen />}
          {!isLoading && data &&
            <Grid
              className={classes.container}
              container
              direction='column'
              justify='space-between'>
              <Grid item>
                <Typography
                  color='textPrimary'
                  variant='h5' >
                  Help
                </Typography>
                {isLoadingHelpText && <LoadingScreen />}
                {!isLoadingHelpText && helpTextData &&
                  <Box mt={2}>
                    <Typography color='textPrimary' dangerouslySetInnerHTML={{ __html: helpTextData }} />
                  </Box>
                }
              </Grid>
              <Grid item>
                <Box mt={3}>
                  <Typography
                    color='textPrimary'
                    variant='h6'
                    dangerouslySetInnerHTML={{ __html: data }} />
                </Box>
              </Grid>
            </Grid>
          }
        </Box>
      </Drawer>
    </>
  );
};