import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';

import { LoadingScreen, ProductTier, ProductTierPreview } from 'components';

export const ChooseProductTier = ({
  isLoadingCurrencies,
  isLoadingProductsPricing,
  currencies,
  productsPricing,
  selectedCurrency,
  selectedProductTier,
  onSelectedCurrencyChange,
  onSelectedProductTierChange,
  onBack,
  onNext,
  upgrade
}) => {
  const [ expanded, setExpanded ] = useState(false);

  const handleAccordionChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {(isLoadingCurrencies || isLoadingProductsPricing) && <LoadingScreen />}
      {!isLoadingCurrencies && !isLoadingProductsPricing && currencies && productsPricing &&
        <Box>
          <Typography
            variant='h6'
            color='textPrimary'>
            Choose Product Tier
          </Typography>
          <Box mt={1}>
            <Typography
              variant='subtitle1'
              color='textSecondary'>
              {upgrade
                ?
                'In this step you need to choose product tier that you would like to subscribe to.'
                :
                'In this step you need to choose currency and product tier that you would like to subscribe to.'
              }
            </Typography>
          </Box>
          {!upgrade &&
            <Box mt={2}>
              <Typography
                variant='h6'
                color='textPrimary'>
                Choose Currency
              </Typography>
              <FormControl component='fieldset'>
                <RadioGroup row defaultValue='end' value={selectedCurrency} onChange={onSelectedCurrencyChange}>
                  {currencies.data.map((currency, index) => (
                    <FormControlLabel
                      key={index}
                      value={currency}
                      control={<Radio color='primary' />}
                      label={currency.toUpperCase()}
                      labelPlacement='end' />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          }
          <Grid container justify='space-between' direction='row' spacing={2}>
            <Grid item sm={6}>
              <Box mt={2}>
                {productsPricing.data.products.sources.map((source, index) => (
                  <ProductTier
                    key={index}
                    source={source}
                    index={index}
                    selectedProductTier={selectedProductTier}
                    onSelectedProductTierChange = {onSelectedProductTierChange}
                    expanded={expanded}
                    onAccordionChange={handleAccordionChange} />
                ))}
              </Box>
              <Box mt={2}>
                {productsPricing.data.products.sourcesFreemium.map((freemiumSource, index) => (
                  <ProductTierPreview
                    key={index}
                    previewSourceName={'Freemium sources'}
                    previewSource={freemiumSource} />
                ))}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box mt={2}>
                {productsPricing.data.products.users.map((userSource, index) => (
                  <ProductTierPreview
                    key={index}
                    previewSource={userSource} />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Box mt={6} display='flex'>
            <Button
              onClick={onBack}
              size='large'>
              Previous
            </Button>
            <Box flexGrow={1} />
            <Button
              color='primary'
              type='submit'
              variant='contained'
              size='large'
              onClick={onNext}>
              Next
            </Button>
          </Box>
        </Box>
      }
    </>
  );
};

ChooseProductTier.propTypes = {
  isLoadingCurrencies: PropTypes.bool,
  isLoadingProductsPricing: PropTypes.bool,
  currencies: PropTypes.any,
  productsPricing: PropTypes.any,
  selectedCurrency: PropTypes.string,
  selectedProductTier: PropTypes.any,
  onSelectedCurrencyChange: PropTypes.func,
  onSelectedProductTierChange: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  upgrade: PropTypes.bool
};