import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[10],
    color: theme.palette.primary.contrastText
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));