import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  LoadingScreen,
  Page,
  PublicationCard,
  PublicationsBanner,
  PublicationsFilters
} from 'components';
import {
  browserHistory,
  getFiltersObject,
  getQueryString,
  handleErrors,
  setDefaultFilters
} from 'helpers';
import { useDebounce, useUser } from 'hooks';
import { publicationService } from 'services';

import { useStyles } from './PublicationsPage.styles';

export const PublicationsPage = () => {
  const classes = useStyles();
  const { user } = useUser();
  const [ offset, setOffset ] = useState(0);
  const [ filters, setFilters ] = useState(null);
  const [ freeText, setFreeText ] = useState('');
  const debouncedFreeText = useDebounce(freeText, 300);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const { isLoading: isLoadingPublications, data: publicationsData } = useQuery(
    [ 'getPublications', { user, offset, filters }],
    () => publicationService.getPublications(
      query && query.get('organisation_id') ? query.get('organisation_id') : user.organisation.organisation_id,
      offset,
      ...getFiltersObject(filters)
    ), {
      enabled: user.organisation !== null && filters !== null,
      onError: (err) => handleErrors(err)
    });

  const { isLoading: isLoadingPublicationFilters, data: publicationFiltersData } = useQuery(
    [ 'getPublicationFilters', { user }],
    () => publicationService.getPublicationFilters(user.organisation.organisation_id), {
      enabled: user.organisation !== null,
      onSuccess: (response) => {
        if (search) {
          if (query && query.get('offset')) {
            setOffset(Number(query.get('offset')));
          }

          if (query && query.get('freetext')) {
            setFreeText(query.get('freetext'));
          }

          setFilters(setDefaultFilters(response.data.criteria, query));
        } else {
          setFilters(response.data.criteria);
        }
      },
      onError: (err) => handleErrors(err)
    });

  useEffect(() => {
    setOffset(0);
  }, [user]);

  useEffect(() => {
    if (filters && user && user.organisation && user.organisation.organisation_id) {
      browserHistory.push({
        pathname: '/app/search',
        search: getQueryString(
          user.organisation.organisation_id,
          offset,
          ...getFiltersObject(filters)
        )
      });
    }
  }, [ filters, offset, user ]);

  useEffect(() => {
    if (filters) {
      handleFilters({
        ...filters,
        textField: [
          ...filters.textField.map(field => ({
            ...field,
            value: {
              ...field.value,
              ...(field.fieldName === 'freetext' && {
                value: debouncedFreeText
              })
            }
          }))
        ]
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFreeText]);

  const handleGoBack = () => {
    setOffset(offset - 1);
  };

  const handleGoForward = () => {
    setOffset(offset + 1);
  };

  const handleFilters = (newFilters) => {
    setOffset(0);
    setFilters(newFilters);
  };

  const handleFreeTextChange = (value) => {
    setFreeText(value);
  };

  return (
    <>
      {(isLoadingPublicationFilters) && <LoadingScreen />}
      {!isLoadingPublicationFilters && publicationFiltersData &&
        <Page
          className={classes.root}
          appName={user.theme && user.theme.appName}
          icon={user.theme && user.theme.appIcon}
          title='Publications'>
          <Container maxWidth={false}>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Grid
                  container
                  justify='space-between'
                  spacing={3}>
                  <Grid item>
                    <Typography variant='h5'>
                      List of Publications
                    </Typography>
                    <Breadcrumbs
                      aria-label='breadcrumb'
                      separator={<NavigateNextIcon fontSize='small' />}
                      sx={{ mt: 1 }}>
                      <Link
                        color='textPrimary'
                        component={RouterLink}
                        to='/app'
                        variant='subtitle2'>
                        Home
                      </Link>
                      <Typography
                        color='textSecondary'
                        variant='subtitle2'>
                        Search
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                </Grid>
              </Grid>
              {publicationFiltersData && publicationFiltersData.data && publicationFiltersData.data.message &&
                <Grid item xs={12}>
                  <PublicationsBanner message={publicationFiltersData.data.message} />
                </Grid>
              }
              <Grid item xs={12}>
                {filters &&
                  <PublicationsFilters
                    filters={filters}
                    handleFilters={handleFilters}
                    freeText={freeText}
                    handleFreeTextChange={handleFreeTextChange} />
                }
              </Grid>
              <Grid item xs={12}>
                {isLoadingPublications && <Box mt={10}><LoadingScreen /></Box>}
                {!isLoadingPublications && publicationsData &&
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      {publicationsData && publicationsData.data && publicationsData.data.map((publication) => (
                        <PublicationCard key={publication.publication_id} publication={publication} />
                      ))}
                    </Grid>
                    <Grid item xs={12} className={classes.pagination}>
                      <Grid container direction='row' justify='flex-end' alignItems='baseline'>
                        <Grid item>
                          <Typography
                            className={classes.totalSearchCountText}
                            color='textSecondary'
                            variant='subtitle2'>
                            {`Total search count: ${publicationsData.pagination.total}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton color='primary' onClick={handleGoBack} disabled={offset === 0}>
                            <ChevronLeftIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            color='primary'
                            onClick={handleGoForward}
                            disabled={publicationsData.pagination.total === 0 || (offset === Math.ceil(publicationsData.pagination.total / 10) - 1)}>
                            <ChevronRightIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Container>
        </Page>
      }
    </>
  );
};
