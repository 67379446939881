import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { handleOpenLink } from 'helpers';
import { ExternalLink } from 'resources/icons';
import { useStyles } from './PublicationAboutSection.styles';

export const PublicationAboutSection = ({ publication }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          component='p'
          variant='overline'>
          About
        </Typography>
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              primary='Publication date:'
              secondary={new Date(publication.publication_date).toLocaleDateString()} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Type:'
              secondary={publication.type.name} />
          </ListItem>
          {publication.publication_url && publication.publication_url !== '' &&
            <ListItem button onClick={() => handleOpenLink(publication.publication_url)}>
              <ListItemText primary='Publication' />
              <ListItemIcon className={classes.itemIcon}>
                <ExternalLink color='primary' />
              </ListItemIcon>
            </ListItem>
          }
          {publication.summary_url && publication.summary_url !== '' &&
            <ListItem button onClick={() => handleOpenLink(publication.summary_url)}>
              <ListItemText primary='Summary' />
              <ListItemIcon className={classes.itemIcon}>
                <ExternalLink color='primary' />
              </ListItemIcon>
            </ListItem>
          }
        </List>
        <Divider className={classes.divider} />
        <Typography
          className={classes.title}
          component='p'
          variant='overline'>
          Publisher
        </Typography>
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              primary='Name:'
              secondary={publication.publisher.name} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary='Website:'
              secondary={
                <Link
                  className={classes.link}
                  href={publication.publisher.website}
                  target='_blank'
                  variant='body2'>
                  {publication.publisher.website}
                </Link>
              } />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

PublicationAboutSection.propTypes = {
  publication: PropTypes.object.isRequired
};