import axios from 'axios';

import { config } from 'helpers';

const getOrganisations = () => {
  return axios.get(`${config.openRegulationApiBaseUrl}/organisations`)
    .then((response) => {
      return response.data;
    });
};

const getOrganisation = (organisationId) => {
  return axios.get(`${config.openRegulationApiBaseUrl}/organisations/${organisationId}`)
    .then((response) => {
      return response.data;
    });
};

export const organisationService = {
  getOrganisations,
  getOrganisation
};