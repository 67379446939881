import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 36,
    width: 36,
    backgroundColor: 'transparent'
  },
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
  },
  titleTypography: {
    paddingLeft: theme.spacing(1)
  },
  cardHeader: {
    paddingBottom: 0
  },
  cardContent: {
    paddingTop: theme.spacing(2)
  },
  cardActions: {
    backgroundColor: theme.palette.background.standard,
    padding: theme.spacing(2)
  },
  footerButton: {
    textTransform: 'none'
  }
}));