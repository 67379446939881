import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.standard,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  stepper: {
    backgroundColor: 'transparent'
  },
  dialogTitle: {
    textAlign: 'center',
    paddingBottom: theme.spacing(1)
  },
  paper: {
    marginBottom: theme.spacing(2)
  }
}));