import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField } from '@material-ui/core';

export const AutocompleteTextField = ({ params, isLoading }) => {
  return (
    <TextField
      {...params}
      variant='outlined'
      placeholder='Search ...'
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }} />
  );
};

AutocompleteTextField.propTypes = {
  params: PropTypes.any,
  isLoading: PropTypes.bool
};