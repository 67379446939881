import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from 'react-feather';

import { useStyles } from './ConfirmationStep.styles';

export const ConfirmationStep = ({ onClose, upgrade }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box maxWidth={450} mx='auto'>
          <Box
            display='flex'
            justifyContent='center'>
            <Avatar className={classes.avatar}>
              <CheckCircleIcon />
            </Avatar>
          </Box>
          <Box mt={2}>
            <Typography
              variant='h5'
              color='textPrimary'
              align='center'>
              You are all done!
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              align='center'>
              {upgrade ? 'You successfully upgraded your plan.' : 'You successfully subscribed to a plan.'}
            </Typography>
          </Box>
          <Box
            mt={2}
            display='flex'
            justifyContent='center'>
            <Button
              variant='contained'
              color='primary'
              onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

ConfirmationStep.propTypes = {
  onClose: PropTypes.func,
  upgrade: PropTypes.bool
};