import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Trash2 as DeleteIcon
} from 'react-feather';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  DeleteUserDialog,
  EditUserPermissionsDialog,
  Label
} from 'components';
import { handleErrors, userRoleEnum } from 'helpers';
import { useUser } from 'hooks';
import { userService } from 'services';
import { useStyles } from './UsersTable.styles';

const getRoleLabel = (userRole) => {
  const { text, color } = userRoleEnum[userRole];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

export const UsersTable = ({
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  totalCount,
  users
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [ openEditUserPermissionsDialog, setOpenEditUserPermissionsDialog ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState(null);
  const [ openDeleteUserDialog, setOpenDeleteUserDialog ] = useState(false);
  const [ disableButton, setDisableButton ] = useState(false);

  const deleteMutation = useMutation(
    (deleteData) => userService.deleteUser(deleteData.organisationId, deleteData.userId), {
      onSuccess: () => {
        setDisableButton(false);
        invalidateQueries();
        setOpenDeleteUserDialog(false);
        toast.success('User deleted successfully');
      },
      onError: (err) => {
        setDisableButton(false);
        handleErrors(err);
      }
    });

  const handleOpenEditUserPermissionsDialog = (userData) => {
    setSelectedUser(userData);
    setOpenEditUserPermissionsDialog(true);
  };

  const handleCloseEditUserPermissionsDialog = () => {
    setSelectedUser(null);
    setOpenEditUserPermissionsDialog(false);
  };

  const handleOpenDeleteUserDialog = (userData) => {
    setSelectedUser(userData);
    setOpenDeleteUserDialog(true);
  };

  const handleCloseDeleteUserDialog = () => {
    setSelectedUser(null);
    setOpenDeleteUserDialog(false);
  };

  const handleDeleteUser = () => {
    setDisableButton(true);

    const dto = {
      organisationId: user.organisation.organisation_id,
      userId: selectedUser.user_id
    };

    deleteMutation.mutate(dto);
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries('getUsers');
  };

  const handleUserPermissions = (permissions) => {
    const permission = permissions.find(x => x.organisation_id === user.organisation.organisation_id);
    if (permission) {
      return (
        <>
          <TableCell>{getRoleLabel(permission.role_admin ? 'YES' : 'NO')}</TableCell>
          <TableCell>{getRoleLabel(permission.role_billing ? 'YES' : 'NO')}</TableCell>
          <TableCell>{getRoleLabel(permission.role_developer ? 'YES' : 'NO')}</TableCell>
        </>
      );
    } else {
      return (
        <>
          <TableCell>{getRoleLabel('NO')}</TableCell>
          <TableCell>{getRoleLabel('NO')}</TableCell>
          <TableCell>{getRoleLabel('NO')}</TableCell>
        </>
      );
    }
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Admin Role</TableCell>
                <TableCell>Billing Role</TableCell>
                <TableCell>Developer Role</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((userData) => (
                <TableRow
                  key={userData.user_id}>
                  <TableCell>
                    <Box
                      display='flex'
                      alignItems='center'>
                      <Avatar
                        className={classes.avatar}
                        src={null} >
                      </Avatar>
                      <div>
                        <Typography
                          variant='body1'>
                          {`${userData.first_name} ${userData.last_name}`}
                        </Typography>
                        <Typography
                          variant='body2'
                          color='textSecondary'>
                          {userData.email}
                        </Typography>
                      </div>
                    </Box>
                  </TableCell>
                  <TableCell>{userData.title}</TableCell>
                  {handleUserPermissions(userData.permissions)}
                  <TableCell align='right'>
                    <Tooltip title='Edit Permissions' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenEditUserPermissionsDialog(userData)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete' placement='bottom'>
                      <IconButton
                        className={classes.button}
                        edge='end'
                        variant='contained'
                        color='primary'
                        onClick={() => handleOpenDeleteUserDialog(userData)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[ 5, 10, 25 ]} />
      {selectedUser &&
        <>
          <DeleteUserDialog
            openDeleteUserDialog={openDeleteUserDialog}
            handleDeleteUser={handleDeleteUser}
            onCloseDeleteUserDialog={handleCloseDeleteUserDialog}
            selectedUserId={selectedUser.user_id}
            disableButton={disableButton} />
          <EditUserPermissionsDialog
            openEditUserPermissionsDialog={openEditUserPermissionsDialog}
            onCloseEditUserPermissionsDialog={handleCloseEditUserPermissionsDialog}
            userData={selectedUser} />
        </>
      }
    </Card>
  );
};

UsersTable.propTypes = {
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  users: PropTypes.array
};